import { Modal } from 'antd';
import { green5, red5 } from '../utils/colors';
import { formulateModal } from '../utils/Descriptions'

export const NeuroHCardDisplay = ({ title, subTitle, value = 0, onClick }: any) => {
  const [modal, modalContextHolder] = Modal.useModal();

  return (
    <div style={{
      flex: 1,
      minHeight: 50,
      textAlign: "center",
      paddingTop: 15,
      paddingBottom: 15,
      borderRadius: 5,
      // border: "3px solid lightblue", 
      cursor: "pointer",
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
    }}
      // onClick={() => formulateModal(modal, "HRV Index", "hrvIndex")}
      onClick={onClick}
    >
      <div style={{
        fontSize: 28,
        paddingTop: 0,
        paddingLeft: 16,
        paddingRight: 16,
        lineHeight: 1,
        color: "#333",
        marginBottom: 7,
      }}>{getNeuroH(value)}</div>
      <div style={{ textAlign: "center", color: 'black', fontSize: 16, lineHeight: 1 }}>{title}</div>
      {/* {subTitle ?
        <div style={{ textAlign: "center", fontSize: 12, color: 'grey', lineHeight: 1 }}>{subTitle}</div>
        : null} */}

      {modalContextHolder}

    </div>
  )
}

export const getNeuroH = (value: number) => {
  if (value <= -.16) {
    return "Possible Exhaustion"
  }
  else if (value > -.16 && value <= .15) {
    return "Normal Regulation"
  }
  else if (value > .15 && value <= .30) {
    return "Mild Imbalance"
  }
  else if (value > .30 && value <= .45) {
    return "Moderate Imbalance"
  }
  else if (value > .45) {
    return "Severe Distortion"
  }
  else {
    return "Unknown"
  }
}