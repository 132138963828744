import { Alert, Breadcrumb, Button, Layout, Menu } from 'antd';
import {
	MenuUnfoldOutlined,
	MenuFoldOutlined,
	AppstoreOutlined,
	ApartmentOutlined,
	UserOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect,
	useHistory,
	useRouteMatch,
	useLocation
} from "react-router-dom";
// import { AuthButton, LoginPage, PrivateRoute, ProtectedPage, PublicPage } from '../App';
import { useAuth } from '../App';
import { ListPatients } from './ListPatients';
import { PatientOverviewScreen } from './PatientOverviewScreen';
import { RegisterPatients } from './RegisterPatient';
import { PatientComparisonScreen } from './PatientComparisonScreen';
import { ConnectPatient } from './ConnectPatient';
import { ViewAtAGlance } from './ViewAtAGlance';
import { ProfilePage } from './ProfilePage';
import { PDFOverviewPage } from '../pdfComponents/PDFOverviewPage';
import Recording from './Recording';
import { useQuery } from '@apollo/client';
import { GET_GLOBAL_NOTIFICATIONS } from '../graphql/queries';
import { BrainScreen } from './BrainScreen';
import { VagalScreen } from '../components/Vagal_Tone_Questionnaire/VagalScreen';

var dotenv = require('dotenv').config({ path: '../../.env' });

const { Header, Sider, Content } = Layout;

export const MainLayout = () => {
	let auth = useAuth();
	let history = useHistory();

	const { path, url } = useRouteMatch()
	let location = useLocation()
	const [collapsed, setCollapsed] = useState(false)
	const [currentMenuItem, setCurrentMenuItem] = useState('1')

	const { loading: globalMessageLoading, error: globalMessageError, data: globalMessageData } = useQuery(GET_GLOBAL_NOTIFICATIONS)

	useEffect(() => {

		switch (location.pathname) {
			case `${path}/patients`:
				setCurrentMenuItem('1')
				break
			case `${path}/patients/register`:
				setCurrentMenuItem('2')
				break
			case `${path}/patients/connect`:
				setCurrentMenuItem('3')
				break
			case `${path}/profile`:
				setCurrentMenuItem('4')
				break
		}

		return () => {
			// cleanup
		}
	}, [location])

	const toggle = () => {
		setCollapsed(!collapsed);
	};

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<Sider
				// theme="light"
				trigger={null}
				collapsible
				collapsed={collapsed}

			>
				<div className="logo" >
					<div style={{ color: 'white', fontSize: 18 }}>
						{collapsed ? "HQ" : "Heart Quest"}
					</div>
				</div>
				<Menu
					theme="dark"
					mode="inline"
					defaultSelectedKeys={['1']}
					selectedKeys={[currentMenuItem]}
				>
					<Menu.Item
						key="1"
						icon={<AppstoreOutlined />}>
						<Link
							to={`${path}/patients`}
						>Patients</Link>
					</Menu.Item>
					<Menu.Item
						key="2"
						icon={<ApartmentOutlined />}>
						<Link
							to={`${path}/patients/register`}
						>Register Patient</Link>
					</Menu.Item>
					<Menu.Item
						key="3"
						icon={<ApartmentOutlined />}>
						<Link
							to={`${path}/patients/connect`}
						>Connect Patient</Link>
					</Menu.Item>
					<Menu.Item
						key="4"
						icon={<UserOutlined />}>
						<Link to={`${path}/profile`} >Profile</Link>
					</Menu.Item>
				</Menu>

			</Sider>

			<Layout className="site-layout">
				<Header className="site-layout-background" style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
					{React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
						className: 'trigger',
						onClick: toggle,
					})}
					<div style={{ paddingRight: 10 }}>
						<Button type="primary" onClick={() => auth.signout(() => history.push("/login"))}>
							Logout
						</Button>
					</div>
				</Header>


				{globalMessageData && !globalMessageLoading && globalMessageData.global_notifications.length !== 0 && globalMessageData.global_notifications.map((n: any) => {
					return (
						<Alert
							key={n.id}
							style={{
								marginLeft: 15,
								marginRight: 15,
								marginTop: 5,
								marginBottom: 5
							}}
							message={n?.Title}
							description={<div dangerouslySetInnerHTML={{ __html: n?.message }}></div>}
							type={n?.message_type}
							closable
						// onClose={onClose}
						/>
					)
				})}

				<Switch>
					<Route exact path={`${path}/patients`}>
						<ListPatients />
					</Route>
					<Route exact path={`${path}/patients/register`}>
						<RegisterPatients />
					</Route>
					<Route exact path={`${path}/patients/connect`}>
						<ConnectPatient />
					</Route>
					<Route exact path={`${path}/patients/:userId`}>
						<PatientOverviewScreen />
					</Route>
					<Route exact path={`${path}/patients/:userId/:recordingId/brain`}>
						<BrainScreen />
					</Route>
					<Route exact path={`${path}/patients/:userId/:recordingId/vagus`}>
						<VagalScreen />
					</Route>
					<Route exact path={`${path}/patients/:userId/pdf`}>
						<PDFOverviewPage />
					</Route>
					<Route exact path={`${path}/patients/:userId/comparison`}>
						<PatientComparisonScreen />
					</Route>
					<Route exact path={`${path}/patients/:userId/glance`}>
						<ViewAtAGlance />
					</Route>
					<Route exact path={`${path}/patients/:userId/recording`}>
						<Recording />
					</Route>
					<Route path={`${path}/profile`}>
						<ProfilePage />
					</Route>
					<Route exact path={`${path}`}>
						<Redirect
							to={{
								pathname: `${path}/patients`,
							}}
						/>
					</Route>
				</Switch>
			</Layout>
		</Layout>
	);
}

export default MainLayout
