import { Alert, Button, Form, Input, InputNumber, Modal, Select, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { EcgViewer } from './EcgViewer'
import { MoreOutlined } from '@ant-design/icons';
import { yellow5 } from '../utils/colors';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_USER_PROFILE } from '../graphql/mutations';
import moment from 'moment';
import { useAuth } from '../App';
import { message } from 'antd';

interface ProfileValues {
  firstName: string
  lastName: string
  email: string
  gender: "male" | "female"
  birthDay: number
  birthMonth: number
  birthYear: number
}

interface EditUserModalProps {
  user: UserDetails
  refetch: () => void
}

interface UserDetails {
  id: string
  firstName: string
  lastName: string
  email: string
  data: UserMeta
  birthDate: Date
}

interface UserMeta {
  gender: "male" | "female"
}

const success = () => {
  message.success('Successfully updated user details');
};

export const EditPatientModal = ({ user, refetch }: EditUserModalProps) => {


  const [isModalVisible, setIsModalVisible] = useState(false);

  const [updateUserProfile, { loading, error, data }] = useMutation(UPDATE_USER_PROFILE, {
    onCompleted: () => {
      refetch()
      success()
      message.destroy("updating-user")
    },
  });


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFinish = (values: ProfileValues) => {
    message.loading({ content: "Updating user", key: "updating-user" })

    updateUserProfile({
      variables: {
        userId: user.id,
        userDetails: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          gender: values.gender,
          birthDate: moment(new Date(values.birthYear, values.birthMonth, values.birthDay)).format('YYYY-MM-DD'),
        }
      }
    })
    setIsModalVisible(false);
  }
  if (data) {
    console.log(data);

  }
  if (error) {
    console.log(error);
  }


  return (
    <>
      <Button
        icon={<MoreOutlined />}
        style={{
          // backgroundColor: 'tomato',
          border: 'none',
          // color: 'white'
        }}
        onClick={showModal}
      >

      </Button>

      <Modal
        title="Edit Profile"
        visible={isModalVisible}
        okText="Save"
        okButtonProps={{ form: 'profile-editor-form', target: 'submit', htmlType: 'submit', loading: loading }}
        onCancel={handleCancel}>
        {error &&
          <div>{error.message}</div>
        }


        <Form
          id='profile-editor-form'
          onFinish={handleFinish}
          // labelCol={{ span: 4 }}
          // wrapperCol={{ span: 14 }}
          layout="horizontal"
          initialValues={{
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            gender: user.data.gender,
            birthDay: user.birthDate.getDate(),
            birthMonth: user.birthDate.getMonth(),
            birthYear: user.birthDate.getFullYear(),
          }}
        >

          <Form.Item label="First Name" name="firstName" rules={[{ required: true, message: "Please make sure that you have included you First Name" }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Last Name" name="lastName" rules={[{ required: true, message: "Please make sure that you have included you Last Name" }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Email" name="email" rules={[{ required: true, message: "Please make sure that you have included you email" }]}>
            <Input />
          </Form.Item>

          <Form.Item label="Gender" name="gender" rules={[{ required: true, message: "Please make sure that you have selected a gender" }]}>
            <Select placeholder="Select gender">
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Form.Item>

          <Input.Group compact>
            <Form.Item label="Birth Day" name="birthDay">
              <InputNumber min={1} max={31} />
            </Form.Item>
            <Form.Item label="Birth Month" name="birthMonth">
              <Select placeholder="Select Month">
                {
                  [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                  ].map((m, i) => {
                    return <Select.Option key={i} value={i}>{m}</Select.Option>
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item label="Birth Year" name="birthYear"
            >
              <InputNumber min={new Date().getFullYear() - 110} max={new Date().getFullYear() - 18} />
            </Form.Item>
          </Input.Group>

        </Form>
      </Modal>
    </>
  )
}
