import { Breadcrumb, Button, Descriptions, Divider, PageHeader, Spin } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useAuth } from '../App'
import { NotesModal } from '../components/Notes/NotesModal'
import {
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { GET_HEART_DATA_BY_PK, GET_USER_INFO } from '../graphql/queries'
import moment from 'moment'
import BrainContainer from '../components/Brain/BrainContainer'
import { LoadingOutlined, PrinterOutlined, CheckCircleTwoTone } from '@ant-design/icons';


const Loader = <LoadingOutlined style={{ fontSize: 50 }} spin />;


export const BrainScreen = () => {
  let auth = useAuth();
  let user = auth.getUser()
  const history = useHistory()
  const { userId, recordingId }: any = useParams()

  const { loading, error, data } = useQuery(GET_HEART_DATA_BY_PK, {
    variables: {
      id: recordingId,
      userIdString: userId,
      userIdUuid: userId
    }
  })

  if (loading || !data) {

    return <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <div>
        <Spin indicator={Loader} />
      </div>
    </div>
  }


  return (
    <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Breadcrumb.Item>
          <Link to="/dashboard/patients">
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item >
          <Link to="/dashboard/patients">
            <span>Patients</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>
            Brain Screen
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >

        <PageHeader
          className="site-page-header"
          onBack={history.goBack}
          title={`${data?.user?.firstName}`}
          subTitle={data?.users_by_pk?.last_name}
          extra={[
            <NotesModal recordingId={recordingId} authorId={user.id} />,
          ]}
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 30 }}>
            <Descriptions.Item label="Age">{moment().diff(data?.user?.birthDate, 'years')}</Descriptions.Item>
            <Descriptions.Item label="Gender">{data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null}</Descriptions.Item>
            {/* <Descriptions.Item label="Total recordings">{data?.heart_data?.length}</Descriptions.Item> */}
          </Descriptions>
        </PageHeader>
        <Divider />

        {!loading && data && data?.heart_data_by_pk &&
          <>
            <BrainContainer recording={data?.heart_data_by_pk} />
          </>
        }
        {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      </Content>
    </>
  )
}
