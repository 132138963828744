import { useQuery } from '@apollo/client'
import { Alert, Breadcrumb, Descriptions, Divider, Empty, PageHeader } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import moment from 'moment'
import React, { useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { StackedAreaScrollComponent } from '../components/StackedAreaScrollComponent'
import { VerticalBarComponent } from '../components/VerticalBarComponent'
import { GET_USER_RECORDINGS } from '../graphql/queries'
import { green5, red5, yellow5 } from '../utils/colors'
import { hfPercVertical, hfVertical, hrvIndexVertical, lfHfVertical, lfPercVertical, lfVertical, rmssdVertical, sdnnVertical, stressIndexVertical, totalPowerVertical, vlfPercVertical, vlfVertical } from '../utils/gradients'
import {
  HomeOutlined,
  UserOutlined
} from '@ant-design/icons'
import { TrendPanBar } from '../components/TrendPanBar'
import { getFormatedDate, getFormatedTime } from '../utils/helperFunctions'

interface ScrollPos {
  name: String
  pos: number
}

export const ViewAtAGlance = () => {

  const { userId }: any = useParams()
  const history = useHistory();
  const location = useLocation();

  const [scrollerName, setScrollerName] = useState("")
  const [scrollPos, setScrollPos] = useState<ScrollPos>()
  const [dataIndex, setDataIndex] = useState(0)

  const { loading, error, data } = useQuery(GET_USER_RECORDINGS, {
    variables: {
      _eq: userId,
      id: userId,
      where: {
        owner: { _eq: userId },
        deleted_on: { _is_null: true },
        // ...(query.get('trend') ? { trend_recording: { _eq: query.get('trend') } } : { trend_recording: { _is_null: false } }),
      },
      // order_by: query.get('order_by') || "asc"
    }
  })

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (loading || !data) {
    return <p>Loading...</p>
  }

  if (data?.heart_data.length === 0) {
    return <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item>
            <HomeOutlined />
            <span>Home</span>
          </Breadcrumb.Item>
        </Link>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item >
            <span>Patients</span>
          </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >
        <Empty
          style={{ marginTop: 100 }}
          description={
            <div>This user do not have any recording data yet. <br /> Please use our app and HQP device to do a recording</div>
          }
        />
      </Content>
    </>
  }

  const freqData = data?.heart_data.map((d: any) => {
    return {
      vlf: d?.data?.frequencyDomain?.vlf ? d?.data?.frequencyDomain?.vlf : 0,
      lf: d?.data?.frequencyDomain?.vlf ? d?.data?.frequencyDomain?.lf : 0,
      hf: d?.data?.frequencyDomain?.vlf ? d?.data?.frequencyDomain?.hf : 0,
    }
  })

  const titles = data?.heart_data.map((d: any) => {
    return d?.title
  })

  const dates = data?.heart_data.map((d: any) => {
    return getFormatedDate(d?.created_on)
  })
  const times = data?.heart_data.map((d: any) => {
    return getFormatedTime(d?.created_on)
  })

  const mainData = data?.heart_data[dataIndex]?.data

  return (
    <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item>
            <HomeOutlined />
            <span>Home</span>
          </Breadcrumb.Item>
        </Link>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item >
            <span>Patients</span>
          </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >
        <div>
          <PageHeader
            className="site-page-header"
            onBack={history.goBack}
            title={`${data?.user?.firstName}`}
            subTitle={data?.users_by_pk?.last_name}
          >
            <Descriptions size="small" column={1} style={{ paddingLeft: 30 }}>
              <Descriptions.Item label="Age">{moment().diff(data?.user?.birthDate, 'years')}</Descriptions.Item>
              <Descriptions.Item label="Gender">{data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null}</Descriptions.Item>
              <Descriptions.Item label="Total recordings">{data?.heart_data?.length}</Descriptions.Item>
            </Descriptions>
          </PageHeader>
          <Divider />
          {data?.heart_data[dataIndex]?.data?.timeDomain.sdnn > 100 && data?.heart_data[dataIndex]?.data?.frequencyDomain.total_power > 5000 ?

            <Alert
              message="Warning"
              description={`This recording indicates a high probability of an irregular heart rhythm leading to possible inaccurate results (falsely elevated values) on HQP.
            Further evaluation with a licensed heart professional may be warranted if this pattern continues.`}
              type="error"
              style={{ marginBottom: 30 }}
            />
            : null
          }
          <h1 style={{ textAlign: "center", lineHeight: 1 }}>{titles[dataIndex]}</h1>
          <h1 style={{ textAlign: "center", lineHeight: 1 }}>{dates[dataIndex]}</h1>
          <h2 style={{ textAlign: "center", lineHeight: 1 }}>{times[dataIndex]}</h2>
          <div style={{ height: 50 }}></div>
          {data.heart_data.length > 1 ?
            <TrendPanBar
              data={freqData}
              dataIndex={(dataIndex === -1) ? 21 : dataIndex}
              setDataIndex={setDataIndex}
              dataPos={50}
              locationChange={location}
            />
            : null}
          {/* {data.heart_data.length > 1 ?
            <StackedAreaScrollComponent
              data={[vlf, lf, hf]}
              colors={[red5, yellow5, green5]}
              dataNames={["VLF", "LF", "HF"]}
              name={"1"}
              scrollPos={scrollPos}
              setScrollPos={setScrollPos}
              dataIndex={dataIndex}
              setDataIndex={setDataIndex}
            />
            : null} */}
          <div style={{ width: "100%", display: 'flex', flexDirection: 'row', overflowX: 'scroll', justifyContent: 'space-between' }}>
            <VerticalBarWrapper
              name="TP"
              unit="MC2"
              range="1500-300"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.total_power}
                domain={[500, 5000]}
                decimal={0}
                backgroundColor={totalPowerVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="HF"
              unit="MC2"
              range="750-1000"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.hf}
                domain={[750, 1000]}
                decimal={0}
                backgroundColor={hfVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="LF"
              unit="MC2"
              range="750-1200"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.lf}
                domain={[750, 1200]}
                decimal={0}
                backgroundColor={lfVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="VLF"
              unit="MC2"
              range="600-1000"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.vlf}
                domain={[600, 1000]}
                decimal={0}
                backgroundColor={vlfVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="HF%"
              // unit="MC2"
              range="20-30"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.hf / mainData?.frequencyDomain?.total_power * 100}
                domain={[0, 45]}
                decimal={0}
                backgroundColor={hfPercVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="LF%"
              // unit="MC2"
              range="30-50"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.lf / mainData?.frequencyDomain?.total_power * 100}
                domain={[0, 60]}
                decimal={0}
                backgroundColor={lfPercVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="VLF%"
              // unit="MC2"
              range="30-50"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.vlf / mainData?.frequencyDomain?.total_power * 100}
                domain={[0, 55]}
                decimal={0}
                backgroundColor={vlfPercVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="LF/HF"
              unit="MC2"
              range="1-3"
            >
              <VerticalBarComponent
                value={mainData?.frequencyDomain?.lf_hf_ratio}
                domain={[0, 5]}
                decimal={1}
                backgroundColor={lfHfVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="SI"
              // unit="MC2"
              range="10-100"
            >
              <VerticalBarComponent
                value={mainData?.stressIndex}
                domain={[0, 600]}
                decimal={0}
                backgroundColor={stressIndexVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="SDNN"
              // unit="MC2"
              range="30-100"
            >
              <VerticalBarComponent
                value={mainData?.timeDomain?.sdnn}
                domain={[0, 200]}
                decimal={0}
                backgroundColor={sdnnVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="RMSSD"
              // unit="MC2"
              range="15-45"
            >
              <VerticalBarComponent
                value={mainData?.timeDomain?.rmssd}
                domain={[0, 45]}
                decimal={0}
                backgroundColor={rmssdVertical}
              />
            </VerticalBarWrapper>

            <VerticalBarWrapper
              name="HRV Index"
              // unit="MC2"
              range="6-25"
            >
              <VerticalBarComponent
                value={mainData?.hrvIndex}
                domain={[0, 50]}
                decimal={0}
                backgroundColor={hrvIndexVertical}
              />
            </VerticalBarWrapper>

          </div>
          {/* <pre>{JSON.stringify(data?.heart_data[dataIndex]?.data, null, 2)}</pre> */}

        </div>
      </Content>
    </>
  )
}


const VerticalBarWrapper = ({ name, unit, range, children }: any) => {

  return (
    <div style={{ textAlign: 'center', width: 100, display: 'flex', flexDirection: 'column' }}>
      {children}
      {name ? <div>{name}</div> : null}
      {unit ? <div>{unit}</div> : null}
      {range ? <div>{range}</div> : null}
    </div>
  )
}