import { useMutation } from '@apollo/client';
import { message, Modal } from 'antd';
import React, { useState } from 'react';
import { useAuth } from '../App';
import { REMOVE_FOLLOWER, UNFOLLOW_PATIENT } from '../graphql/mutations';
import { GET_PRAC_FOLLOWERS, GET_PRAC_PATIENTS } from '../graphql/queries';
import { red5 } from '../utils/colors';

export const RemoveFollowerModal = ({ relationship_id, followerName }: { relationship_id: string, followerName: String }) => {

  let auth = useAuth();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [removeRelation, { data, loading, error }] = useMutation(REMOVE_FOLLOWER, {
    onCompleted: () => {
      message.success(`Successfully removed ${followerName} from your list`);
    },
    onError: () => {
      message.error(`Could not remove ${followerName} from your list`);
    },
    refetchQueries: [{
      query: GET_PRAC_FOLLOWERS,
      variables: {
        followee_id: auth.getUser()?.id
      }
    }
    ]
  })

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    removeRelation({
      variables: {
        id: relationship_id
      }
    })
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    message.info(`Cencelled removing ${followerName} from your list`);
  };

  return (
    <>
      <svg onClick={() => {

        showModal()
      }} xmlns="http://www.w3.org/2000/svg" style={{ width: 16, height: 16, color: red5, cursor: "pointer" }} fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
      </svg>
      <Modal title={`Remove ${followerName}`} visible={isModalVisible} onOk={handleOk} okText="DELETE" okType='danger' onCancel={handleCancel}>
        Are you sure you want to remove <b>{followerName}</b> from your list?
      </Modal>
    </>
  );
};
