import { Button, Input, message } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import React, { useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import { BarcodeObject, createBarcodeString } from '../utils/helperFunctions'
import { QrcodeOutlined } from '@ant-design/icons';
import { blue5, red5 } from '../utils/colors';
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';

import ChromeLogo from '../../src/chrome.svg'
const { Search } = Input;



export const BarcodeModal = (barcodeData: BarcodeObject) => {
  const inputRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false)
  const generatedLink = `https://hqp.heartquest-global.com/record/${barcodeData.ownerId}`

  const showModal = () => {
    // barcodeData.onClose()
    setIsModalVisible(true);
  };

  const handleOk = () => {
    barcodeData.onClose()
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    barcodeData.onClose()
    setIsModalVisible(false);
  };
  return (
    <>
      <Button
        type="primary"
        icon={<QrcodeOutlined />}
        // loading={loadings[1]}
        style={{
          backgroundColor: blue5,
          border: 'none',
          color: 'white'
        }}
        onClick={showModal}
      >
        QR
      </Button>
      <Modal
        width={600}
        title="QR Code"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {/* <pre>{createBarcodeString(barcodeData)}</pre> */}
          <h1>Scan Barcode</h1>
          <p>Scan the barcode with the HQ Recorder app to start a recording</p>
          <QRCode value={createBarcodeString(barcodeData)} size={450} />
          <div style={{ marginTop: 50, width: "100%" }}>
            <p>You can copy this link and send it to {barcodeData.name} to do a recording. Make sure {barcodeData.name} opens the link in <img src={ChromeLogo} alt="chrome-logo" style={{ width: 15 }} /> Chrome.</p>
            <Input.Group compact>
              <Input ref={inputRef} style={{ width: 'calc(100% - 35px)' }} readOnly defaultValue={generatedLink} />
              <Button onClick={() => {
                navigator.clipboard.writeText(generatedLink)
                setIsCopied(true)
                message.success('Link copied successfully');
              }} icon={isCopied ? <CheckOutlined /> : <CopyOutlined />}></Button>
            </Input.Group>
          </div>
        </div>
      </Modal>
    </>
  )
}
