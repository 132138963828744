import { Col, Row, Tag } from 'antd'
import React from 'react'
import { VictoryAxis, VictoryChart, VictoryPie } from 'victory'
import { blue4, blue5, green5, red5, yellow5 } from '../utils/colors'

export const MeridiansDiagram = ({ meridians }: any) => {
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <VictoryChart width={350} height={240}>
        <VictoryAxis style={{
          axis: { display: "none" },
          ticks: { display: "none" },
          tickLabels: { display: "none" }
        }} />
        <VictoryPie
          width={240}
          height={240}
          // style={{
          //   data: {
          //     fillOpacity: 0.9, stroke: "white", strokeWidth: 3
          //   }
          // }}
          startAngle={-30}
          labelRadius={103}
          radius={120}
          innerRadius={103}
          labels={["PITTA", "VATA", "KAPHA", "PITTA", "VATA", "KAPHA"]}
          labelPlacement='perpendicular'
          colorScale={[red5, yellow5, green5]}
          data={[
            { x: "PITTA", y: 60 },
            { x: "VATA", y: 60 },
            { x: "KAPHA", y: 60 },
            { x: "PITTA", y: 60 },
            { x: "VATA", y: 60 },
            { x: "KAPHA", y: 60 },

          ]}
        />

        <VictoryPie
          width={240}
          height={240}
          style={{
            data: {
              // fillOpacity: 0.9,
              fill: "white",
              stroke: "black",
              strokeWidth: .5
            },
            labels: {
              fontSize: 10, fill: "black",
            }
          }}
          startAngle={-30}
          labelRadius={89}
          radius={103}
          innerRadius={85}
          // labelPlacement="perpendicular"
          // labels={["GB", "LV", "LU", "LI", "ST", "SP", "HT", "SI", "BL", "KI", "PC", "TX"]}
          colorScale={[]}
          data={[
            { x: "GB", y: 30 },
            { x: "LV", y: 30 },
            { x: "LU", y: 30 },
            { x: "LI", y: 30 },
            { x: "ST", y: 30 },
            { x: "SP", y: 30 },
            { x: "HT", y: 30 },
            { x: "SI", y: 30 },
            { x: "BL", y: 30 },
            { x: "KI", y: 30 },
            { x: "PC", y: 30 },
            { x: "TW", y: 30 },
          ]}
        />

        <VictoryPie
          width={240}
          height={240}
          style={{
            data: {
              // fillOpacity: 0.9,
              fill: "white",
              stroke: "black",
              strokeWidth: .5,
            },
            labels: {
              fontSize: 10, fill: "white",

            }
          }}
          startAngle={-30}
          labelRadius={89}
          radius={85}
          innerRadius={0}
          labels={[]}
          colorScale={[]}
          data={[
            { x: "GB", y: 30 },
            { x: "LV", y: 30 },
            { x: "LU", y: 30 },
            { x: "LI", y: 30 },
            { x: "ST", y: 30 },
            { x: "SP", y: 30 },
            { x: "HT", y: 30 },
            { x: "SI", y: 30 },
            { x: "BL", y: 30 },
            { x: "KI", y: 30 },
            { x: "PC", y: 30 },
            { x: "TW", y: 30 },
          ]}
        />

        <VictoryPie
          // width={20}
          // height={20}
          style={{
            data: {
              fillOpacity: 0.9,
              // stroke: "white",
              strokeWidth: .5,
              fill: ({ datum }) => getColor(datum.r)
            }
          }}
          startAngle={-30}
          labelRadius={89}
          innerRadius={0}
          labels={[]}
          radius={({ datum }) => {
            // console.log(datum.r);
            return datum.r > 10 ? datum.r : 15 // adjust cneter for low values
          }}
          data={[
            { x: "GB", y: 30, r: meridians?.gb, },
            { x: "LV", y: 30, r: meridians?.lv, },
            { x: "LU", y: 30, r: meridians?.lu, },
            { x: "LI", y: 30, r: meridians?.li, },
            { x: "ST", y: 30, r: meridians?.st, },
            { x: "SP", y: 30, r: meridians?.sp, },
            { x: "HT", y: 30, r: meridians?.ht, },
            { x: "SI", y: 30, r: meridians?.si, },
            { x: "BL", y: 30, r: meridians?.bl, },
            { x: "KI", y: 30, r: meridians?.ki, },
            { x: "PC", y: 30, r: meridians?.pc, },
            { x: "TW", y: 30, r: meridians?.tw, },
          ]}
        />
        <VictoryPie
          style={{
            data: {
              fillOpacity: 0,
              stroke: "grey",
              strokeWidth: 1,
            }
          }}
          startAngle={-30}
          labelRadius={89}
          innerRadius={0}
          labels={[]}
          radius={40}
          data={[
            { x: 40, y: 80 },

          ]}
        />
        <VictoryPie
          style={{
            data: {
              fillOpacity: 0,
              stroke: "grey",
              strokeWidth: 1,
            }
          }}
          startAngle={-30}
          labelRadius={89}
          innerRadius={0}
          labels={[]}
          radius={60}
          data={[
            { x: 40, y: 80 },

          ]}
        />

      </VictoryChart>
      <Row justify='center' style={{ marginTop: 10, marginBottom: 10 }} >
        <Col >
          <Tag color={red5}>{"<"} 10</Tag>
          <Tag color={yellow5}>{"<"} 20</Tag>
          <Tag color={green5}>{"<"} 40</Tag>
          <Tag color={blue4}>{"<"} 60</Tag>
          <Tag color={blue5}>{">"} 60</Tag>
        </Col>
      </Row>
    </div>
  )
}

const getColor = (value: number) => {
  if (value < 10) {
    return red5
  }
  else if (value >= 10 && value < 20) {
    return yellow5
  }
  else if (value >= 20 && value < 40) {
    return green5
  }
  else if (value >= 40 && value < 60) {
    return blue4
  }
  else {
    return blue5
  }
}