import React from 'react'

export const HorisontalBarHeadingSingle = ({ title, subHeading, value }: any) => {
  return (
    <>
      <div style={{ textAlign: "center", fontSize: 18, lineHeight: 1 }}>{title} {value ? `= ${value}` : null}</div>
      {subHeading ?
        <div style={{ textAlign: "center", fontSize: 16, color: 'grey', lineHeight: 1 }}>{subHeading}</div>
        : null}
    </>
  )
}
