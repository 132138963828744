/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Input, Button, Checkbox, DatePicker, Select, InputNumber } from 'antd';
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React, { useState } from 'react';
import { useAuth } from '../../App';
import { Link, useHistory } from 'react-router-dom';
import './SignupContainer.css'
import logo from '../../hq-logo.svg';
import moment from 'moment';

const { Option } = Select

export const SignupContainer = () => {
  let history = useHistory();
  let auth = useAuth();
  const dateFormat = 'YYYY/MM/DD';

  const [error, setError] = useState<string>("")

  const onFinish = (values: any) => {
    console.log("#####");
    auth.professionalSignUp(values)
      .then((data: any) => {
        console.log("#####***");
        console.log(data)
        console.log("Link User Done");
        console.log(data)
        console.log(data?.response?.status)

        console.log(data)
        if (data?.response?.status == 400) {
          setError("The user already exist, please search for user on Connect Patient view")
        }
        else {
          history.push("/dashboard")
        }
      })
    console.log("*****");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", justifyContent: "center", marginTop: 100 }}>
      <div>
        <img src={logo} alt="Heart Quest Logo" />
      </div>
      <h1 style={{ marginBottom: 0, fontWeight: "bold", letterSpacing: 7, lineHeight: 1.5, textAlign: "center", marginTop: 50 }}>HEART QUEST</h1>
      <pre style={{ marginBottom: 50, marginTop: 20, fontFamily: "monospace" }}>Beta Version</pre>
      <div>
        {error ? <p style={{ color: "red" }}>{error}</p> : null}
      </div>
      <Form
        name="normal_login"
        className="signup-form"
        // initialValues={{ email: "test4@mail.com", firstname: "Test4", lastname: "test4", password: "Test41234", confirm_password: "Test41234", birthDate: moment('1980/01/01', dateFormat) }}
        onFinish={onFinish}
        onCompositionUpdate={() => {
          setError("")
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input
            // prefix={<UserOutlined className="site-form-item-icon" />} 
            placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="firstname"
          style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
          rules={[{ required: true, message: 'Please input your firstname!', whitespace: true }]}
        >
          <Input placeholder="First Name" />
        </Form.Item>

        <span
          style={{ display: 'inline-block', width: '24px', lineHeight: '32px', textAlign: 'center' }}
        >

        </span>

        <Form.Item
          name="lastname"
          style={{ display: 'inline-block', width: 'calc(50% - 12px)' }}
          rules={[{ required: true, message: 'Please input your lastname!', whitespace: true }]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>

        <div style={{ color: "#999" }}>Birth Date</div>
        <Input.Group compact style={{ width: "100%" }}  >
          <Form.Item name="birthDay">
            <InputNumber min={1} max={31} placeholder="Day" />
          </Form.Item>
          <Form.Item name="birthMonth">
            <Select placeholder="Select Month">
              {
                [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ].map((m, i) => {
                  return <Select.Option key={i} value={i}>{m}</Select.Option>
                })
              }
            </Select>
          </Form.Item>
          <Form.Item name="birthYear"
          >
            <InputNumber min={new Date().getFullYear() - 110} max={new Date().getFullYear() - 18} placeholder="Year" />
          </Form.Item>
        </Input.Group>

        {/* <Form.Item
          name="birthDate"
          // label="Birth Date"

          rules={[
            {
              type: 'object' as const,
              required: true,
              message: 'Please select a birthdate!'
            }
          ]} >
          <DatePicker
            placeholder="Date of Birth YYYY-MM-DD"
            // defaultValue={moment('1980/01/01', dateFormat)} format={dateFormat}
            style={{ width: "100%" }} />
        </Form.Item> */}

        <Form.Item
          name="gender"
          rules={[{ required: true, message: "Please ensure to pick a gender" }]}
        >
          <Select placeholder="Gender" >
            <Option value="male" >Male</Option>
            <Option value="female" >Female</Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            // type="password"
            placeholder="Password"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>


        <Form.Item
          name="confirm_password"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('The two passwords that you entered do not match!'));
              },
            }),
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Confirm Password"
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>


        <Form.Item>
          <Button type="primary" htmlType="submit" className={"signup-form-button"}>
            Signup
          </Button>
          <div style={{ marginTop: 10 }}>
            Or <Link to="/login">Sign In!</Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};