import { useQuery } from '@apollo/client'
import { Content } from 'antd/lib/layout/layout'
import { createContext, useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { GET_USER_RECORDINGS } from '../graphql/queries'
import { createBarcodeString, generateHTMLImageUrl, getFormatedDate, getFormatedTime, useUrlQuery } from '../utils/helperFunctions'
import { StackedAreaScrollComponent } from '../components/StackedAreaScrollComponent'
import { HRVDetailsSection } from '../components/HRVDetailsSection'
import { CardioVascularSection } from '../components/CardioVascularSection'
import { NeuroHormonalSection } from '../components/NeuroHormonalSection'
import { EasternSection } from '../components/EasternSection'
import { Affix, Alert, Breadcrumb, Button, Descriptions, Divider, Empty, List, Menu, Modal, PageHeader, Select, Spin, Table, Tag } from 'antd'
import { EcgModal } from '../components/EcgModal'
import moment from 'moment'
// import QRCode from "react-qr-code";

import {
  HomeOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined
} from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

import { blue5, green5 } from '../utils/colors';
import { LoadingOutlined, PrinterOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import { BarcodeModal } from '../components/BarcodeModal'
// import { DOCTER_FOLLOW_USER } from '../graphql/mutations'
import { TrendPanBar } from '../components/TrendPanBar'
import { EditRecordingModal } from '../components/EditRecordingModal'
import { RecalculateRecordingModal } from '../components/RecalculateRecordingModal'
import { useAuth } from '../App'
import { NotesModal } from '../components/Notes/NotesModal'
import BrainContainer from '../components/Brain/BrainContainer'
import { VagalToneModal } from '../components/Vagal_Tone_Questionnaire/VagalToneModal'
// import { PDFDownloadLink, usePDF } from '@react-pdf/renderer'
// import { PDFOverviewPage } from '../pdfComponents/PDFOverviewPage'
// import html2canvas from 'html2canvas'



const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const experimentalAllowList = ["drmkessler@sbcglobal.net", "ruandn@gmail.com", "ruan@cilocybin.com", "gabriel@cilocybin.com", "drjava_k@hotmail.com", "test@mail.com"]


interface ScrollPos {
  name: String
  pos: number
}

interface HQParams {
  order_by: "asc" | "desc" | undefined,
  trend: "true" | "false" | undefined
}
const { Option } = Select;


export const PatientOverviewScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useUrlQuery()
  let auth = useAuth();
  let user = auth.getUser()
  const buttonRef = useRef<any>()

  const { userId }: any = useParams()

  // const [scrollerName, setScrollerName] = useState("")
  const [scrollPos, setScrollPos] = useState<ScrollPos>()
  const [dataIndex, setDataIndex] = useState(-1)
  const [schemaVersion, setSchemaVersion] = useState(0)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [recordingsExpanded, setRecordingsExpanded] = useState(true)



  const { loading, error, data, refetch } = useQuery(GET_USER_RECORDINGS, {
    variables: {
      _eq: userId,
      id: userId,
      where: {
        owner: { _eq: userId },
        deleted_on: { _is_null: true },
        ...(query.get('trend') ? { trend_recording: { _eq: query.get('trend') } } : { trend_recording: { _is_null: false } }),
      },
      order_by: query.get('order_by') || "asc"
    }
  })

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.scrollTo(buttonRef.current.scrollLeftMax, 0)
    }
    fetch(`${process.env.REACT_APP_API_URL}/schema_version`)
      .then((response) => {
        return response.json()
      })
      .then((version) => {
        // console.log(version)
        setSchemaVersion(version)
      })
      .catch((error) => {
        console.log(error);
      })
    if (data && data.heart_data.length) {
      setDataIndex(data.heart_data.length - 1)
    }
  }, [data, loading, location])


  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (loading || !data) {

    return <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <div>
        <Spin indicator={antIcon} />
      </div>
    </div>
  }

  const titleColumns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      width: '18%'
    },
    {
      title: 'Date',
      render: (_: string, record: any) => {
        return record.date + " - " + record.time
      },
      width: '16%'
    },
    {
      title: 'Version',
      dataIndex: 'schema_version',
      key: 'schemaVersion',
      width: '8%',
      render: (_: string, record: any) => {
        return <>{`${record?.schema_version}`} {record?.schema_version === schemaVersion ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : null}</>
      }
    },
    {
      title: "RR Results",
      dataIndex: "rr_metadata",
      key: "rr_metadata",
      render: (_: string, record: any) => {
        return (record?.rr_metadata && <div style={{ fontSize: 11 }}>
          {`Mean: ${record?.rr_metadata?.mean.toFixed()}ms Min: ${record?.rr_metadata?.min}ms Max: ${record?.rr_metadata?.max}ms Threshold:${record?.rr_metadata?.threshold} RRs Rejected:${record?.rr_metadata?.total_rejected}`}
        </div>)
      }
    },
    // {
    //   title: 'Frequency Domain',
    //   render: (_: string, record: any) => {
    //     return (<div style={{ fontSize: 11 }}>
    //       {`VLF: ${(record.vlf / record.total_power * 100).toFixed()}%   LF: ${(record.lf / record.total_power * 100).toFixed()}%   HF: ${(record.hf / record.total_power * 100).toFixed()}%`}
    //     </div>)
    //   }
    // },
    {
      title: 'Trending',
      render: (_: string, record: any) => {
        return (
          <Tag color={record.trend_recording ? 'green' : 'red'} key={record.index}>
            {record.trend_recording ? "Trending" : 'Not Trending'}
            {/* {record.trend_recording} */}
          </Tag>
        );
      }
    }
    // {
    //   title: 'Actions',
    //   render: (_: string, record: any, index: number) => {
    //     return <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', }}>
    //       {/* <div style={{ flex: 1 }}> */}
    //       <Button size="small" onClick={() => setDataIndex(record.index)
    //       }>go to recording</Button>
    //       {/* </div> */}
    //     </div>
    //   },
    //   width: '14%'
    // }
  ]

  const onTrendSortChange = (v: any) => {
    console.log(v)
    if (v === 'all') {
      const params = query.delete('trend')
      history.push({
        search: query.toString()
      })
    } else if (v === 'true') {
      history.push({
        search: `trend=true`
      })
    } else if (v === 'false') {
      history.push({
        search: `trend=false`
      })
    }

  }
  const isBluetoothCapable = 'bluetooth' in navigator;

  if (data?.heart_data.length === 0 || dataIndex === -1) {
    return <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Breadcrumb.Item>
          <Link to="/dashboard/patients">
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item >
          <Link to="/dashboard/patients">
            <span>Patients</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >
        <PageHeader
          className="site-page-header"
          onBack={history.goBack}
          title={`${data?.user?.firstName}`}
          subTitle={data?.users_by_pk?.last_name}
          extra={[

            <BarcodeModal
              name={data?.user?.firstName}
              ownerId={userId}
              age={moment().diff(data?.user?.birthDate, 'years')}
              gender={JSON.parse(data?.users_by_pk?.data)?.data?.gender.toLowerCase()}
              sampleRate={1000}
              onClose={() => refetch()}
            />,
            (() => isBluetoothCapable ? <Button>
              <Link to={location => ({ pathname: `${location.pathname}/recording` })}>Record</Link>
            </Button> : null)(),
          ]}
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 30 }}>
            <Descriptions.Item label="Age">{moment().diff(data?.user?.birthDate, 'years')}</Descriptions.Item>
            <Descriptions.Item label="Gender">{data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null}</Descriptions.Item>
            <Descriptions.Item label="Total recordings">{data?.heart_data?.length}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{}}>
            <Button icon={recordingsExpanded ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={() => {
              setRecordingsExpanded(!recordingsExpanded)
            }} >
              Recordings
            </Button>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => {
              history.push({
                search: `order_by=${query.get('order_by') === "asc" || query.get('order_by') === null ? "desc" : "asc"}`
              })
            }} size="small" >
              Sort Date: {query.get('order_by') === "desc" ? "DESC" : "ASC"}
            </Button>
            <div style={{ width: 10 }}></div>
            <Select defaultValue={query.get('trend') || "all"} style={{ width: 120 }} onChange={onTrendSortChange} size={'small'}>
              <Option value="all">All</Option>
              <Option value="true">Trend Only</Option>
              <Option value={'false'}>
                Not Trending
              </Option>
            </Select>
          </div>
        </div>

        <div style={{ height: recordingsExpanded ? 450 : 0, overflow: 'hidden' }}>
          <Table
            dataSource={[]}
            columns={titleColumns}
            size="small"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setDataIndex(record.index)
                }
              }
            }}
            rowClassName={(record, index) => record.index === dataIndex ? 'row-active' : ''}
            pagination={false} scroll={{ y: 400 }} />
        </div>
        <Empty
          style={{ marginTop: 20 }}
          description={
            <div>This user do not have any recording data yet. <br /> Please use our app and HQP device to do a recording</div>
          }
        />
      </Content>
    </>
  }

  const titles = data?.heart_data.map((d: any) => {
    return d?.title
  })

  const dates = data?.heart_data.map((d: any) => {
    return getFormatedDate(d?.created_on)
  })
  const times = data?.heart_data.map((d: any) => {
    return getFormatedTime(d?.created_on)
  })

  const freqData = data?.heart_data.map((d: any) => {
    return {
      vlf: d?.data?.frequencyDomain?.vlf ? d?.data?.frequencyDomain?.vlf : 0,
      lf: d?.data?.frequencyDomain?.vlf ? d?.data?.frequencyDomain?.lf : 0,
      hf: d?.data?.frequencyDomain?.vlf ? d?.data?.frequencyDomain?.hf : 0,
    }
  })

  const titleButtons = data?.heart_data.map((d: any, i: number) => {
    return {
      index: i,
      id: d?.id,
      title: d?.title,
      date: getFormatedDate(d?.created_on),
      time: getFormatedTime(d?.created_on),
      schema_version: d?.data?.schema_version,
      vlf: d?.data?.frequencyDomain.vlf,
      lf: d?.data?.frequencyDomain.lf,
      hf: d?.data?.frequencyDomain.hf,
      total_power: d?.data?.frequencyDomain.total_power,
      trend_recording: d?.trend_recording,
      rr_metadata: d?.rr_metadata
    }
  })




  return (
    <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Breadcrumb.Item>
          <Link to="/dashboard/patients">
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item >
          <Link to="/dashboard/patients">
            <span>Patients</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >

        <PageHeader
          className="site-page-header"
          onBack={history.goBack}
          title={`${data?.user?.firstName}`}
          subTitle={data?.users_by_pk?.last_name}
          extra={[
            <EcgModal recording={data?.heart_data[dataIndex]} />,
            <BarcodeModal
              name={data?.user?.firstName}
              ownerId={userId}
              age={moment().diff(data?.user?.birthDate, 'years')}
              gender={JSON.parse(data?.users_by_pk?.data)?.data?.gender.toLowerCase()}
              sampleRate={1000}
              onClose={() => refetch()}
            />,
            <RecalculateRecordingModal recording={data?.heart_data[dataIndex]} />,
            <EditRecordingModal recording={data?.heart_data[dataIndex]} currentIndex={dataIndex} refetch={refetch} />,
            <Button
              icon={<PrinterOutlined />}
              // target="_blank"
              loading={pdfLoading}
              style={{
                backgroundColor: green5,
                border: 'none',
                color: 'white'
              }}
              onClick={async () => {
                setPdfLoading(true)
                localStorage.setItem('pdf', JSON.stringify({
                  firstName: data?.user?.firstName,
                  lastName: data?.users_by_pk?.last_name,
                  age: moment().diff(data?.user?.birthDate, 'years'),
                  gender: data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null,
                  date: dates[dataIndex],
                  time: times[dataIndex],
                  recording: data?.heart_data[dataIndex]?.data,
                  rhythmogramImageUrl: await generateHTMLImageUrl("rgram"),
                  histogramImageUrl: await generateHTMLImageUrl("hgram"),
                  scattergramImageUrl: await generateHTMLImageUrl("sgram"),
                  mineralsImageUrl: await generateHTMLImageUrl("minerals"),
                  neurotransmittersImageUrl: await generateHTMLImageUrl("neurotransmitters"),
                  hormonesImageUrl: await generateHTMLImageUrl("hormones"),
                  easternSectionImageUrl: await generateHTMLImageUrl("easternSection")
                }))
                history.push(`${history.location.pathname}/pdf`)
                setPdfLoading(false)
              }}
            >
              Print
            </Button>,
            (() => isBluetoothCapable ? <Button>
              <Link to={location => ({ pathname: `${location.pathname}/recording` })}>Record</Link>
            </Button> : null)(),
            <NotesModal recordingId={data?.heart_data[dataIndex].id} authorId={user.id} />,
            (data?.heart_data[dataIndex]?.data?.brain &&
              <Button ><Link to={location => ({ pathname: `${location.pathname}/${data?.heart_data[dataIndex].id}/brain` })}>Brain</Link></Button>),
            <Button ><Link to={location => ({ pathname: `${location.pathname}/${data?.heart_data[dataIndex].id}/vagus` })}>Vagus Nerve</Link></Button>,
            // (experimentalAllowList.includes(user.email) ? <VagalToneModal recordingId={data?.heart_data[dataIndex].id} /> : null)
          ]}
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 30 }}>
            <Descriptions.Item label="Age">{moment().diff(data?.user?.birthDate, 'years')}</Descriptions.Item>
            <Descriptions.Item label="Gender">{data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null}</Descriptions.Item>
            <Descriptions.Item label="Total recordings">{data?.heart_data?.length}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <Divider />
        <div style={{ fontSize: 10, marginBottom: 10 }}>Calculation Version: {schemaVersion}</div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{}}>
            <Button icon={recordingsExpanded ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />} onClick={() => {
              setRecordingsExpanded(!recordingsExpanded)
            }} >
              Recordings
            </Button>
          </div>
          <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={() => {
              history.push({
                search: `order_by=${query.get('order_by') === "asc" || query.get('order_by') === null ? "desc" : "asc"}`
              })
            }} size="small" >
              Sort Date: {query.get('order_by') === "desc" ? "DESC" : "ASC"}
            </Button>
            <div style={{ width: 10 }}></div>
            <Select defaultValue={query.get('trend') || "all"} style={{ width: 120 }} onChange={onTrendSortChange} size={'small'}>
              <Option value="all">All</Option>
              <Option value="true">Trend Only</Option>
              <Option value={'false'}>
                Not Trending
              </Option>
            </Select>
          </div>
        </div>

        <div style={{ height: recordingsExpanded ? 450 : 0, overflow: 'hidden' }}>
          {/* {data?.heart_data[dataIndex].id} */}
          <Table
            dataSource={titleButtons.reverse()}
            columns={titleColumns}
            size="small"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setDataIndex(record.index)
                }
              }
            }}
            rowClassName={(record, index) => record.index === dataIndex ? 'row-active' : ''}
            pagination={false} scroll={{ y: 400 }} />
        </div>
        <div id="hrv" style={{ marginTop: 20 }}>
          <h1 style={{ textAlign: "center", lineHeight: 1 }}>{titles[dataIndex]}</h1>
          <h1 style={{ textAlign: "center", lineHeight: 1 }}>{dates[dataIndex]}</h1>
          <h2 style={{ textAlign: "center", lineHeight: 1 }}>{times[dataIndex]}</h2>
          <div style={{ height: 50 }}></div>
          {/* {JSON.stringify(query.get('trend'))} */}

          <TrendPanBar
            data={freqData}
            dataIndex={(dataIndex === -1) ? 21 : dataIndex}
            setDataIndex={setDataIndex}
            dataPos={50}
            locationChange={location}
          />

          {data?.heart_data[dataIndex]?.data?.timeDomain.sdnn > 100 && data?.heart_data[dataIndex]?.data?.frequencyDomain.total_power > 5000 ?

            <Alert
              message="Warning"
              description={`This recording indicates a high probability of an irregular heart rhythm leading to possible inaccurate results (falsely elevated values) on HQP.
            Further evaluation with a licensed heart professional may be warranted if this pattern continues.`}
              type="error"
              style={{ marginBottom: 30, marginTop: 10 }}
            />
            : null
          }

          <div style={{ height: 20 }}></div>
          <HRVDetailsSection recording={data?.heart_data[dataIndex]} user={data?.user} />
          <div id="cardio" style={{ height: 150, marginTop: 50 }}>
            <Divider >
              Cardiovascular Section
            </Divider>
          </div>

          <div style={{ height: 50 }}></div>
          <CardioVascularSection recording={data?.heart_data[dataIndex]} />
          <div id="neuro" style={{ height: 150, marginTop: 50 }}>
            <Divider >
              Neurohormonal Section
            </Divider>
          </div>

          <div style={{ height: 50 }}></div>
          <NeuroHormonalSection recording={data?.heart_data[dataIndex]} />
          <div id="eastern" style={{ height: 150, marginTop: 50 }}>
            <Divider >
              Eastern Section
            </Divider>
          </div>

          <div style={{ height: 50 }}></div>
          <EasternSection recording={data?.heart_data[dataIndex]} />
          <div style={{ height: 50 }}></div>
        </div>

      </Content>

    </>
  )
}


