import React from 'react';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryHistogram, VictoryTheme } from 'victory';
import { blue5, green5, red5 } from '../utils/colors';
import { binInterval } from '../utils/helperFunctions';

export const HistogramRRDisplay = ({ rythmData }: any) => {

  // console.log("UnFilterred", rythmData);
  const rythmDataFiltered = rythmData.filter(checkRejected)
  function checkRejected(d: any) {
    return d.rejected === false;
  }
  // console.log("Filterred", rythmDataFiltered);


  return (
    <>
      <VictoryChart width={350} height={350}  >
        <VictoryAxis dependentAxis
          style={{
            tickLabels: { fontSize: 8, fill: "black" },
          }}
        />
        <VictoryAxis
          style={{
            tickLabels: { fontSize: 8, fill: "black" },
          }}
        />
        <VictoryHistogram
          binSpacing={7}
          style={{
            data: {
              fill: blue5,
              fillOpacity: 1,
              stroke: "none",
              strokeWidth: 1,
            }
          }}
          data={rythmDataFiltered?.map((d: any) => ({ x: d.y }))}
          bins={binInterval(50)}
        />
        <VictoryArea data={[{ x: 500, y: 200 }, { x: 700, y: 200 }]} style={{ data: { fill: red5, opacity: .1, strokeWidth: 0 } }} />
        <VictoryArea data={[{ x: 700, y: 200 }, { x: 1050, y: 200 }]} style={{ data: { fill: green5, opacity: .1, strokeWidth: 0 } }} />
        <VictoryArea data={[{ x: 1050, y: 200 }, { x: 1200, y: 200 }]} style={{ data: { fill: blue5, opacity: .1, strokeWidth: 0 } }} />
        <VictoryAxis
          dependentAxis
          offsetX={30}
          theme={VictoryTheme.material}
          tickValues={[100, 150]}
          tickFormat={(tick) => ``}
          style={{
            axis: { display: "none" },
            ticks: { display: "none", },
            // tickLabels: { fontSize: 4, fill: "black" },
            grid: { stroke: '#333333', strokeWidth: 0.2 },
          }}
        />
      </VictoryChart>
    </>
  )
};
