import React, { useEffect, useState } from 'react'
import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Switch } from 'antd';
import { red5, yellow5 } from '../utils/colors';
import { useMutation } from '@apollo/client';
import { DELETE_RECORDING_BY_PK, UPDATE_RECORDING_BY_PK } from '../graphql/mutations';

interface EditRecordingModalProps {
  recording: any,
  currentIndex: number,
  refetch: () => void
}

export const EditRecordingModal = ({ recording, refetch }: EditRecordingModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [form] = Form.useForm();

  const [updateRecording, { data, loading, error }] = useMutation(UPDATE_RECORDING_BY_PK, {
    onCompleted: () => {
      setIsModalVisible(false);
    }
  })

  const [deleteRecording, { data: deleteData, error: deleteError, loading: loadingDelete, reset }] = useMutation(DELETE_RECORDING_BY_PK, {
    onCompleted: () => {
      refetch()
    }
  })

  useEffect(() => {
    // Manually Reset Confirm Delete
    setConfirmDelete(false)
    form.setFieldsValue({
      title: recording?.title,
      trend_recording: recording?.trend_recording
    });

    return () => {
      // form.resetFields()
    }
  }, [recording])

  if (!recording) {
    return null
  }


  const handleOk = () => {
    setIsModalVisible(false);
  }

  const handleCancel = () => {
    setIsModalVisible(false);
    setConfirmDelete(false)
  }

  const onFinish = (values: any) => {
    // console.log("My values", values);
    updateRecording({
      variables: {
        id: recording.id,
        title: values.title,
        trend_recording: values.trend_recording
      }
    })

  }

  const onFinishFailed = () => {

  }

  const onConfirmDelete = () => {
    setConfirmDelete(true)
  }
  if (deleteData) {
    console.log(deleteData);
  }


  return (
    <>
      <Button
        icon={<EditOutlined />}
        style={{
          backgroundColor: "darkorange",
          border: 'none',
          color: 'white'
        }}
        onClick={() => setIsModalVisible(true)}
      >
        Edit
      </Button>

      <Modal
        // width={2000}
        footer={[
          <Button type="primary" danger onClick={onConfirmDelete} hidden={confirmDelete}>
            Delete Recording
          </Button>,
          <Button onClick={handleCancel} hidden={deleteData}>
            Discard Changes
          </Button>,
          <Button type='primary' form="edit_recording" key="submit" htmlType="submit" loading={loading} hidden={confirmDelete}>
            Save Changes
          </Button>,
          <Button type='primary' hidden={!deleteData} onClick={() => {
            reset()
            setIsModalVisible(false)

          }}>
            Close
          </Button>
        ]}

        title={confirmDelete ? `Delete ${recording.title}` : "Edit Recording"}
        visible={isModalVisible}
        okText="Save"
        onOk={handleOk}
        onCancel={handleCancel}>
        {/* {recording.id}
        {JSON.stringify(data)} */}
        {JSON.stringify(error)}
        {!confirmDelete &&
          <Form
            form={form}
            preserve={false}
            name="edit_recording"

            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: 'Please input a valid title' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Show In Trend"
              name="trend_recording"
              valuePropName="checked"
            // rules={[{ required: true, message: 'Please input a valid title' }]}
            >
              <Switch />
            </Form.Item>

          </Form>}
        {
          confirmDelete && !deleteData &&
          <div style={{ textAlign: 'center' }}>
            <div>You are about to delete this recording.</div>
            <div>Are you sure you want to delete <b>{recording.title}</b>?</div>
            {deleteError && <div>{JSON.stringify(error)}</div>}
            <div>
              <Button type="primary" danger onClick={() => {
                deleteRecording({
                  variables: {
                    id: recording.id
                  }
                })

              }} style={{ marginTop: 10 }} loading={loadingDelete}>
                Delete Recording
              </Button>
              {/* <Button style={} onClick={onConfirmDelete} >
                Don't delete
              </Button> */}
            </div>
          </div>
        }
        {
          deleteData &&
          <div>Your recording was deleted successfully!</div>
        }
        {/* <pre>
          {JSON.stringify(recording, null, 2)}
        </pre> */}
      </Modal>
    </>
  )
}
