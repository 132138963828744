import { Document, Page, Font, Text, Svg, PDFViewer, PDFDownloadLink, View, Path, G, Rect, Defs, LinearGradient, Stop, Line, Image } from '@react-pdf/renderer';
import { useHistory, useLocation } from 'react-router-dom';
// import { PDFFrequencyDomainPie } from './PDFFrequencyDomainPie';
import { ArrowLeftOutlined } from '@ant-design/icons';
import * as d3 from 'd3';
import { pie, scaleLinear } from 'd3';
import './../App.css'
import { Button } from 'antd';
import { green5, red5, yellow5 } from '../utils/colors';
import { AndCnsHorisontalPDF, energyDepHorisontalPDF, polyvagalHorisontalPDF, psActivityHorisontalPDF, stressIndexHorisontalPDF, totalPowerHorisontalPDF } from '../utils/gradients';
import { calculatePolyVagalPDF } from '../components/HRVDetailsSection';
import { getNeuroH } from '../components/NeuroHCardDisplay';

interface PDFOverViewData {
  firstName: string,
  lastName?: string,
  date?: string,
  recordingName?: string
  data: any
}

export const PDFOverviewPage = () => {
  const stateString = localStorage.getItem("pdf")
  const history = useHistory();


  if (!stateString) {
    return <div>Could not generate PDF</div>
  }
  const state = JSON.parse(stateString)
  console.log(state)

  return (
    <div style={{
      height: "100%",
      padding: 20,
    }}>
      <Button
        icon={<ArrowLeftOutlined />}
        type="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>
      <PDFDownloadLink
        document={<PDFOverviewStructure data={state} firstName={state?.firstName} />}
        fileName={`${state?.firstName}-${state?.lastName}.pdf`}
      >
        {({ loading }) => (
          <Button
            icon={<ArrowLeftOutlined />}
            loading={loading}
            style={{
              backgroundColor: green5,
              border: 'none',
              color: 'white',
              float: "right",
              margin: 10
            }}
          >
            Download
          </Button>
        )}
      </PDFDownloadLink>
      <PDFViewer
        showToolbar={false}
        style={{
          width: '100%',
          height: '100%',
          border: "none",
        }}
      >
        <PDFOverviewStructure data={state} firstName={state?.firstName} />
      </PDFViewer>
    </div>
  )
}

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const PDFOverviewStructure = ({ data }: PDFOverViewData) => {
  return (
    <>

      <Document >
        <Page size="A4" style={{
          display: 'flex',
          flexDirection: 'column',
        }} >
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            padding: 20
          }}>
            <Text style={{
              textAlign: "center",
              fontWeight: "bold",
              color: "#999"
            }}>HQ REPORT</Text>
          </View>
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'row',
            paddingLeft: 20,
            paddingRight: 20
          }}>
            <View style={{
              width: "50%",
              marginTop: 60
            }}>
              <Text style={{
                fontSize: 18,
                color: "#222222"
              }}>{data.firstName} {data.lastName}</Text>
              {/* <Text style={{
                fontSize: 16,
                color: "#222222",
                marginTop: 4
              }}>{data.lastName}</Text> */}
              <Text style={{
                fontSize: 12,
                color: "#222222",
                marginTop: 5
              }}>{data.date} - {data.time}</Text>
              <Text style={{
                fontSize: 8,
                color: "#222222",
                marginTop: 5
              }}>Age: {data.age}</Text>
              <Text style={{
                fontSize: 8,
                color: "#222222",
                marginTop: 5
              }}>Gender: {data.gender}</Text>
            </View>
            <View style={{
              width: "50%",
              padding: 10,
              alignItems: "flex-end"
            }}>
              <Svg width="112" height="94" viewBox="0 0 112 94" >
                <Path d="M6.40197 4.08203H0V90.9906H6.40197V4.08203Z"
                  fill="#444" />
                <Path d="M42.0419 44.3389H13.918V50.7408H42.0419V44.3389Z" fill="#444" />
                <Path d="M76.0436 92.9878L49.7695 66.7095V28.9847L78.7542 0L108.645 29.891V64.272L102.243 58.0149V32.5436L78.7542 9.05462L56.1715 31.6374V64.0569L85.0983 92.9878H76.0436Z" fill="#444" />
                <Path d="M89.0436 66.6721L84.5684 71.25L106.819 93.0012L111.294 88.4233L89.0436 66.6721Z" fill="#444" />
              </Svg>
              <Text style={{
                marginTop: 5,
                fontSize: 21,
                color: "#444"
              }}>Heart Quest</Text>
            </View>
          </View>
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            padding: 20
          }}>
            <Text style={{
              textAlign: "center",
              color: "#CECECE"
            }}>HRV SECTION</Text>
          </View>
          <View style={{
            padding: 20,
            display: 'flex',
            flexDirection: 'row',
          }}>

            <View style={{
              width: "40%"
            }}>
              <PDFPie vlf={(data.recording.frequencyDomain.vlf_percentage * 100)} lf={(data.recording.frequencyDomain.lf_percentage * 100)} hf={(data.recording.frequencyDomain.hf_percentage * 100)} />
              <View style={{
                marginTop: 40
              }}>
                <View style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 15
                }}>
                  <PDFValueCard title='Heart Rate' value={data.recording.bpm.toFixed()} idealRange={"(60-84)"} />
                  <PDFValueCard title='SDNN' value={data.recording.timeDomain.sdnn.toFixed()} idealRange={"(50-70)"} />
                </View>
              </View>
              <View>
                <View style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 15
                }}>
                  <PDFValueCard title='RMSSD' value={data.recording.timeDomain.rmssd.toFixed()} idealRange={"(15-45+)"} />
                  <PDFValueCard title='LF-HF Ration' value={data.recording.frequencyDomain.lf_hf_ratio.toFixed(2)} idealRange={"(1-3)"} />
                </View>
              </View>
            </View>
            <View style={{
              width: "60%",
              display: 'flex',
              flexDirection: 'column',
            }}>
              <SingleHorizontalBarLabel title='Total Power' value={data.recording.frequencyDomain.total_power.toFixed()} idealRange="(1500 3500)" />
              <PDFHorizontalBar
                width={330}
                value={data.recording.frequencyDomain.total_power}
                stops={[1500, 3500]}
                absDomain={[500, 6000]}
                gradientArray={totalPowerHorisontalPDF}
                subLabelArray={[
                  {
                    name: "Low",
                    value: 620
                  },
                  {
                    name: "1500",
                    value: 1500
                  },
                  {
                    name: "3500",
                    value: 3500
                  },
                  {
                    name: "High",
                    value: 5900
                  },
                ]}
              />
              <SingleHorizontalBarLabel title='Stress Index' value={data.recording.stressIndex.toFixed()} idealRange="(10 100)" />
              <PDFHorizontalBar
                width={330}
                value={data.recording.stressIndex}
                absDomain={[10, 500]}
                gradientArray={stressIndexHorisontalPDF}
                stops={[100]}
                subLabelArray={[
                  {
                    name: "Low",
                    value: 20
                  },
                  {
                    name: "100",
                    value: 100
                  },
                  {
                    name: "High",
                    value: 490
                  },
                ]}
              />
              <SingleHorizontalBarLabel title='Nervous System Balance Index' value={data.recording.cns_ans.toFixed()} idealRange="(1-3)" />
              <PDFHorizontalBar
                width={330}
                value={data.recording.cns_ans.toFixed()}
                absDomain={[0.1, 5]}
                gradientArray={AndCnsHorisontalPDF}
                stops={[1, 3]}
                subLabelArray={[
                  {
                    name: "CNS",
                    value: .2
                  },
                  {
                    name: "ANS",
                    value: 4.9
                  },
                ]}
              />
              <View style={{
                border: "2px solid #CECECE",
                borderRadius: 10,
                marginTop: 50
              }}>
                <Text style={{
                  fontSize: 16,
                  textAlign: 'center',
                  marginBottom: 10
                }}>Poly Vagal</Text>
                <SingleHorizontalBarLabel title='Parasympathetic Activity' value={`${(data.recording.frequencyDomain?.hf / data.recording.frequencyDomain?.total_power * 100).toFixed()}%`} idealRange="(0-70)" />
                <PDFHorizontalBar
                  width={330}
                  value={(data.recording.frequencyDomain?.hf / data.recording.frequencyDomain?.total_power * 10)}
                  absDomain={[0, 7.0]}
                  gradientArray={psActivityHorisontalPDF}
                  // stops={[1, 3]}
                  subLabelArray={[{ name: "15%", value: 1.5 }, { name: "30%", value: 3.0 }, { name: "70%", value: 6.7 }]}

                // subLabelArray={[{ name: "5K", value: 5000 }, { name: "3.5K", value: 3500 }, { name: "1.5K", value: 1500 }, { name: "1K", value: 1000 }, { name: "0.5", value: 500 }, { name: ".1", value: 100 }]}

                />
                <SingleHorizontalBarLabel title='Energy Index' value={`${(data.recording.frequencyDomain?.total_power / 1000).toFixed(1)}K`} idealRange="(100-5000)" />
                <PDFHorizontalBar
                  width={330}
                  value={data.recording.frequencyDomain?.total_power}
                  absDomain={[5000, 100]}
                  gradientArray={energyDepHorisontalPDF}
                  // stops={[1, 3]}

                  subLabelArray={[{ name: "5K", value: 5000 }, { name: "3.5K", value: 3500 }, { name: "1.5K", value: 1500 }, { name: "1K", value: 1000 }, { name: "0.5", value: 500 }, { name: "0.1", value: 100 }]}

                />
                <SingleHorizontalBarLabel title='Poly-Vagal' value={calculatePolyVagalPDF(data.recording).toFixed(2)} idealRange="(0.25-3.0)" />
                <PDFHorizontalBar
                  width={330}
                  value={calculatePolyVagalPDF(data.recording)}
                  absDomain={[0.25, 3.0]}
                  gradientArray={polyvagalHorisontalPDF}
                  // stops={[1, 3]}
                  insideLabelArray={[{ name: "0.25", value: 0.32 }, { name: "0.5", value: 0.5 }, { name: "0.8", value: 0.8 }, { name: "1.0", value: 1 }, { name: "1.2", value: 1.15 }, { name: "3.0", value: 2.9 }]}
                  subLabelArray={[{ name: "Ventral nucleous", value: 0.5 }, { name: "Balanced", value: 1.2 }, { name: "Dorsal Nucleous", value: 2.65 }]}
                />
              </View>
            </View>

          </View>
        </Page>
        <Page size="A4" style={{
          display: 'flex',
          flexDirection: 'column',
        }} >
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            padding: 20
          }}>
            <Text style={{
              textAlign: "center",
              color: "#CECECE"
            }}>CARDIO VASCULAR SECTION</Text>
          </View>
          <View style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            padding: 20
          }}>
            <View style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignContent: "space-between",
              alignItems: "center"
            }}>
              <PDFValueCard title='HRV Index' value={data.recording.hrvIndex.toFixed()} idealRange={"(6-25)"} />
              <PDFValueCard title='Heart Rate' value={data.recording.bpm.toFixed()} idealRange={"(50-84)"} />
              <PDFValueCard title='RMSSD' value={data.recording.timeDomain.rmssd.toFixed()} idealRange={"(15-45+)"} />
              <PDFValueCard title='aMo%' value={(data.recording.amo * 100).toFixed(1)} idealRange={"(10-50)"} />
            </View>
          </View>
          <View style={{
            paddingLeft: 25,
            paddingRight: 25
          }}>
            <Text style={{
              fontSize: 16,
              textAlign: 'center',
              marginBottom: 10
            }}>Rhytmogram</Text>
            <Image src={data.rhythmogramImageUrl} style={{
              width: "100%"
            }} />

          </View>

          <View style={{
            marginTop: 50,
            paddingLeft: 15,
            paddingRight: 15,
            display: "flex",
            flexDirection: "row"
          }}>
            <View style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: "50%"
            }}>
              <Text style={{
                fontSize: 16,
                textAlign: 'center',
                marginBottom: 10
              }}>Histogram</Text>
              <Image src={data.histogramImageUrl} />
            </View>
            <View style={{
              paddingLeft: 10,
              paddingRight: 10,
              width: "50%"
            }}>
              <Text style={{
                fontSize: 16,
                textAlign: 'center',
                marginBottom: 10
              }}>Scattergram</Text>
              <Image src={data.scattergramImageUrl} />
            </View>
          </View>

        </Page>
        <Page size="A4" style={{
          display: 'flex',
          flexDirection: 'column',
        }} >
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            padding: 20
          }}>
            <Text style={{
              textAlign: "center",
              color: "#CECECE"
            }}>NEUROHORMONAL SECTION</Text>
          </View>
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'row',
            padding: 20
          }}>
            <View style={{
              width: "100%",
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Image src={data.mineralsImageUrl} />
              <View style={{
                marginTop: 20,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignContent: "space-between",
                alignItems: "center"
              }}>
                <PDFValueCard title='ICP' value={data.recording.icp} idealRange={"(WNL-HIGH)"} />
                <PDFValueCard title='NeuroH Reg' value={getNeuroH(data.recording.neuro_hormonal_reg)} idealRange={"(10-100)"} />
              </View>
            </View>
            <View style={{
              width: "100%",
              display: 'flex',
              flexDirection: 'column',
            }}>
              <Image src={data.neurotransmittersImageUrl} />
            </View>
            <View style={{
              width: "100%",
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: 10,
              marginBottom: 10
            }}>
              <Image src={data.hormonesImageUrl} style={{ paddingBottom: 10 }} />
            </View>
          </View>
        </Page>
        <Page size="A4" style={{
          display: 'flex',
          flexDirection: 'column',

        }} >
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            padding: 20
          }}>
            <Text style={{
              textAlign: "center",
              color: "#CECECE"
            }}>EASTERN SECTION</Text>
          </View>
          <View style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: 70,
            paddingRight: 70
          }}>
            <Image src={data.easternSectionImageUrl} />
          </View>
        </Page>

      </Document>
    </>
  )
}

interface PDFPieProps {
  vlf: number
  lf: number
  hf: number
}

const PDFPie = ({ vlf, lf, hf }: PDFPieProps) => {
  const data = [
    { number: vlf, name: "VLF", subName: "NeuroH", color: red5 },
    { number: lf, name: "LF", subName: "SNS", color: yellow5 },
    { number: hf, name: "HF", subName: "PNS", color: green5 },
  ];

  const data_ready = pie<any>()
    .value((d: any) => d.number)
    (data);


  var arcGenerator = d3.arc()
    .innerRadius(0)
    .outerRadius(100)


  return (
    <Svg style={{
      height: 200,
      width: 200,
    }}>
      <G transform='translate(100, 100)'>
        {
          data_ready.map((d: any, i: any) => {
            const path = arcGenerator(d)
            return path && <Path key={i} d={path} stroke="white" strokeWidth={3} fill={d.data.color} />
          })
        }
      </G>
      <G transform='translate(100, 100)'>
        {
          data_ready.map((d: any, i: any) => {
            const location = arcGenerator.centroid(d)
            return location && <G key={i}>
              <Text
                textAnchor="middle"
                x={location[0]}
                y={location[1]}
                style={{
                  fontSize: 14,
                  textAlign: "center"
                }}  >{`${d.data.number.toFixed()}%`}</Text>
              <Text
                textAnchor="middle"
                x={location[0]}
                y={location[1] + 10}
                style={{
                  fontSize: 12,
                  textAlign: "center"
                }} >{d.data.name}</Text>
              <Text
                textAnchor="middle"
                x={location[0]}
                y={location[1] + 20}
                style={{
                  fontSize: 10,
                  textAlign: "center"
                }} >{d.data.subName}</Text>
            </G>
          })
        }
      </G>
    </Svg>
  )
}

interface PDFHorizontalBarProps {
  width: number
  gradientArray: PDFHorizontalBarGradient[]
  absDomain: [number, number]
  value: number
  stops?: number[]
  subLabelArray?: LabelArray[]
  insideLabelArray?: LabelArray[]
}
interface PDFHorizontalBarGradient {
  offset: string
  color: string
}

interface LabelArray {
  value: number
  name: string
}

const PDFHorizontalBar = ({ width = 300, gradientArray, absDomain, value, stops, subLabelArray, insideLabelArray }: PDFHorizontalBarProps) => {

  const scaleX = scaleLinear()
    .domain(absDomain)
    .range([0, width - 10])
    .clamp(true)

  return (
    <Svg style={{
      width: width,
      height: 40,
    }}>
      <Defs>
        <LinearGradient
          id="myLinearGradient"
          x1={0}
          x2={1}
          y1={0}
          y2={0}>
          {gradientArray.map((g, i) => {
            return <Stop key={i} offset={g.offset} stopColor={g.color} />
          })}

        </LinearGradient>
      </Defs>
      <Rect
        x="5"
        y="3"
        rx="5"
        ry="5"
        width={width - 10}
        height="10"
        fill="url('#myLinearGradient')"
      />
      {stops &&
        stops.map((v, i) => {
          return <Line key={i} x1={5 + scaleX(v)} y1={1} x2={5 + scaleX(v)} y2={26} stroke={"white"} strokeWidth="2" />
        })
      }
      {subLabelArray &&
        subLabelArray.map((v, i) => {
          return <Text key={i} x={5 + scaleX(v.value)} y={22} textAnchor='middle' style={{
            fontSize: 8,
            color: "#222222"
          }} >{v.name}</Text>
        })
      }

      {insideLabelArray &&
        insideLabelArray.map((v, i) => {
          return <Text
            key={i}
            x={5 + scaleX(v.value)}
            y={11}
            textAnchor='middle'
            fill='#FFFFFF'
            style={{
              fontSize: 8,
            }} >{v.name}</Text>
        })
      }

      <Line x1={5 + scaleX(value)} y1={0} x2={5 + scaleX(value)} y2={17} stroke={"#222222"} strokeWidth="2" />

    </Svg>
  )
}

interface SingleHorizontalBarLabelProps {
  title: string
  value: number | string
  idealRange: string
  size?: "small" | "regular"
}

const SingleHorizontalBarLabel = ({ title, value, idealRange, size = "regular" }: SingleHorizontalBarLabelProps) => {
  return (
    <>
      <Text style={{
        textAlign: "center",
        fontSize: size === "small" ? 10 : 14
      }}>{title} {value ? `= ${value}` : ''}</Text>
      <Text style={{
        textAlign: "center",
        fontSize: 8,
        color: "#444444"
      }}>{idealRange}</Text>
    </>
  )
}

interface PDFValueCardProp {
  title: string
  value: number | string
  idealRange: string
}

const PDFValueCard = ({ title, value, idealRange }: PDFValueCardProp) => {
  return (
    <View style={{
      border: "2px solid #CECECE",
      borderRadius: 10,
      width: "100%",
      padding: 5,
      margin: 5
    }}>
      <Text style={{
        textAlign: "center",
        fontSize: 18
      }}>{value}</Text>
      <Text style={{
        textAlign: "center",
        fontSize: 12
      }}>{title}</Text>
      <Text style={{
        textAlign: "center",
        fontSize: 8,
        color: "#444444"
      }}>{idealRange}</Text>
    </View>
  )
}

const RhythmogramDisplayPDF = () => {
  return (
    <Svg style={{
      width: "100%",
      height: 150,
      backgroundColor: "#CECECE"
    }}>

    </Svg>
  )
}

const HistogramPDF = () => {
  return (
    <Svg style={{
      width: "100%",
      height: 150,
      backgroundColor: "#CECECE"
    }}>

    </Svg>
  )
}

const ScattergramPDF = () => {
  return (
    <Svg style={{
      width: "100%",
      height: 150,
      backgroundColor: "#CECECE"
    }}>

    </Svg>
  )
}