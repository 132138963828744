import { useQuery } from '@apollo/client'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useAuth } from '../App'
import { GET_USER_RECORDINGS } from '../graphql/queries'
import { VictoryStack, VictoryArea, VictoryTheme, VictoryChart, VictoryScatter, VictoryAxis, VictoryPie } from 'victory';
import { getFormatedDate, getFormatedTime, useResizeObserver } from '../utils/helperFunctions'
import { StackedAreaScrollComponent } from '../components/StackedAreaScrollComponent'
import { HorisontalBarComponent } from '../components/HorisontalBarComponent'
import { HRVDetailsSection } from '../components/HRVDetailsSection'
import { CardioVascularSection } from '../components/CardioVascularSection'
import { NeuroHormonalSection } from '../components/NeuroHormonalSection'
import { ScrollSync } from 'react-scroll-sync'
import { EasternSection } from '../components/EasternSection'
import { Breadcrumb, Button, Descriptions, Divider, PageHeader, Select } from 'antd'
import { EcgModal } from '../components/EcgModal'
import moment from 'moment'
import { CompareContainer } from '../components/CompareContainer'
import { Link, useHistory } from 'react-router-dom'
import {
  HomeOutlined,
  UserOutlined
} from '@ant-design/icons'

const returnRandomDataArray = (size: number) => {
  let newArr: any[] = []
  for (let i = 0; i < size; i++) {
    newArr.push(Math.random() * 11)
  }
  return newArr
}

// const data = returnRandomDataArray(100)


interface ScrollPos {
  name: String
  pos: number
}

export const PatientComparisonScreen = () => {

  const { userId }: any = useParams()
  const history = useHistory();
  const [leftSelected, setLeftSelected] = useState()
  const [rightSelected, setRightSelected] = useState()

  const { loading, error, data } = useQuery(GET_USER_RECORDINGS, {
    variables: {
      _eq: userId,
      id: userId,
      where: {
        owner: { _eq: userId },
        deleted_on: { _is_null: true },
        // ...(query.get('trend') ? { trend_recording: { _eq: query.get('trend') } } : { trend_recording: { _is_null: false } }),
      },
      // order_by: query.get('order_by') || "asc"
    }
  })

  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (loading || !data) {
    return <p>Loading...</p>
  }

  const { Option } = Select;

  function onLeftChange(value: any) {
    console.log(`selected ${value}`);
    setLeftSelected(value)
  }
  function onRightChange(value: any) {
    console.log(`selected ${value}`);
    setRightSelected(value)
  }

  // function onBlur() {
  //   console.log('blur');
  // }

  // function onFocus() {
  //   console.log('focus');
  // }

  // function onSearch(val: any) {
  //   console.log('search:', val);
  // }


  return (
    <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item>
            <HomeOutlined />
            <span>Home</span>
          </Breadcrumb.Item>
        </Link>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item >
            <span>Patients</span>
          </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >

        <PageHeader
          className="site-page-header"
          onBack={history.goBack}
          title={`${data?.user?.firstName}`}
          subTitle={data?.users_by_pk?.last_name}
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 30 }}>
            <Descriptions.Item label="Age">{moment().diff(data?.user?.birthDate, 'years')}</Descriptions.Item>
            <Descriptions.Item label="Gender">{data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null}</Descriptions.Item>
            <Descriptions.Item label="Total recordings">{data?.heart_data?.length}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <Divider />
        <h4 style={{ lineHeight: 1 }}>To compare recording select a recording in the dropdown list on both columns</h4>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column"
        }}>
          <div style={{ flex: 1, marginTop: 20, marginBottom: 40, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <div style={{ flex: 1, textAlign: "center" }}>
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Select a recording"
                optionFilterProp="children"
                onChange={onLeftChange}
              // onFocus={onFocus}
              // onBlur={onBlur}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              >
                {data?.heart_data?.map((rec: any, index: number) => {
                  return <Option key={index} value={index}>
                    <h3>{rec.title}</h3>
                    <p>{getFormatedDate(rec.created_on)}</p>
                  </Option>
                })}

              </Select>
            </div>
            <div style={{ flex: 1, textAlign: "center" }}>
              <Select
                showSearch
                style={{ width: 300 }}
                placeholder="Select a recording"
                optionFilterProp="children"
                onChange={onRightChange}
              // onFocus={onFocus}
              // onBlur={onBlur}
              // onSearch={onSearch}
              // filterOption={(input, option) =>
              //   option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
              >
                {data?.heart_data?.map((rec: any, index: number) => {
                  return <Option key={index} value={index}>
                    <div hidden><p>{rec.title}{rec.created_on}</p></div>
                    <h3>{rec.title}</h3>
                    <p>{getFormatedDate(rec.created_on)}</p>
                  </Option>
                })}
              </Select>
            </div>
          </div>
          <div style={{ flex: 1, width: "100%", height: 600, display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 30 }}>
            <div style={{ flex: 1, minHeight: 600, backgroundColor: "#f4f4f4", padding: 10 }}>
              {leftSelected !== undefined ?
                <CompareContainer heartData={data.heart_data[leftSelected ?? 0]} />
                : null}
            </div>
            <div style={{ flex: 1, minHeight: 600, backgroundColor: "#f4f4f4", padding: 10 }}>
              {
                rightSelected !== undefined ?
                  <CompareContainer heartData={data.heart_data[rightSelected ?? 0]} />
                  : null
              }
            </div>
          </div>
        </div>

      </Content>
    </>
  )
}
