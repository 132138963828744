import React, { useRef } from 'react'
import { useResizeObserver } from '../utils/helperFunctions'
import { scaleLinear } from 'd3'
import { blue5, green5, yellow5 } from '../utils/colors'

export const HorisontalBarComponent = ({
  value,
  domain,
  barHeight = 15,
  decimal = 2,
  backgroundColor = `linear-gradient(90deg, ${blue5} 0%, ${green5} 50%, ${yellow5} 100%)`,
  leftText,
  rightText,
  valueMarker = false,
  onClick,
  goodRange = null,
  markers = [],
  additionalMarkers = []
}: any) => {
  const ref = useRef<any>()
  const resizeObserver = useResizeObserver(ref)
  const containerWidth = resizeObserver?.width

  const scaleX = scaleLinear()
    .domain(domain ? domain : [0, 100])
    .range([0, containerWidth ? containerWidth : 0])
    .clamp(true)

  const fontScale = scaleLinear()
    .domain([10, 25])
    .range([10, 18])
    .clamp(true)


  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "row",
        padding: barHeight,
        position: "relative",
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <div style={{
        width: "100%",
        height: barHeight,
        background: backgroundColor,
        borderRadius: 25,
        display: "flex",
        position: "relative",
        WebkitPrintColorAdjust: "exact"
      }}>
        {leftText ? <div style={{ color: 'grey', marginLeft: 0, lineHeight: 5, fontSize: 12, fontWeight: 500, position: 'absolute', left: 0 }}>{leftText}</div> : null}
        <div style={{ width: "100%" }}></div>
        {rightText ? <div style={{ color: 'grey', marginLeft: 0, lineHeight: 5, fontSize: 12, fontWeight: 500, textAlign: 'right', position: 'absolute', right: 0 }}>{rightText}</div> : null}

        {
          markers.length ? markers.map((m: { name: string, value: number }, i: number) => {
            return (
              <div key={i} style={{ color: 'grey', left: scaleX(m.value), top: -5, lineHeight: 6, fontSize: 12, fontWeight: 500, position: 'absolute', textAlign: 'center' }}>{m.name}</div>
            )
          }) : null
        }
        {
          additionalMarkers.length ? additionalMarkers.map((m: { name: string, value: number }, i: number) => {
            return (
              <div key={i} style={{ color: 'white', left: scaleX(m.value), top: -28, lineHeight: 6, fontSize: 12, fontWeight: 500, position: 'absolute', textAlign: 'center' }}>{m.name}</div>
            )
          }) : null
        }

      </div>
      {!valueMarker ?
        <div style={{
          position: "absolute",
          width: (barHeight * 2),
          height: (barHeight * 2),
          borderRadius: 25,
          backgroundColor: "white",
          top: barHeight / 2,
          left: scaleX(value) + barHeight,
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textAlign: "center",
          // lineHeight: height / 7.5
          display: 'flex',
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div style={{ flex: 1, fontSize: fontScale(barHeight) }}>
            {value?.toFixed(decimal)}
          </div>
        </div> : <div style={{
          position: "absolute",
          width: 0, // (barHeight * 2),
          height: (barHeight * 2),
          borderRadius: 25,
          backgroundColor: "#333",
          top: barHeight / 2,
          left: scaleX(value) + barHeight,
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          textAlign: "center",
          // lineHeight: height / 7.5
          display: 'flex',
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid #333"
        }}>
          {/* <div style={{ flex: 1, fontSize: fontScale(barHeight) }}>
          {value?.toFixed(decimal)}
        </div> */}
        </div>}
      {goodRange ?
        <>
          <div style={{
            position: "absolute",
            width: 0, // (barHeight * 2),
            height: (barHeight * 2),
            borderRadius: 25,
            backgroundColor: "white",
            top: barHeight / 2,
            left: scaleX(goodRange[0]) + barHeight,
            // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            textAlign: "center",
            // lineHeight: height / 7.5
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid white"
          }}></div>
          <div style={{
            position: "absolute",
            width: 0, // (barHeight * 2),
            height: (barHeight * 2),
            borderRadius: 25,
            backgroundColor: "white",
            top: barHeight / 2,
            left: scaleX(goodRange[1]) + barHeight,
            // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            textAlign: "center",
            // lineHeight: height / 7.5
            display: 'flex',
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid white"
          }}></div>
        </>

        : null
      }
    </div>
  )
}
