import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Avatar, Button, Col, DatePicker, Empty, Form, Input, List, message, Row, Space, Table, Tag } from 'antd';
import { Content } from 'antd/lib/layout/layout'
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import moment from 'moment';
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../App';
import { GET_HEART_DATA_BY_OWNER, GET_PRAC_PATIENTS, GET_USERS_BY_EMAIL } from '../graphql/queries';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { debounce, divide } from 'lodash';
import { DOCTER_FOLLOW_USER } from '../graphql/mutations';

const { Search } = Input;

export const ConnectPatient = () => {
  let auth = useAuth();
  let history = useHistory();
  let currentFollowees: string[] = []

  const [name, setName] = useState("")

  const patients = useQuery(GET_PRAC_PATIENTS, {
    variables: { _id: auth.getUser()?.id },
  });

  if (patients.data) {
    currentFollowees = patients.data.followers.map((f: any) => f.followee.id)
  }

  const [getUsersByEmail, { loading, data }] = useLazyQuery(GET_USERS_BY_EMAIL);
  const [followUser, followerRequest] = useMutation(DOCTER_FOLLOW_USER, {
    onCompleted: (data: any) => {
      followSuccess(name)
      history.push('/dashboard/patients')
    },
    refetchQueries: [{
      query: GET_PRAC_PATIENTS,
      variables: {
        _id: auth.getUser()?.id
      }
    }
    ]
  });

  const followSuccess = (name: string) => {
    message.success(`Your connection request have been sent to ${name}`);
  };

  const onSearch = (value: string) => {
    console.log("This is the value that was searched", value);
  }

  const onChange = (event: any) => {
    console.log("The value on Change: ", event.target.value);
    getUsersByEmail({
      variables: {
        email: event.target.value,
      }
    })
  }

  const followUserAction = (follower_id: string, followee_id: string, name: string) => {
    if (!follower_id || !followee_id || !name) {
      return
    }
    setName(name)

    followUser({
      variables: {
        follower_id,
        followee_id
      }
    })

  }

  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 200,
      }} >
      <h1>Connect with User</h1>
      <Row
        justify="center"
        style={{
          marginTop: 70,

        }}>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={14}
          xl={12}
          style={{
            padding: 50,
            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",

          }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1, paddingBottom: 30 }} >
            <h1 style={{ textAlign: "center" }}>Enter user email below</h1>
            {/* <p style={{ maxWidth: 300, textAlign: "center" }}>The user will be sent an email to enter a password. Any user added through this form will automatically be added to your followees list.</p> */}
            <Search placeholder="Input email to search" onSearch={onSearch} onChange={debounce(onChange, 500)} loading={loading} enterButton />

          </div>
          {/* <h3>Users found</h3> */}
          {data?.userByEmail?.users ?
            <List
              itemLayout="horizontal"
              dataSource={data.userByEmail.users}
              renderItem={(user: any) => (
                <List.Item
                  actions={[
                    currentFollowees?.includes(user.id)
                      ?
                      <Button key="connect" onClick={() => message.success(`Already following ${user.firstName}`)} type="dashed">Connected</Button>
                      :
                      <Button key="connect" onClick={() => followUserAction(auth.getUser()?.id, user.id, user.firstName)} type="primary">Connect</Button>
                  ]}
                >
                  <List.Item.Meta
                    avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                    title={<a href="#">{`${user.firstName} ${user.lastName}`}</a>}
                    description={`${user.email}`}
                  />
                </List.Item>
              )}
              pagination={{
                // onChange: page => {
                //   console.log(page);
                // },
                pageSize: 10,
              }}
            />
            :
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </Col>
      </Row>
    </Content>
  )
}
