import { Col, Row, Collapse, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory'
import { HorisontalBarComponent } from './HorisontalBarComponent';
import { CaretRightOutlined } from '@ant-design/icons';
import { formulateModal } from '../utils/Descriptions';
import { FiveElementsDiagram } from './FiveElementsDiagram';
import { MeridiansDiagram } from './MeridiansDiagram';
import { ChakraDiagram } from './ChakraDiagram';
import axios from 'axios';
import { csv } from 'd3';
import { HorisontalBarHeadingSingle } from './HorisontalBarHeadingSingle';
import { energyDepHorisontal, hormonesMineralsHorisontal, inflamIndexHorisontal, neuroBalance, polyvagalHorisontal, psActivityHorisontal, stressIndexHorisontal, TfiHorisontal, totalPowerHorisontal } from '../utils/gradients';
import { HorisontalBarHeadingDouble } from './HorisontalBarHeadingDouble';
import { ValueCard } from './ValueCard';
import { FrequencyDomainPie } from './FrequencyDomainPie';
import { RhythmogramDisplay } from './RhythmogramDisplay';
import { HistogramDisplay } from './HistogramDisplay';
import { GraphLoadingBox } from './GraphLoadingBox';
import { ScattergramDisplay } from './ScattergramDisplay';
import { VataBarDisplay } from './VataBarDisplay';
import { PittaBarDisplay } from './PittaBarDisplay';
import { KaphaBarDisplay } from './KaphaBarDisplay';
import { TissuesBarDisplay } from './TissuesBarDisplay';
import { calculatePolyVagal } from './HRVDetailsSection';



const { Panel } = Collapse;
interface DoshaModalData {
  dosha: string
  name: string
  shortName: string
  value: number
  status: string
  relationship: string
  affectedAreas: string
  details: string
}

interface ScatterData {
  x: number
  y: number
}


export const CompareContainer = ({ heartData }: any) => {

  const [modal, modalContextHolder] = Modal.useModal();

  const [rythmData, setRythmData] = useState<any[]>()
  const [rythmLoading, setRythmLoading] = useState(true)
  const [scatterData, setScatterData] = useState<ScatterData[]>()
  const source = axios.CancelToken.source()

  useEffect(() => {
    if (!heartData) return
    getRythmogram(heartData.recording_location)
    console.log(heartData.recording_location);

    return () => {
      source.cancel('Cancelled rr file request')
    }
  }, [heartData])
  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

  async function getRythmogram(location: any) {
    setRythmLoading(true)

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/v1/files/rr/${heartData?.rr_file.id}`,
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      // data: location,
      cancelToken: source.token
    })
      .then(urldata => {
        // console.log("URL DATA", urldata.data)
        // const rythmFetched = csv(urldata.data)

        // if (true) {
        //   return csv(urldata.data.replace("localstack", "localhost"), initReq)
        // }
        return csv(urldata.data)

      })

      .then(res => {
        // console.log(res)
        const rdata = res.map((values: any, index: any) => {
          return { x: Number(values.x), y: Number(values.y) }
        })
        // console.log(rdata)
        const x1 = rdata.map((d: any) => d.y)
        x1.pop()
        const x2 = rdata.map((d: any) => d.y)
        x2.shift()

        const scatData = x1.map((d: any, i: any) => {
          return { x: d, y: x2[i] }
        })
        setScatterData(scatData)
        setRythmData(rdata)
        setRythmLoading(false)
      })
      .catch(error => {
        console.log(error);

        console.log(error.message)
        setRythmLoading(false)
      })
  }


  return (
    <div>
      <Row>
        <Col span={10}>
          <FrequencyDomainPie recording={heartData} />
        </Col>
        <Col span={14}>
          <div style={{ flex: 5, display: "flex", flexDirection: "column", flexBasis: 300 }}>
            <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Total Power", "total_power")}>
              <HorisontalBarHeadingSingle
                title="Total Power"
                subHeading="(1500-3500)"
                value={heartData?.data?.frequencyDomain?.total_power.toFixed(2)}
              />
              <HorisontalBarComponent
                value={heartData?.data?.frequencyDomain?.total_power}
                domain={[100, 6000]}
                goodRange={[1500, 3500]}
                markers={[{ name: "1500", value: 1500 }, { name: "3500", value: 3500 },]}
                decimal={0}
                backgroundColor={totalPowerHorisontal}
                leftText="Low"
                rightText="High"
                valueMarker={true}
              />
            </div>
            <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Stress Index", "stressIndex")}>
              <HorisontalBarHeadingSingle
                title="Stress Index"
                subHeading="(10-100)"
                onClick={() => formulateModal(modal, "Stress Index", "stressIndex")}
                value={heartData?.data?.stressIndex.toFixed(2)}
              />
              <HorisontalBarComponent
                value={heartData?.data?.stressIndex}
                domain={[10, 500]}
                goodRange={[0, 100]}
                markers={[{ name: "100", value: 100 },]}
                backgroundColor={stressIndexHorisontal}
                leftText="Low"
                rightText="High"
                valueMarker={true}
              />
            </div>
            {/* <div style={{ cursor: "pointer", paddingTop: 10 }} onClick={() => formulateModal(modal, "Dorsal Vagus", "dorsalVagus")}>
            <HorisontalBarHeadingDouble
              titleLeft="Ventral Vagus"
              // subHeadingLeft="(10-500)" 
              titleRight="Dorsal Vagus"
            // subHeadingRight=""
            />
            <HorisontalBarComponent
              value={heartData?.data?.dorsalVagus}
              domain={[0, 3]}
              valueMarker={true}
              backgroundColor={hormonesMineralsHorisontal}
            />
          </div> */}


            <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Nervous System Balance Index", "cns_ans")}>
              <HorisontalBarHeadingSingle
                title="Nervous System Balance Index"
                subHeading="(1-3)"
                value={heartData?.data?.cns_ans.toFixed()}
              />
              <HorisontalBarComponent
                value={heartData?.data?.cns_ans}
                domain={[0.1, 5]}
                goodRange={[1, 3]}
                valueMarker={true}
                backgroundColor={hormonesMineralsHorisontal}
                leftText="CNS"
                rightText="ANS"
              />
            </div>
            <div style={{
              backgroundColor: "#F3F3F340",
              padding: 20,
              borderRadius: 10,
              marginTop: 10,
              border: "3px solid #99999940",
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              cursor: "pointer"
            }}
              onClick={() => formulateModal(modal, "Poly Vagal", "dorsalVagus")}
            >
              <div style={{
                fontSize: 24,
                textAlign: 'center',
                marginBottom: 20
              }}>Poly Vagal</div>
              <div style={{ cursor: "pointer", }}
              >
                <HorisontalBarHeadingSingle
                  title="Parasympathetic Activity"
                  subHeading="(0 - 70)"
                  value={`${(heartData?.data?.frequencyDomain?.hf / heartData?.data?.frequencyDomain?.total_power * 100).toFixed()}%`}
                />
                <HorisontalBarComponent
                  value={(heartData?.data?.frequencyDomain?.hf / heartData?.data?.frequencyDomain?.total_power * 10)}
                  domain={[0, 7.0]}
                  backgroundColor={psActivityHorisontal}
                  valueMarker={true}
                  markers={[{ name: "15%", value: 1.5 }, { name: "30%", value: 3.0 }, { name: "70%", value: 6.7 }]}
                />
              </div>
              <div style={{ cursor: "pointer", }}
              >
                <HorisontalBarHeadingSingle
                  title="Energy Index"
                  subHeading="(100-5000)"
                  value={`${(heartData?.data?.frequencyDomain?.total_power / 1000).toFixed(1)}K`}
                />
                <HorisontalBarComponent
                  value={(heartData?.data?.frequencyDomain?.total_power)}
                  domain={[5000, 100]}
                  backgroundColor={energyDepHorisontal}
                  valueMarker={true}
                  markers={[{ name: "5K", value: 5000 }, { name: "3.5K", value: 3500 }, { name: "1.5K", value: 1500 }, { name: "1K", value: 1000 }, { name: "0.5", value: 500 }, { name: ".1", value: 100 }]}
                />
              </div>
              <div style={{ cursor: "pointer", }}
              >
                <HorisontalBarHeadingSingle
                  title="Poly-Vagal"
                  subHeading="(0.25-3.0)"
                  value={calculatePolyVagal(heartData).toFixed(2)}
                />
                <HorisontalBarComponent
                  value={calculatePolyVagal(heartData)}
                  domain={[0.25, 3.0]}
                  backgroundColor={polyvagalHorisontal}
                  valueMarker={true}
                  markers={[{ name: "Ventral nucleous", value: 0.3 }, { name: "Balanced", value: 1.2 }, { name: "Dorsal Nucleous", value: 2.65 }]}
                  additionalMarkers={[{ name: "0.25", value: 0.27 }, { name: "0.5", value: 0.5 }, { name: "0.8", value: 0.8 }, { name: "1.0", value: 1 }, { name: "1.2", value: 1.15 }, { name: "3.0", value: 2.9 }]}
                />
              </div>
            </div>
          </div>

        </Col>
      </Row>
      <Collapse
        style={{ marginTop: 50 }}
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        className="site-collapse-custom-collapse"
      >
        <Panel header="Cardiovascular Data" key="1" className="site-collapse-custom-panel">
          <div style={{ display: "flex", borderRadius: 10, width: "100%", gap: 20 }}>
            <ValueCard title="HRV Index" subTitle="(6-25)" range={[6, 25]} value={heartData?.data?.hrvIndex} onClick={() => formulateModal(modal, "HRV Index", "hrvIndex")} />
            <ValueCard title="Heart Rate" subTitle="(60-84)" range={[59, 84]} value={heartData?.data?.bpm} onClick={() => formulateModal(modal, "Heart Rate", "bpm")} decimal={0} />
            <ValueCard title="Stress Index" subTitle="(10-100)" range={[10, 100]} value={heartData?.data?.stressIndex} onClick={() => formulateModal(modal, "Stress Index", "stressIndex")} decimal={0} />
            <ValueCard title="aMo%" subTitle="(30-50)" range={[30, 50]} value={heartData?.data?.amo * 100} onClick={() => formulateModal(modal, "Amo %", "amo")} decimal={1} />
          </div>
          <div
            style={{ cursor: "pointer", fontSize: 24, color: "#444", textAlign: 'center', marginBottom: 20, marginTop: 20 }}
            onClick={() => formulateModal(modal, "Rhythmogram", "rhythmogram")}
          >
            Rhythmogram
          </div>
          <div onClick={() => formulateModal(modal, "Rhythmogram", "rhythmogram")}>
            {rythmData && !rythmLoading ? <RhythmogramDisplay rythmData={rythmData} /> : <GraphLoadingBox height={150} />}
          </div>
          <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Histogram", "histogram")}>
            <div style={{ cursor: "pointer", fontSize: 24, color: "#444", textAlign: 'center', marginTop: 20 }}>
              Histogram = {(heartData?.data?.amo * 100).toFixed(2)}%
            </div>
            {rythmData && !rythmLoading ? <HistogramDisplay rythmData={rythmData} /> : <GraphLoadingBox height={300} />}
          </div>
          <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Scattergram", "scattergram")}>
            <div
              style={{ cursor: "pointer", fontSize: 24, color: "#444", textAlign: 'center', marginTop: 20 }}
            >
              Scattergram
            </div>
            {rythmData && !rythmLoading ? <ScattergramDisplay scatterData={scatterData} /> : <GraphLoadingBox height={300} />}
          </div>
        </Panel>
        <Panel header="Neurohormonal Data" key="2" className="site-collapse-custom-panel">
          <Collapse
            bordered={false}
            // defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
          >
            <Panel header="Minerals" key="1" className="site-collapse-custom-panel">
              <div style={{ flex: 1, minWidth: 360, }}>
                <HorisontalBarHeadingSingle title="K (Potassium)" subHeading="(4-6)" value={heartData?.data?.minerals.k.toFixed(2)} />
                <HorisontalBarComponent value={heartData?.data?.minerals.k} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Potassium", "k")} backgroundColor={neuroBalance} leftText="Low"
                  rightText="High" valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Na (Sodium)" subHeading="(4-6)" value={heartData?.data?.minerals.na.toFixed(2)} />
                <HorisontalBarComponent value={heartData?.data?.minerals.na} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Sodium", "na")} backgroundColor={neuroBalance} leftText="Low"
                  rightText="High" valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Mg (Magnesium)" subHeading="(4-6)" value={heartData?.data?.minerals.mg.toFixed(2)} />
                <HorisontalBarComponent value={heartData?.data?.minerals.mg} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Magnesium", "mg")} backgroundColor={neuroBalance} leftText="Low"
                  rightText="High" valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Ca (Calcium)" subHeading="(4-6)" value={heartData?.data?.minerals.ca.toFixed(2)} />
                <HorisontalBarComponent value={heartData?.data?.minerals.ca} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Calcium", "ca")} backgroundColor={neuroBalance} leftText="Low"
                  rightText="High" valueMarker={true} barHeight={15} />
              </div>
            </Panel>
            <Panel header="Hormones" key="2" className="site-collapse-custom-panel">
              <div style={{ flex: 1, minWidth: 360, }}>
                <HorisontalBarHeadingSingle
                  title="Dopa"
                  subHeading="(2-4)"
                  value={heartData?.data?.bnt?.dopa.toFixed(2)}
                />
                <HorisontalBarComponent
                  value={heartData?.data?.bnt?.dopa}
                  domain={[0, 7.5]}
                  goodRange={[2, 4]}
                  onClick={() => formulateModal(modal, "Dopa", "dopa")}
                  backgroundColor={neuroBalance}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Ct E" subHeading="(2-4)" value={heartData?.data?.bnt?.ct_e.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.bnt?.ct_e}
                  domain={[0, 7.5]}
                  goodRange={[2, 4]}
                  onClick={() => formulateModal(modal, "Epinephrine", "ct_e")}
                  backgroundColor={neuroBalance}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Ct Ne" subHeading="(2-4)" value={heartData?.data?.bnt?.ct_ne.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.bnt?.ct_ne}
                  domain={[0, 7.5]}
                  goodRange={[2, 4]}
                  onClick={() => formulateModal(modal, "Norepinephrine", "ct_ne")}
                  backgroundColor={neuroBalance}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Neuro Balance" subHeading="(2-4)" value={heartData?.data?.bnt?.dopa.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.bnt?.dopa}
                  domain={[0, 7.5]}
                  goodRange={[2, 4]}
                  // onClick={() => formulateModal(modal, "Potassium", "k")} 
                  backgroundColor={neuroBalance}
                  leftText="Inhibitory"
                  rightText="Excitatory"
                  valueMarker={true} barHeight={15}
                />
                <HorisontalBarHeadingSingle
                  title="Ach"
                  subHeading="(2-4)"
                  value={heartData?.data?.bnt?.ach.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.bnt?.ach}
                  domain={[0, 7.5]}
                  goodRange={[2, 4]}
                  onClick={() => formulateModal(modal, "Acetylcholine", "ach")}
                  backgroundColor={neuroBalance}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Gaba" subHeading="(2-4)" value={heartData?.data?.bnt?.gaba.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.bnt?.gaba}
                  domain={[0, 7.5]}
                  goodRange={[2, 4]}
                  onClick={() => formulateModal(modal, "Gaba", "gaba")}
                  backgroundColor={neuroBalance}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Sert" subHeading="(2-4)" value={heartData?.data?.bnt?.sert.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.bnt?.sert}
                  domain={[0, 7.5]}
                  goodRange={[2, 4]}
                  onClick={() => formulateModal(modal, "Serotonin", "sert")}
                  backgroundColor={neuroBalance}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
              </div>
            </Panel>
            <Panel header="Neurotransmitters" key="3" className="site-collapse-custom-panel">
              <div style={{ flex: 1, minWidth: 360, }}>
                <HorisontalBarHeadingSingle title="Inflam Index" subHeading="(75-300)" value={heartData?.data?.inflamIndex.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.inflamIndex}
                  domain={[50, 1200]}
                  goodRange={[75, 300]}
                  onClick={() => formulateModal(modal, "Inflam Index", "inflamIndex")}
                  backgroundColor={inflamIndexHorisontal}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15}
                  decimal={1} />
                <HorisontalBarHeadingSingle title="Cortisol" subHeading="(4-6)" value={heartData?.data?.hormones?.cortisol.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.hormones?.cortisol}
                  domain={[0, 10]}
                  goodRange={[4, 6]}
                  onClick={() => formulateModal(modal, "Cortisol", "cortisol")}
                  backgroundColor={hormonesMineralsHorisontal}
                  leftText="Low"
                  rightText="High" valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="DHEA" subHeading="(4-6)" value={heartData?.data?.hormones?.dhea.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.hormones?.dhea}
                  domain={[0, 10]}
                  goodRange={[4, 6]}
                  onClick={() => formulateModal(modal, "DHEA", "dhea")}
                  backgroundColor={hormonesMineralsHorisontal}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Estradiol" subHeading="(4-6)" value={heartData?.data?.hormones?.estradiol.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.hormones?.estradiol}
                  domain={[0, 10]}
                  goodRange={[4, 6]}
                  onClick={() => formulateModal(modal, "Estradiol", "estradiol")}
                  backgroundColor={hormonesMineralsHorisontal}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="Pregnenolone" subHeading="(4-6)" value={heartData?.data?.hormones?.pregnenolone.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.hormones?.pregnenolone}
                  domain={[0, 10]}
                  goodRange={[4, 6]}
                  onClick={() => formulateModal(modal, "Pregnenolone", "pregnenolone")}
                  backgroundColor={hormonesMineralsHorisontal}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="T4 - T3" subHeading="(4-6)" value={heartData?.data?.hormones?.t3_t4.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.hormones?.t3_t4}
                  domain={[0, 10]}
                  goodRange={[4, 6]}
                  onClick={() => formulateModal(modal, "t3_t4", "T4 T3")}
                  backgroundColor={hormonesMineralsHorisontal}
                  leftText="Low"
                  rightText="High"
                  valueMarker={true} barHeight={15} />
                <HorisontalBarHeadingSingle title="TFi" subHeading="(-0.07 - 0)" value={heartData?.data?.tfi.toFixed(2)} />
                <HorisontalBarComponent
                  value={heartData?.data?.tfi}
                  domain={[-0.2, 0.15]}
                  goodRange={[-0.07, 0]}
                  onClick={() => formulateModal(modal, "Thyroid Function Index", "tfi")}
                  backgroundColor={TfiHorisontal}
                  leftText="HYPO"
                  rightText="HYPER"
                  valueMarker={true} barHeight={15} />
              </div>
            </Panel>
          </Collapse>

        </Panel>
        <Panel header="Eastern Data" key="3" className="site-collapse-custom-panel">
          <Collapse
            bordered={false}
            // defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            className="site-collapse-custom-collapse"
          >
            <Panel header="Doshas" key="1" className="site-collapse-custom-panel">
              <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
                <div style={{ flex: 1, minWidth: 360, maxWidth: 450 }}>
                  <h1 style={{ textAlign: "center" }}>Vata</h1>
                  <VataBarDisplay recording={heartData} />
                </div>
                <div style={{ flex: 1, minWidth: 360, maxWidth: 450 }}>
                  <h1 style={{ textAlign: "center" }}>Pitta</h1>
                  <PittaBarDisplay recording={heartData} />
                </div>
                <div style={{ flex: 1, minWidth: 360, maxWidth: 450 }}>
                  <h1 style={{ textAlign: "center" }}>Kapha</h1>
                  <KaphaBarDisplay recording={heartData} />
                </div>
              </div>

            </Panel>
            <Panel header="Five Elements" key="2" className="site-collapse-custom-panel">
              <div style={{ flex: 1, display: "flex", flexDirection: "column", justifyItems: "center" }}>
                {heartData?.data ?
                  <FiveElementsDiagram meridians={heartData?.data?.meridians} />
                  : null}

              </div>

            </Panel>
            <Panel header="Meridians" key="3" className="site-collapse-custom-panel">
              <div style={{ flex: 1, width: 360, display: "flex", flexDirection: "column", justifyItems: "center" }}>
                {heartData?.data ?
                  <MeridiansDiagram meridians={heartData?.data?.meridians} />
                  : null}
              </div>

            </Panel>
            <Panel header="Tissues (Dhatu)" key="4" className="site-collapse-custom-panel">
              <div style={{ flex: 1, minWidth: 360 }}>
                <h1 style={{ textAlign: "center" }}>Tissues (Dhatu)</h1>
                <TissuesBarDisplay recording={heartData} />
              </div>

            </Panel>
            <Panel header="Energy Plexus" key="5" className="site-collapse-custom-panel">
              <div style={{ flex: 1, width: 520, display: "flex", flexDirection: "column", justifyItems: "center" }}>
                {heartData?.data ?
                  <ChakraDiagram data={heartData?.data} />
                  : null}
              </div>

            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
      {modalContextHolder}
    </div>

  )
}
