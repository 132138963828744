import { max } from 'd3'
import React, { useState } from 'react'
import { Area, AreaChart, ResponsiveContainer, YAxis } from 'recharts'
import { blue4, green5, red5, yellow5 } from '../../utils/colors'
import ReactSpeedometer from "react-d3-speedometer"
import { NeurotransmitterCombination } from '../CombinedDisplays/NeurotransmitterCombination'
import { NeuroHCardDisplay } from '../NeuroHCardDisplay'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { formulateModal } from '../../utils/Descriptions'
import { Button, Modal, Popover, Space } from 'antd'
import { ICPCardDisplay } from '../ICPCardDisplay'
import brain_20 from "../../brain_images/brain_20.png"
import brain_30 from "../../brain_images/brain_30.png"
import brain_50 from "../../brain_images/brain_50.png"
import brain_60 from "../../brain_images/brain_60.png"
import brain_80 from "../../brain_images/brain_80.png"
import brain_100 from "../../brain_images/brain_100.png"
import { useAuth } from '../../App'
import { BrainGaugeModal } from './BrainGaugeModal'
import { useQuery } from '@apollo/client'
import { GET_BRAIN_DESCRIPTION } from '../../graphql/queries'
import _ from 'lodash'
import { BrainGaugeModalRawScores } from './BrainGaugeModalRawScores'

// const emails = ["test@mail.com", "drmkessler@sbcglobal.net"]

interface BrainContainerProps {
  recording: any
}

const maxCalc = (arr: GraphData[]): number => {
  return max(arr, (d) => d.y) || 0
}

const BrainContainer = ({ recording }: BrainContainerProps) => {
  let auth = useAuth();
  let user = auth.getUser()

  const { data: brainDescription, loading: brainLoading, error: brainError } = useQuery(GET_BRAIN_DESCRIPTION)

  const delta: GraphData[] = recording.brain_spectrum.delta
  const theta: GraphData[] = recording.brain_spectrum.theta
  let alphaReversed = [...recording.brain_spectrum.alpha].reverse()
  const alpha: GraphData[] = alphaReversed
  let betaReversed = [...recording.brain_spectrum.beta].reverse()
  const beta: GraphData[] = betaReversed
  let hbetaReversed = [...recording.brain_spectrum.hbeta].reverse()
  const hbeta: GraphData[] = hbetaReversed
  const graphHeight = 200
  const maxYs = [maxCalc(delta), maxCalc(theta), maxCalc(alpha), maxCalc(beta), maxCalc(hbeta)]
  const yDomain = [0, max(maxYs) || 200]
  const total_power = recording?.data?.brain?.total_power

  const [modal, modalContextHolder] = Modal.useModal();

  const image_array = [brain_20, brain_30, brain_50, brain_60, brain_80, brain_100]
  const brain_key_array: string[] = ["brain_20", "brain_30", "brain_50", "brain_60", "brain_80", "brain_100"]
  const brain_value_array = ["0-20%", "21-39%", "40-59%", "60-79%", "80-100%", "100+"]

  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "row",
        height: graphHeight,
        width: '100%'
      }}>

        <div style={{ flex: 1, cursor: "pointer", }}
          onClick={() => formulateModal(modal, "Delta", "delta")}>
          <BrainHeading heading={"Delta"} power={`${(recording?.data?.brain?.delta / total_power * 100).toFixed()}% - ${recording?.data?.brain?.delta.toFixed()}`} />
          <BrainGraph id="delta" graphData={delta} graphHeight={graphHeight} yDomain={yDomain} color={red5} />
        </div>
        <div style={{ flex: 1, cursor: "pointer" }}
          onClick={() => formulateModal(modal, "Theta", "theta")}>
          <BrainHeading heading={"Theta"} power={`${(recording?.data?.brain?.theta / total_power * 100).toFixed()}% - ${recording?.data?.brain?.theta.toFixed()}`} />
          <BrainGraph id="theta" graphData={theta} graphHeight={graphHeight} yDomain={yDomain} color={yellow5} />
        </div>
        <div style={{ flex: 1, cursor: "pointer" }}
          onClick={() => formulateModal(modal, "Alpha", "alpha")}>
          <BrainHeading heading={"Alpha"} power={`${(recording?.data?.brain?.alpha / total_power * 100).toFixed()}% - ${recording?.data?.brain?.alpha.toFixed()}`} />
          <BrainGraph id="alpha" graphData={alpha} graphHeight={graphHeight} yDomain={yDomain} color={green5} />
        </div>
        <div style={{ flex: 1, cursor: "pointer" }}
          onClick={() => formulateModal(modal, "Beta", "beta")}>
          <BrainHeading heading={"Beta"} power={`${(recording?.data?.brain?.beta / total_power * 100).toFixed()}% - ${recording?.data?.brain?.beta.toFixed()}`} />
          <BrainGraph id="beta" graphData={beta} graphHeight={graphHeight} yDomain={yDomain} color={blue4} />
        </div>
        <div style={{ flex: 1, cursor: "pointer" }}
          onClick={() => formulateModal(modal, "High Beta", "hbeta")}>
          <BrainHeading heading={"High Beta"} power={`${(recording?.data?.brain?.hbeta / total_power * 100).toFixed()}% - ${recording?.data?.brain?.hbeta?.toFixed()}`} />
          <BrainGraph id="hbeta" graphData={hbeta} graphHeight={graphHeight} yDomain={yDomain} color={"#8884d8"} />
        </div>
      </div>
      <div style={{
        height: 50
      }}></div>
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
      }}>

        <div style={{
          flex: "60%"
        }}>
          <NeurotransmitterCombination recording={recording} />
        </div>
        <div style={{
          flex: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        >
          {/* {emails.includes(user.email) && */}
          <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 20,
            marginBottom: 20,
            alignItems: "center",
            flex: 1,
            width: '100%',
            position: 'relative'
          }}>
            {/* <Button icon={<LeftOutlined />} onClick={prevImage}></Button> */}

            <div style={{
              position: 'absolute',
              top: 10,
              right: 0,
              display: "flex",
              flexDirection: "column",
              textAlign: 'center',
            }}>
              <h3>
                BRAIN GAUGE
              </h3>
              <Space direction='vertical'>
                <BrainGaugeModal />
                <BrainGaugeModalRawScores />
              </Space>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', marginTop: 70 }}>
              <Popover content={() => <>
                <div style={{}}>
                  The brain color that you see here is the brain state showing up for that patient.
                  It is based on the Brain Power Spectrum.<br /> Look at the number representing this parameter and click on the small brain picture that matches this<br /> range for an explanation.
                </div>
              </>} trigger="hover">
                <img src={GetBrainImage(recording?.data?.brain?.brain_power)} style={{ width: 200, filter: "drop-shadow(0px 0px 7px #999999)" }} alt="brain_image" />
              </Popover>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1, maxWidth: 350, marginTop: 20, gap: 10 }}>
                {
                  brainDescription && !brainLoading && image_array.map((img, i) => {
                    const brain_desc = _.find(brainDescription.descriptions, (d) => { return d.key === brain_key_array[i] })

                    return (
                      <div key={i} style={{ minWidth: 50 }}>
                        <Popover content={() => <>
                          <div style={{ textAlign: 'left' }}>
                            {brain_value_array[i]}
                          </div><div dangerouslySetInnerHTML={{ __html: brain_desc.hq_professional }}></div>
                        </>} trigger="click">
                          <img src={img} style={{ width: 50, filter: "drop-shadow(0px 0px 7px #999999)" }} alt="brain_image" />
                          <div style={{ textAlign: 'center' }}>
                            {brain_value_array[i]}
                          </div>
                        </Popover>
                      </div>
                    )
                  })}
              </div>
            </div>
            {/* <Button icon={<RightOutlined />} onClick={nextImage}></Button> */}
          </div>
          {/* } */}
          <div style={{
            cursor: "pointer",
            marginTop: 30
          }} onClick={() => formulateModal(modal, "Power Spectrum of the Brain", "brainspectrum")}>
            <ReactSpeedometer
              height={160}
              value={recording?.data?.brain?.brain_power > 120 ? 120 : recording?.data?.brain?.brain_power ? recording?.data?.brain?.brain_power : 0}
              minValue={0}
              maxValue={120}
              // segments={4}
              currentValueText=""
              customSegmentStops={[0, 50, 80, 100, 120]}
              segmentColors={[red5, "orange", yellow5, green5]}
              customSegmentLabels={[{
                text: "Very Low",
                fontSize: "12px",
                color: "white"
              }, {
                text: "Decreased",
                fontSize: "12px",
                color: "white"
              }, {
                text: "Moderate",
                fontSize: "12px",
                color: "white"
              }, {
                text: "Optimal",
                fontSize: "12px",
                color: "white"
              }]}
            />
          </div>
          <div style={{ width: "100%", textAlign: "center", cursor: "pointer" }} onClick={() => formulateModal(modal, "Power Spectrum of the Brain", "brainspectrum")}>
            <div style={{ marginTop: -18, fontSize: 34 }}>{recording?.data?.brain?.brain_power.toFixed()}</div>
            <h2 style={{ marginTop: -15 }}>Brain Power Spectrum</h2>
          </div>
          <div style={{
            width: 200,
            height: 120,
            marginTop: 70
          }}>
            <ICPCardDisplay title="ICP"
              subTitle="(WNL-HIGH)"
              value={recording?.data?.icp}
              onClick={() => formulateModal(modal, "Intracranial Pressuer", "icp")} />
          </div>

        </div>
      </div>
      {modalContextHolder}

      {/* <pre>{JSON.stringify(recording, null, 2)}</pre> */}
    </>
  )
}

export default React.memo(BrainContainer)

interface BrainGraphProps {
  id: string
  graphData: GraphData[],
  color: string
  graphHeight: number
  yDomain: number[]
}

interface GraphData {
  x: number
  y: number
}

const BrainGraph = ({ id, graphData, graphHeight, yDomain, color }: BrainGraphProps) => {

  return (
    <ResponsiveContainer width="100%" height={graphHeight - 45} >
      <AreaChart data={graphData}
        margin={{ top: 5, bottom: 5 }}>
        <defs>
          <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0.2} />
          </linearGradient>
        </defs>
        <YAxis type="number" domain={yDomain} hide />
        <Area type="monotone" dataKey="y" stroke={color} fillOpacity={1} fill={`url(#${id})`} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

interface BrainHeadingProps {
  heading: String
  power: number | string
}

const BrainHeading = ({ heading, power }: BrainHeadingProps) => {
  return (
    <div style={{ flex: 1, }}>
      <div style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        justifyContent: "center",
        height: 50
      }}>
        <div style={{
          textAlign: "center"
        }}>{heading.toUpperCase()}</div>
        <div style={{
          textAlign: "center"
        }}>{power}</div>
      </div>
    </div>
  )
}

const GetBrainImage = (value: number) => {
  switch (true) {
    case (value < 20):
      return brain_20
    case (value >= 20 && value < 39):
      return brain_30
    case (value >= 40 && value < 59):
      return brain_50
    case (value >= 60 && value < 79):
      return brain_60
    case (value >= 80 && value < 99):
      return brain_80
    case (value >= 100):
      return brain_100
    default:
      break;
  }
}