import { useMutation, useQuery } from '@apollo/client'
import { Alert, Button, Divider, message } from 'antd'
import { GET_NOTES_FOR_RECORDING } from '../../graphql/queries'
import {
  DeleteOutlined,
  FormOutlined,
} from '@ant-design/icons';


import { getFormatedDate, getFormatedTime } from '../../utils/helperFunctions';
import { serializeToHTML, serializeToPlainText, SlateNoteEditor } from '../Editor/SlateNoteEditor';
import { ReactElement, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DELETE_NOTE_BY_PK, INSERT_NOTE_FOR_RECORDING, UPDATE_NOTE_BY_PK } from '../../graphql/mutations';
import { useAuth } from '../../App';
import { red5 } from '../../utils/colors';
import { Descendant } from 'slate';


interface NotesDisplayProps {
  heart_data_id: string
}

interface EditingNoteData {
  id: number
  value: Descendant[]
}


export const NotesDisplay = ({ heart_data_id }: NotesDisplayProps) => {
  let auth = useAuth();
  let user = auth.getUser()

  const [editorValue, setEditorValue] = useState([])
  const [editorHidden, setEditorHidden] = useState<boolean>(true)
  const [isDeleting, setIsDeleting] = useState(true)
  const [itemDeleteIndex, setItemDeleteIndex] = useState<null | number>(null)
  const [editingNote, setEditingNote] = useState<EditingNoteData | null>(null)


  const { data, loading, error } = useQuery(GET_NOTES_FOR_RECORDING, {
    variables: {
      heart_data_id
    }
  })

  const [insertNote, { loading: insertNoteLoading, error: insertNoteError }] = useMutation(INSERT_NOTE_FOR_RECORDING, {
    onCompleted: () => {
      success()
      setEditorHidden(true)
    },
    refetchQueries: [{
      query: GET_NOTES_FOR_RECORDING,
      variables: {
        heart_data_id
      }
    }]
  })

  const [deleteNote, { loading: deleteLoading }] = useMutation(DELETE_NOTE_BY_PK, {
    onCompleted: () => {
      successDelete()
    },
    onError: () => {
      errorDelete()
    },
    refetchQueries: [{
      query: GET_NOTES_FOR_RECORDING,
      variables: {
        heart_data_id
      }
    }]
  })

  const [updateNote, { loading: updateNoteLoading }] = useMutation(UPDATE_NOTE_BY_PK, {
    onCompleted: () => {
      successUpdate()
      setEditingNote(null)
    },
    onError: () => {
      errorUpdate()
    },
    refetchQueries: [{
      query: GET_NOTES_FOR_RECORDING,
      variables: {
        heart_data_id
      }
    }]
  })

  const success = () => {
    message.success('Successfully saved note', 5);
  };
  const successUpdate = () => {
    message.success('Successfully updated note', 5);
  };
  const errorUpdate = () => {
    message.error('Could not updated note', 5);
  };
  const errorDelete = () => {
    message.error('Could not delete note', 5);
  };
  const successDelete = () => {
    message.success('Successfully deleted note', 5);
  };


  if (error) {
    return <div>Could not get notes</div>
  }

  const insertNoteOnSave = () => {
    if (!editorValue) return
    insertNote({
      variables: {
        heart_data_id,
        author_id: user.id,
        note: editorValue,
        text: serializeToPlainText(editorValue)
      }
    })
  }


  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", gap: 20, marginBottom: 180 }}>
        {data && !loading && data.note.map((n: any, i: number) => {
          return <div key={i} style={{
            padding: 10,
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
            borderRadius: 7,
          }}>
            {editingNote && editingNote.id === n.id ? <div key={n.id}>
              <SlateNoteEditor updateValue={(data: Descendant[]) => setEditingNote({ id: n.id, value: [...data] })} insertValue={n.note} />
              <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: 5,
                marginTop: 5
              }}>
                <Button onClick={() => {
                  setEditingNote(null)
                }} >Cancel</Button>
                <Button type="primary"
                  loading={updateNoteLoading}
                  onClick={() => {
                    updateNote({
                      variables: {
                        id: n.id,
                        note: editingNote.value,
                        text: serializeToPlainText(editingNote.value)
                      }
                    })
                  }} >Save</Button>
              </div>
            </div>
              : <div key={n.id}>
                <div>
                  {n.note.map((nd: any) => {
                    return serializeToHTML(nd)
                  })}
                </div>
                <Divider style={{ margin: "10px 0" }} />
                <div style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}>
                  <div>{getFormatedDate(n?.created_at)} - {getFormatedTime(n?.created_at)}</div>
                  <div style={{
                    display: "flex",
                    gap: 10
                  }}>{
                      isDeleting && itemDeleteIndex === n.id ?
                        <div>
                          <p style={{ color: red5 }}>This will permenatly delete the note, are your sure you want to delete</p>
                          <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            gap: 5
                          }}>
                            <Button onClick={() => {
                              setIsDeleting(false)
                              setItemDeleteIndex(null)
                            }} >Cancel</Button>
                            <Button
                              loading={deleteLoading}
                              onClick={() => {
                                deleteNote({
                                  variables: {
                                    id: n.id
                                  }
                                })
                              }} danger >delete</Button>
                          </div>
                        </div>
                        :
                        <>
                          <Button onClick={() => {
                            setEditingNote({
                              id: n.id,
                              value: []
                            })
                          }} icon={<FormOutlined />} />
                          <Button onClick={() => {
                            setIsDeleting(true)
                            setItemDeleteIndex(n.id)
                          }} icon={<DeleteOutlined />} danger />
                        </>
                    }
                  </div>
                </div></div>}
          </div>
        })}
      </div>
      <div style={{
        backgroundColor: "white",
        position: "absolute",
        padding: 10,
        left: 0,
        bottom: 0,
        width: "100%",
      }}>
        <Button onClick={() => setEditorHidden(!editorHidden)}>{editorHidden ? "Show Editor" : "Hide Editor"}</Button>
        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {!editorHidden &&
          <>
            {insertNoteError && <Alert
              message="Note save failed"
              description="Could not save note, please try again later"
              type="error"
              closable
              style={{
                marginBottom: 10
              }}
            />}
            {JSON.stringify(insertNoteError)}
            <SlateNoteEditor updateValue={setEditorValue} />
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: 10 }}>
              <Button type="primary" onClick={insertNoteOnSave} loading={insertNoteLoading}>Save</Button>
            </div>
          </>}
      </div>

    </>
  )
}


