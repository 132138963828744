import { Button, Modal } from 'antd';
import React from 'react';
import { VictoryPie } from 'victory';
import { green5, red5, yellow5 } from '../utils/colors';
import { formulateModal } from '../utils/Descriptions';
import { InfoOutlined } from '@ant-design/icons';


export const FrequencyDomainPie = ({ recording }: any) => {
  const [modal, modalContextHolder] = Modal.useModal();

  return (
    <div style={{ maxWidth: 500, maxHeight: 500, marginLeft: 'auto', marginRight: 'auto', marginBottom: 10 }}>
      <Button
        style={{}}
        onClick={() => formulateModal(modal, "Frequency Domain", "pie-desc")}
        shape="circle" icon={<InfoOutlined />} />

      <VictoryPie
        // height={450}
        // width={400}
        padAngle={2}
        innerRadius={3}
        style={{

          data: {
            fill: (d) => {
              return d.datum?.color
            },
            cursor: () => "pointer"
          }
        }}
        // labels={({ datum }) => `${datum.name} \n ${datum.x} \n ${(datum.y / recording?.data?.frequencyDomain?.total_power * 100).toFixed(1)}%`}
        labels={({ datum }) => [datum.name, datum.x, `${(datum.y / recording?.data?.frequencyDomain?.total_power * 100).toFixed(1)}%`]
        }

        labelRadius={70}
        data={[
          { x: "VLF", y: recording?.data?.frequencyDomain?.vlf, color: red5, name: "NeuroH" },
          { x: "LF", y: recording?.data?.frequencyDomain?.lf, color: yellow5, name: "SNS" },
          { x: "HF", y: recording?.data?.frequencyDomain?.hf, color: green5, name: " PNS" }
        ]}
        events={[{
          target: "data",
          eventHandlers: {
            onClick: () => {
              return [
                {
                  // Add an event to reset all the points to the original color
                  target: "data",
                  eventKey: "all",
                  mutation: (props) => {
                    return { style: { fill: props.datum.color } }
                  }
                },
                {
                  target: "data",
                  mutation: (props) => {
                    formulateModal(modal, props.datum.x, props.datum.x.toLowerCase())
                    const fill = props.style && props.style.fill;
                  }
                }
              ]
            }
          }
        }]}
      />
      {modalContextHolder}
    </div>
  )
};
