import { Modal } from 'antd';
import React, { useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { formulateModal } from '../utils/Descriptions';

export const PittaBarDisplay = ({ recording }: any) => {
  const [clearBars, setClearBars] = useState<any>()
  const [modal, modalContextHolder] = Modal.useModal();
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={{ x: 20 }}
      width={450}
    >
      <VictoryAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", strokeWidth: 0.5 },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 15, padding: 5, fill: "black" }
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 15, padding: 5, fill: "black" }
        }}
      />
      <VictoryBar
        externalEventMutations={clearBars}
        domain={{ y: [0, 10] }}
        barRatio={0.8}
        style={{
          data: {
            cursor: 'pointer',
            fill: (d) => {
              return (d.datum.y < 1) ? "#FFB300" : (d.datum.y > 3.5) ? "#E53935" : "#43A047"
            }
          }
        }}
        categories={{ x: ["P1", "P2", "P3", "P4", "P5"] }}
        data={[
          { x: 1, y: recording?.data?.doshas?.pittaSub?.sadhaka, name: "Sadhaka", shortName: "P1", relationship: "V1, K5", affectedAreas: "Brain, heart, spine, nervous system functions; have a controlling action of the subdoshas of their respective dosha", details: "Headaches, memory issues, mental fatigue, issues with heart muscle and irregular pulse.  Associated w/ serotonin and dopamine. Can manifest as depression, ADD.  Poor retention of information." },
          { x: 2, y: recording?.data?.doshas?.pittaSub?.pachaka, name: "Pachaka", shortName: "P2", relationship: "V4, K1", affectedAreas: "Govern digestive processes; stomach and small intestines", details: "Lower part of stomach & sm. Intestines. Hyperacidity/diarrhea/fever. Inability to break down fats, proteins, carbohydrates.  Dysbiosis, food intolerances, prone to duodenal ulcers.  hPylori infection, GERD, inflamed gallbladder, parasites, gallstones." },
          { x: 3, y: recording?.data?.doshas?.pittaSub?.alochaka, name: "Alochaka", shortName: "P3", relationship: "V3, K3", affectedAreas: "Head, face, neck, mouth, sensory perceptions; help the function of their respective subtypes", details: "Governs visual perception and eye health.  Includes all parts of the eye.  Can cause distorted vision, bloodshot eyes." },
          { x: 4, y: recording?.data?.doshas?.pittaSub?.bhrajaka, name: "Bhrajaka", shortName: "P4", relationship: "V5, K2", affectedAreas: "Limbs, skin, joints; surface of the body; circulation", details: "Governs the skin and skin diseases." },
          { x: 5, y: recording?.data?.doshas?.pittaSub?.ranjaka, name: "Ranjaka", shortName: "P5", relationship: "V2, K4", affectedAreas: "Support other doshas; kidney/heart/liver; govern waste products of the doshas", details: "Governs area of liver, gallbladder, spleen, small intestines.  Involved in regulating Heme and Iron metabolism. These get oxygen to tissues. Can manifest as anxiety/panic; fatigue, brain fog, inflammation and pain." }
        ]}
        events={[
          {
            target: "data",
            eventHandlers: {
              onClick: () => {
                setClearBars([
                  {
                    target: ["data"],
                    eventKey: "all",
                    mutation: () => ({ style: undefined }),
                  }
                ])
                return [{
                  target: "data",
                  mutation: (props) => {
                    const x = props.datum.x;
                    const y = props.datum.y;
                    formulateModal(modal, "Doshas", props.datum.name.toLowerCase(), {
                      name: props.datum.name,
                      shortName: props.datum.shortName,
                      value: y.toFixed(2),
                      status: (y < 1) ? "Chronic" : (y > 3.5) ? "acute" : "Normal",
                      parentDosha: 'Pitta',
                    }, 'eastern')

                  }
                }];
              },

            }
          }
        ]}
      />
      {modalContextHolder}
    </VictoryChart>
  )
};
