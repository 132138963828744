import { Form, Input, Button, Checkbox, Spin } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useAuth } from '../../App';
import { Link, useHistory } from 'react-router-dom';
import './LoginContainer.css'
import logo from '../../hq-logo.svg';


export const LoginContainer = () => {
  let history = useHistory();
  let auth = useAuth();

  const [signinLoading, setSigninLoading] = useState(false)
  const [error, setError] = useState<string>("")

  const onFinish = (values: any) => {
    setSigninLoading(true)
    auth.signin(values)
      .then((data: any) => {
        // console.log(data)
        if (data?.status != 200) {
          setError("Email or Password incorrect")

        } else {
          history.push("/dashboard")
        }
        setSigninLoading(false)
      })
      .catch((error: any) => {
        setSigninLoading(false)
        console.log(error)
      })
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <div>
        <img src={logo} alt="Heart Quest Logo" />
      </div>
      <h1 style={{ marginBottom: 0, fontWeight: "bold", letterSpacing: 7, lineHeight: 1.5, textAlign: "center", marginTop: 50 }}>HEART QUEST</h1>
      <pre style={{ marginBottom: 50, marginTop: 20, fontFamily: "monospace" }}>Beta Version</pre>
      {signinLoading ? <div style={{ marginBottom: 30 }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </div> : null}
      <div>
        {error ? <p style={{ color: "red" }}>{error}</p> : null}
      </div>
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ email: "", password: "", remember: true }}
        onFinish={onFinish}
        onCompositionUpdate={() => {
          setError("")
        }}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>

        <Form.Item>
          <Link className="login-form-forgot" to="/forgot-password">
            Forgot password?
          </Link>
        </Form.Item>

        <Form.Item >
          <Button type="primary" htmlType="submit" className={"login-form-button"}>
            Log in
          </Button>
          <div style={{ marginTop: 10 }}>
            Or <Link to="/signup">Sign Up</Link>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};