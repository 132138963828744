import { Modal } from 'antd';
import React, { useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { formulateModal } from '../utils/Descriptions';

export const VataBarDisplay = ({ recording }: any) => {
  const [clearBars, setClearBars] = useState<any>()
  const [modal, modalContextHolder] = Modal.useModal();
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={{ x: 20 }}
      width={450}
    >
      <VictoryAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", strokeWidth: 0.5 },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 15, padding: 5, fill: "black" }
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 15, padding: 5, fill: "black" }
        }}
      />
      <VictoryBar

        externalEventMutations={clearBars}
        domain={{ y: [0, 10] }}
        barRatio={0.8}
        style={{
          data: {
            cursor: 'pointer',
            fill: (d) => {
              return (d.datum.y < 1) ? "#FFB300" : (d.datum.y > 3.5) ? "#E53935" : "#43A047"
            }
          }
        }}
        categories={{ x: ["V1", "V2", "V3", "V4", "V5"] }}
        data={[
          { x: 1, y: recording?.data?.doshas?.vataSub?.prana, name: "Prana", shortName: "V1", relationship: "P1, K5", affectedAreas: "Brain, heart, spine, nervous system functions; have a controlling action of the subdoshas of their respective dosha", details: "Sensory issues:  dizziness, fear, insomnia, shortness of breath.  Connection to acetylcholine & noradrenaline.  Can manifest as headaches, tinnitus, neuropathies, memory issues, anxiety, insomnia." },
          { x: 2, y: recording?.data?.doshas?.vataSub?.apana, name: "Apana", shortName: "V2", relationship: "P5, K4", affectedAreas: "Support other doshas; kidney/heart/liver; govern waste products of the doshas", details: "Irregular bowel movements; gas/bloating; menstrual disorders, urinary disorders, swollen prostate.  Esophageal reflux—hyper or hypoacidity; weak digestion.  Reproductive issues with men & women." },
          { x: 3, y: recording?.data?.doshas?.vataSub?.udana, name: "Udana", shortName: "V3", relationship: "P3, K3", affectedAreas: "Head, face, neck, mouth, sensory perceptions; help the function of their respective subtypes", details: "Speech disorders or problems expressing self; dry cough, vomiting; esophageal issues." },
          { x: 4, y: recording?.data?.doshas?.vataSub?.samana, name: "Samana", shortName: "V4", relationship: "P2, K1", affectedAreas: "Govern digestive processes; stomach and small intestines", details: "Irregular elimination, malabsorption, sphincter issues related to reflux; intestinal bacterial imbalance leading to abdominal pain and irritable bowel/bloating/gas.  Decreased bile flow or thickened bile w/decreased absorption of fat- soluble vitamins." },
          { x: 5, y: recording?.data?.doshas?.vataSub?.vyana, name: "Vyana", shortName: "V5", relationship: "P4, K2", affectedAreas: "Limbs, skin, joints; surface of the body; circulation", details: "Circulatory disorders; hypertension; dry skin & membranes coldness; motor nerve disorders; heart arrythmias, palpitations. Peripheral circulation issues (cold extremities); decreased oxygen to tissues." }
        ]}
        events={[
          {
            target: "data",
            eventHandlers: {
              onClick: () => {
                setClearBars([
                  {
                    target: ["data"],
                    eventKey: "all",
                    mutation: () => ({ style: undefined }),
                  }
                ])
                return [{
                  target: "data",
                  mutation: (props) => {
                    const x = props.datum.x;
                    const y = props.datum.y;
                    formulateModal(modal, "Doshas", props.datum.name.toLowerCase(), {
                      name: props.datum.name,
                      shortName: props.datum.shortName,
                      value: y.toFixed(2),
                      status: (y < 1) ? "Chronic" : (y > 3.5) ? "acute" : "Normal",
                      parentDosha: 'Vata',
                    }, 'eastern')

                  }
                }];
              },

            }
          }
        ]}
      />{modalContextHolder}
    </VictoryChart>
  );
};
