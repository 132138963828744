import { gql } from '@apollo/client';

export const GET_PRAC_PATIENTS = gql`
query GetPractitionerPatients($_id: String!) {
  followers(where: {follower_id: {_eq: $_id}, deleted_on: {_is_null: true}}) {
    accepted_on
    id
    followee {
      id
      email
      firstName
      lastName
      birthDate
    }
  }
  all: followers_aggregate(where: {follower_id: {_eq: $_id}, deleted_on: {_is_null: true}}) {
    aggregate {
      count
    }
  }
  accepted: followers_aggregate(where: {follower_id: {_eq: $_id}, accepted_on: {_is_null: false}}) {
    aggregate {
      count
    }
  }
  pending: followers_aggregate(where: {follower_id: {_eq: $_id}, accepted_on: {_is_null: true}, deleted_on: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}`


export const GET_HEART_DATA_BY_OWNER = gql`
query GetHeartDataByOwner($_eq: String) {
  heart_data(where: {owner: {_eq: $_eq}, trend_recording: {_eq: "true"}}) {
    id
  }
}`

export const GET_USER_RECORDINGS = gql`
query GetAllHeartDataForOwner($id: uuid!, $_eq: String!, $order_by: order_by, $where: heart_data_bool_exp) {
  user(id: $_eq) {
    firstName
    birthDate
  }
  users_by_pk(id: $id) {
    data
    birth_date
    first_name
    last_name
  }
  heart_data(order_by: {created_on: $order_by}, where: $where) {
    id
    title
    data
    created_on
    trend_recording
    rr_file {
      id
    }
    ecg_file {
      id
    }
    rr_metadata {
      id
      max
      mean
      min
      owner_id
      threshold
      rrs
      total_filtered_rr
      total_rejected
      total_rr
    }
    brain_spectrum {
      id
      delta
      theta
      alpha
      beta
      hbeta
    }
  }
}`

export const GET_USER_INFO = gql`
query GetAllHeartDataForOwner($_eq: String!, $id: uuid!) {
  user(id: $_eq) {
    firstName
    birthDate
  }
  users_by_pk(id: $id) {
    data
    birth_date
    first_name
    last_name
  }
}`

export const GET_USERS_BY_EMAIL = gql`
query GetUsersByEmail($email: String!) {
  userByEmail(email: $email) {
    total
    users {
      id
      email
      birthDate
      active
      firstName
      fullName
      lastName
      username
    }
  }
}`

export const GET_DESCRIPTION_BY_KEY = gql`
query GetDescriptionByKey($key: String!) {
  descriptions(filter: {key: {_eq: $key}, status: {_eq: "published"}}) {
    status
    hq_professional
  }
}`

export const GET_PRAC_FOLLOWERS = gql`
query GetPractitionersFollowers($followee_id: String!) {
  followers(where: {deleted_on: {_is_null: true}, followee_id: { _eq: $followee_id}}, order_by: {accepted_on: asc_nulls_first}) {
    id
    requested_on
    accepted_on
    follower {
      firstName
      lastName
      birthDate
      email
    }
  }
}`

export const GET_GLOBAL_NOTIFICATIONS = gql`
query GetGlobalNotifications {
  global_notifications(filter: {status: {_eq: "published"}}) {
    id
    Title
    message
    message_type
    status
  }
}
`

export const GET_NOTES_FOR_RECORDING = gql`
query GetNotesForRecording($heart_data_id: uuid!) {
  note(where: {heart_data_id: {_eq: $heart_data_id}}) {
    id
    note
    author_id
    heart_data_id
    created_at
    updated_at
  }
}`

export const GET_USER_INFO_FRAGMENT = gql`
query GetAllHeartDataForOwner($userIdString: String!, $userIdUuid: uuid!) {
  user(id: $userIdString) {
    firstName
    birthDate
  }
  users_by_pk(id: $userIdUuid) {
    data
    birth_date
    first_name
    last_name
  }
}`

export const GET_HEART_DATA_BY_PK = gql`
query GetHeartDataByPk($userIdString: String!, $userIdUuid: uuid!, $id: uuid!) {
  user(id: $userIdString) {
    firstName
    birthDate
  }
  users_by_pk(id: $userIdUuid) {
    data
    birth_date
    first_name
    last_name
  }
  heart_data_by_pk(id: $id) {
    id
    title
    data
    created_on
    trend_recording
    rr_file {
      id
    }
    ecg_file {
      id
    }
    rr_metadata {
      id
      max
      mean
      min
      owner_id
      threshold
      rrs
      total_filtered_rr
      total_rejected
      total_rr
    }
    brain_spectrum {
      id
      delta
      theta
      alpha
      beta
      hbeta
    }
  }
}
`

export const GET_BRAIN_GAUGE_DETAIL = gql`
query GetBrainGaugeDetails {
  bg_parameters {
    id
    key
    name
    explanation
  }
}`

export const GET_BRAIN_GAUGE_RAW_SCORES = gql`
query GetBrainGaugeRawScores {
  BG_Raw_Scores {
    id
    key
    name
    explanation
  }
}`

export const GET_BRAIN_DESCRIPTION = gql`
query GetBrainDescription {
  descriptions(filter: {key: {_in: ["brain_20", "brain_30", "brain_50", "brain_60", "brain_80", "brain_100"]}}, sort: "key") {
    key
    hq_professional
  }
}
`

export const GET_SURVEY_BY_RECORDING = gql`
query GetVagalNerveQuestions($heart_data_ref: uuid!, $survey_ref: Int!) {
  get_heart_data_answers(args: {heart_data_ref: $heart_data_ref, survey_ref: $survey_ref}) {
    answer_id
    answer
    question_id
    question
    heart_data_id
    survey_id
  }
}
`