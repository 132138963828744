import { useQuery } from '@apollo/client';
import { Button, Card, Col, Input, Row, Space, Statistic, Table, Tag } from 'antd';
import { Content } from 'antd/lib/layout/layout'
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import moment from 'moment';
import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { useAuth } from '../App';
import { GET_GLOBAL_NOTIFICATIONS, GET_HEART_DATA_BY_OWNER, GET_PRAC_PATIENTS } from '../graphql/queries';
import { SearchOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { red5 } from '../utils/colors';
import { pointer } from 'd3';
import { RemovePatientFromListModal } from '../components/RemovePatientFromListModal';
import { EditPatientModal } from '../components/EditPatientModal';

export const ListPatients = () => {
  let auth = useAuth();

  const searchInput = useRef(null)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const { loading, error, data, refetch } = useQuery(GET_PRAC_PATIENTS, {
    variables: { _id: auth.getUser()?.id },
  });


  if (loading) {
    return <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 200,
      }} >
      <p>Loading...</p>
    </Content>
  }

  if (error) {
    return <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 200,
      }} >
      <p>Error</p>
      <p>{JSON.stringify(error)}</p>
    </Content>
  }

  const patientArray = data ? data.followers.map((d: any, index: number) => {
    const user = d.followee

    return {
      relationship_id: d.id,
      key: index,
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      age: moment().diff(user?.birthDate, 'years'),
      address: user?.email,
      tags: [
        d.accepted_on ? 'accepted' : 'pending',
      ],
      birthDate: user?.birthDate
    }
  }) : []

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    // onFilterDropdownVisibleChange: (visible: any) => {
    //   if (visible) {
    //     setTimeout(() => searchInput.current.select(), 100);
    //   }
    // },
    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText('')
  };

  const getTagColour = (tag: string) => {
    switch (tag) {
      case 'pending':
        return 'blue'
      case 'accepted':
        return 'green'
      default:
        return ''
    }
  }


  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 200,
      }} >
      <Row>
        <Col span={8}>
          <h1>Patient List</h1></Col>
        <Col span={8} offset={8}>
          <div style={{
            display: 'flex',
            alignItems: 'end',
            justifyContent: 'end',
            flexDirection: 'column',
            gap: 10
          }}>
            <Link to="/dashboard/patients/connect">
              <Button icon={<PlusOutlined />}>
                Connect</Button>
            </Link>
            <Link to="/dashboard/patients/register">
              <Button
                // href="/dashboard/patients/register" 
                icon={<UserAddOutlined />}>Register</Button>
            </Link>
          </div>
        </Col>
      </Row>
      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <Statistic
                title="Total Connections"
                value={data?.all ? data.all.aggregate.count : 0}
                // precision={2}
                valueStyle={{ color: '#3f8600' }}
              // prefix={<ArrowUpOutlined />}
              // suffix="%"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Accepted Connections"
                value={data?.all ? data.accepted.aggregate.count : 0}
                // precision={2}
                valueStyle={{ color: '#3f8600' }}
              // prefix={<ArrowUpOutlined />}
              // suffix="%"
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Peinding Connections"
                value={data?.all ? data.pending.aggregate.count : 0}
                // precision={2}
                valueStyle={{ color: '#cf1322' }}
              // prefix={<ArrowDownOutlined />}
              // suffix="%"
              />
            </Card>
          </Col>
        </Row>
      </div>
      <Table dataSource={patientArray}>
        <Column
          title="First Name"
          dataIndex="firstName"
          key="firstName"
          sorter={(a: any, b: any) => a.firstName.length - b.firstName.length}
          sortDirections={['descend', 'ascend']}
          {...getColumnSearchProps('firstName')}
        />
        <Column
          title="Last Name"
          dataIndex="lastName"
          key="lastName"
          sorter={(a: any, b: any) => a.lastName.length - b.lastName.length}
          sortDirections={['descend', 'ascend']}
          {...getColumnSearchProps('lastName')}
        />
        <Column
          title="Age"
          dataIndex="age"
          key="age"
          sorter={(a: any, b: any) => a.age.length - b.age.length}
          sortDirections={['descend', 'ascend']}
          {...getColumnSearchProps('age')}
        />
        <Column
          title="Address"
          dataIndex="address"
          key="address"
          sorter={(a: any, b: any) => a.address.length - b.address.length}
          sortDirections={['descend', 'ascend']}
          {...getColumnSearchProps('address')}
          width="10%"
        />
        <Column
          title="Tags"
          dataIndex="tags"
          key="tags"
          filters={[
            { text: 'pending', value: 'pending' },
            { text: 'accepted', value: 'accepted' },
          ]}
          onFilter={(value, record: any) => record.tags.includes(value)}
          // sorter={(a: any, b: any) => a.tags.length - b.tags.length}
          // sortDirections={['descend', 'ascend']}
          // {...getColumnSearchProps('tags')}
          render={tags => (
            <>
              {tags.map((tag: any) => (
                <Tag
                  color={getTagColour(tag)}
                  key={tag}>
                  {tag}
                </Tag>
              ))}
            </>
          )}

          width="20%"
        />
        <Column
          title="Action"
          key="action"
          render={(text, record: any) => {
            let birthDate = new Date(record.birthDate)
            return (
              <Space size="middle">
                <Link to={`/dashboard/patients/${record.id}`}>Trend</Link>
                <Link to={`/dashboard/patients/${record.id}/glance`}>Overview</Link>
                <Link to={`/dashboard/patients/${record.id}/comparison`}>Comparisons</Link>
                <RemovePatientFromListModal relationship_id={record.relationship_id} followerName={record.firstName} />
                <EditPatientModal user={{
                  id: record.id,
                  firstName: record.firstName,
                  lastName: record.lastName,
                  email: record.address,
                  data: {
                    gender: "male",
                  },
                  birthDate: birthDate
                }} refetch={refetch} />
              </Space>)
          }}
        />
      </Table>
    </Content>
  )
}
