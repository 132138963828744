import React, { useRef } from 'react'
import { useResizeObserver } from '../utils/helperFunctions'
import { scaleLinear } from 'd3'

export const VerticalBarComponent = ({ value, domain, barHeight= 400, barWidth = 25, decimal = 2, reverse=false, backgroundColor="linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)",  onClick }: any) => {
  const ref = useRef<any>()
  const resizeObserver = useResizeObserver(ref)
  const containerWidth = resizeObserver?.width

  const scaleY = scaleLinear()
    .domain(domain ? domain : [0, 100])
    .range(reverse ? [0, barHeight] : [barHeight, 0])
    .clamp(true)

  const fontScale = scaleLinear()
    .domain([10, 25])
    .range([10, 18])
    .clamp(true)


  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: barWidth,
        alignItems: 'center',
        position: "relative",
        cursor: "pointer"
      }}
      onClick={onClick}
    >
      <div style={{
        // flex: 1,
        width: barWidth,
        // minWidth: 25,
        height: barHeight,
        background: backgroundColor,
        borderRadius: 25
      }}>

      </div>
      <div style={{
        position: "absolute",
        width: (barWidth * 2),
        height: (barWidth * 2),
        borderRadius: 25,
        backgroundColor: "white",
        left: containerWidth ? containerWidth/2 : barWidth ,
        top: scaleY(value),
        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        textAlign: "center",
        // lineHeight: height / 7.5
        display: 'flex',
        alignItems: "center",
        justifyContent: "center"
      }}>
        <div style={{ flex: 1, fontSize: fontScale(barWidth) }}>
          {value?.toFixed(decimal)}
        </div>
      </div>
    </div>
  )
}
