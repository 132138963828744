import { Alert, Button, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { HeartOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { csv } from 'd3';
import { VictoryAxis, VictoryBrushContainer, VictoryChart, VictoryHistogram, VictoryLine, VictoryZoomContainer } from 'victory';
import { EcgViewer } from './EcgViewer';
import { red5 } from '../utils/colors';

export const EcgModal = ({ recording }: any) => {
  // console.log(recording.rr_metadata);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingEcg, setLoadingEcg] = useState(false)
  const [ecgData, setEcgData] = useState<Number[]>([])
  const [loadingRR, setLoadingRR] = useState(false)
  const [rrData, setRRData] = useState<{ x: Number, y: number }[]>([])
  const source = axios.CancelToken.source()

  const [selectedDomain, setSelectedDomain] = useState();
  const [zoomDomain, setZoomDomain] = useState();

  const SAMPLE_FACTOR = 1

  useEffect(() => {
    return () => {
      // source.cancel()
      setRRData([])
      setEcgData([])
    }
  }, [isModalVisible])

  const showModal = () => {
    setIsModalVisible(true);
    getEcg(recording.recording_location)
    if (recording.rr_metadata === null) {
      console.log("Getting old RR data");

      getRR(recording.recording_location)
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
    source.cancel()
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    source.cancel()
  };

  const filterData = (data: any, index: number) => {
    return index % SAMPLE_FACTOR == 0
  }

  async function getEcg(location: any) {
    console.log(recording?.ecg_file?.id)
    setLoadingEcg(true)
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/v1/files/ecg/${recording?.ecg_file?.id}`,
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      // data: location,
      cancelToken: source.token
    })
      .then(urldata => {
        console.log("URL DATA", urldata)
        // if (true) {
        //   return csv(urldata.data.replace("localstack", "localhost"))
        // }
        return csv(urldata.data)
      })
      .then((res) => {
        const column = res.columns[0]
        const fullData = res.filter(filterData)
        // console.log(res.length)
        const ecgdata = fullData.map((d: any) => Number(d[column]))
        // console.log(ecgdata);

        setEcgData(ecgdata)
        setLoadingEcg(false)
      })
      .catch(error => {
        console.log(error)
        setLoadingEcg(false)
      })
  }

  async function getRR(location: any) {
    setLoadingEcg(true)
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/v1/files/rr/${recording?.rr_file?.id}`,
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      // data: location,
      cancelToken: source.token
    })
      .then(urldata => {
        if (true) {
          return csv(urldata.data.replace("localstack", "localhost"))
        }
        return csv(urldata.data)
      })
      .then((res) => {
        const rrData = res.map((values: any, index: any) => {
          return { x: Number(values.x) / SAMPLE_FACTOR, y: Number(values.y) }
        })

        setRRData(rrData)
        setLoadingRR(false)
      })
      .catch(error => {
        console.log(error)
        setLoadingRR(false)
      })
  }


  // const handleZoom = (domain: any) => {
  //   setSelectedDomain(domain);
  // };

  // const handleBrush = (domain: any) => {
  //   setZoomDomain(domain);
  // };

  return (
    <>
      <Button
        // type="primary"
        icon={<HeartOutlined />}
        // loading={loadings[1]}
        style={{
          backgroundColor: red5,
          border: 'none',
          color: 'white'
        }}
        onClick={showModal}
      >
        View ECG
      </Button>

      <Modal
        width={2000}
        // style={{ width: "100%" }}  
        title="ECG Graph"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>

        {ecgData && !loadingEcg ?
          <>
            <EcgViewer data={ecgData} rrData={rrData} filteredRR={recording?.rr_metadata?.rrs} />
          </>
          : <Spin tip="Loading...">
            <Alert
              message="Fetching ECG"
              description="Please wait while are getting your ECG file"
              type="info"
            />
          </Spin>
        }
      </Modal>
    </>
  );
}
