import React from 'react'

import { Alert, Breadcrumb, Button, Descriptions, PageHeader, Progress } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { useEffect, useState } from 'react'
import { Line, LineChart, ResponsiveContainer } from 'recharts';
// import { Line, LineChart, ResponsiveContainer, XAxis } from 'recharts';
import { bufferCount, scan, Subject } from 'rxjs';
// import { Line, VictoryAxis, VictoryChart, VictoryLine, VictoryTheme } from 'victory';
// import './App.css'
import { PlayCircleOutlined } from '@ant-design/icons'
import { Link, useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  HomeOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from '../graphql/queries';
import moment from 'moment';

const sub$ = new Subject<number>();

const observable$ = sub$.pipe(
  bufferCount(150),
  scan((acc: any[], curr) => [...acc, ...curr].slice(-3000), []),
)

const recording$ = sub$.pipe(
  bufferCount(1000),
  scan((acc: any[], curr) => [...acc, ...curr], []),
)

export function ExternalTest() {
  const history = useHistory()
  const { userId }: any = useParams()
  const recordingSize = 300000

  const serviceUUID = "dbf90001-1d19-11eb-adc1-0242ac120002"
  const writeChar = "dbf90003-1d19-11eb-adc1-0242ac120002"
  const dataStreamChar = "dbf90005-1d19-11eb-adc1-0242ac120002"

  const [device, setDevice] = useState<BluetoothDevice | undefined>()
  const [server, setServer] = useState<BluetoothRemoteGATTServer | undefined>()
  const [service, setService] = useState<BluetoothRemoteGATTService | undefined>()
  const [streamData, setStreamData] = useState<number[]>([])
  const [shouldStream, setShouldStream] = useState<boolean>(false)
  const [recordingData, setRecordingData] = useState<number[]>([])
  const [recordingDone, setRecordingDone] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errors, setErrors] = useState<string[]>([])

  const { loading, error, data } = useQuery(GET_USER_INFO, {
    variables: {
      _eq: userId,
      id: userId
    }
  })

  useEffect(() => {
    observable$.subscribe(
      (data) => {
        setStreamData(data)
      },
    )
    if (device) {
      device.addEventListener('gattserverdisconnected', () => {
        console.log("Device Disconected");

      })
    }
    return () => {
      console.log(
        "Running Cleanup"
      );
      // stopStreamAndRecording()

    }
  }, [device, server, service])

  const initialiseHeartQuest = async () => {
    let gotDevice = device
    if (!device) {
      console.log("No device found creating new connection");
      gotDevice = await GetHeartQuestDevice([serviceUUID, writeChar, dataStreamChar])
      setDevice(gotDevice)
    }

    if (!gotDevice) {
      console.log("No Device");
      const errorList = [...errors, "No Device Found, please scan again"]
      setErrors(errorList)
      return;
    }
    const gotServer = await GetHeartQuestServer(gotDevice)
    setServer(gotServer)

    if (!gotServer) {
      console.log("No Server");
      const errorList = [...errors, "No Server Found, please scan again"]
      setErrors(errorList)
      return;
    }
    const gotService = await GetHeartQuestService(gotServer, serviceUUID)
    setService(gotService)

    if (!gotService) {
      console.log("No Service");
      const errorList = [...errors, "No Service Found, please scan again"]
      setErrors(errorList)
      return;
    }

    await startStream(gotService);
    await readStream(gotService, (e: any) => {
      handleNotifications(e, sub$)
    });
    setShouldStream(true)
  }

  const handleRecordStream = () => {
    console.log("Recording Started");

    recording$.subscribe(
      (data) => {
        setRecordingData(data)
        console.log(data.length);

        if (data.length >= recordingSize) {
          console.log("Done Recording");
          setRecordingDone(true)
          const stringData = convertRecordingToString(data)
          if (stringData) {
            uploadRecording(stringData)
          }
          setShouldStream(false)
          stopStreamAndRecording()
        }
      },
    )
  }

  const stopStreamAndRecording = async () => {
    console.log("Stoppping stream")
    if (!service) {
      console.error("No Service");
      return
    }
    await stopStream(service)
    if (!server) {
      console.error("No Server to disconnect from");
      return
    }
    device?.gatt?.disconnect()
  }

  const convertRecordingToString = (recordingDataArray: number[]): string | undefined => {
    if (recordingDataArray.length < 300000) {
      const addError = [...errors, "The recording was not large enough"]
      setErrors(addError)
      return
    }
    let datastring = ''
    recordingDataArray.forEach((d) => {
      datastring += `${d}\n`
    })
    return datastring
  }

  const uploadRecording = (convertedRecordingString: string) => {
    const textFile = new File([convertedRecordingString], "test_recording.txt", {
      type: "text/plain"
    });
    const formData = new FormData();
    formData.append('recordingFile', textFile);
    formData.append('ownerId', userId);
    formData.append('sampleRate', '1000');
    formData.append('title', 'Test Recording')

    axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      url: `${process.env.REACT_APP_API_URL}/v1/recordings`,
      onUploadProgress: (ev: ProgressEvent) => {
        const progress = Math.round((ev.loaded / ev.total) * 100);
        setUploadProgress(progress);
      },
    })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
        const addError = [...errors, JSON.stringify(err)]
        setErrors(addError)
      })
  }


  return (
    <>
      {/* <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item>
            <HomeOutlined />
            <span>Home</span>
          </Breadcrumb.Item>
        </Link>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item >
            <span>Patients</span>
          </Breadcrumb.Item>
        </Link>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb> */}
      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 0,
          minHeight: 200,
        }} >
        <PageHeader
          className="site-page-header"
          // onBack={history.goBack}
          title={`${data?.user?.firstName}`}
          subTitle={data?.users_by_pk?.last_name}
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 30 }}>
            <Descriptions.Item label="Age">{moment().diff(data?.user?.birthDate, 'years')}</Descriptions.Item>
            <Descriptions.Item label="Gender">{data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null}</Descriptions.Item>
          </Descriptions>
        </PageHeader>
        <div>
          {errors.length > 0 && errors.map((message) => {
            return (<>
              <Alert message={message} closable type="warning" />
            </>)
          })}
          {shouldStream && streamData.length <= 0 && <Alert message={"Please check that your leads are connected correctly"} closable type="warning" />}
          {/* <pre>{JSON.stringify(convertRecordingToString([123, 234, 345, 456, 567]))}</pre> */}
          <div style={{ flex: 1, height: 400, background: "#CECECE50", position: 'relative' }}>
            {recordingDone && <div style={{ position: 'absolute', width: '100%', height: '100%', background: 'rgba(0,0,0,0.2)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Progress type="circle" percent={uploadProgress} />
              <div style={{ marginTop: 10 }}>{uploadProgress < 100 ? "UPLOADING..." : "Done"}</div>
            </div>}
            <div style={{ marginTop: 10, width: '100%', height: 400 }}>
              <ResponsiveContainer width="100%" height="100%" >
                <LineChart
                  width={500}
                  height={300}
                  data={streamData.map((v: any, i: any) => ({ x: i * 2, y: v }))}
                  margin={{
                    top: 5,
                    // right: 30,
                    // left: 20,
                    bottom: 5,
                  }}
                >
                  <Line type="monotone" isAnimationActive={false} dataKey="y" stroke="red" dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
          {recordingData.length > 0 && uploadProgress === 0 && <Progress percent={Math.round(recordingData.length / recordingSize * 100)} status="active" />}
          {/* {uploadProgress > 0 && <Progress percent={uploadProgress} status="active" />} */}
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: 20,
            paddingBottom: 20,
          }}>

            {!service && streamData.length === 0 && <Button type="primary" onClick={initialiseHeartQuest}>{errors.length > 0 ? "RESCAN" : "SCAN"}</Button>}
            <div style={{ width: 5 }}></div>
            {streamData.length > 0 && recordingData.length === 0 && <Button icon={<PlayCircleOutlined />} onClick={handleRecordStream}>RECORD</Button>}
            <div style={{ width: 5 }}></div>
            {streamData.length > 0 && !recordingDone && <Button onClick={() => {
              stopStreamAndRecording()
              setShouldStream(false)
              history.go(0)
            }}>ABORT</Button>}
            {/* {recordingDone && <Button type="primary" onClick={() => {
              history.goBack()
            }}>Go To Patient</Button>} */}
            {
              recordingDone && <div>
                Your recording is complete, you may close the page.
              </div>
            }
          </div>
        </div>
      </Content>
    </>
  )
}


export const isCapable = 'bluetooth' in navigator;

export const GetHeartQuestDevice = async (services: string[]) => {

  try {
    if (!isCapable) {
      return
    }
    const device = await navigator.bluetooth.requestDevice({
      filters: [{
        name: 'HeartQuest'
      },
      ],
      optionalServices: services
    })
    return device

  } catch (error) {
    console.error(error);

  }
}

const GetHeartQuestServer = async (device: BluetoothDevice) => {
  try {
    if (!device.gatt) return
    console.log('Connecting to GATT Server...');
    const server = await device.gatt.connect();
    return server
  } catch (error) {
    console.error(error)
  }
}

const GetHeartQuestService = async (server: BluetoothRemoteGATTServer, serviceUUID: BluetoothServiceUUID) => {
  try {
    console.log('Getting Service...');
    const service = await server.getPrimaryService(serviceUUID);
    return service
  } catch (error) {
    console.error(error);

  }
}

const startStream = async (service: BluetoothRemoteGATTService) => {
  const writeChar = "dbf90003-1d19-11eb-adc1-0242ac120002"

  try {
    console.log('Getting Characteristic...');
    const writeCharacteristic = await service.getCharacteristic(writeChar);
    console.log("Starting stream...");
    await writeCharacteristic.writeValue(startStData)
  } catch (error) {
    console.error(error);
  }

}

const readStream = async (service: BluetoothRemoteGATTService, handleNotifications: (event: any) => void) => {
  const dataStreamChar = "dbf90005-1d19-11eb-adc1-0242ac120002"

  try {
    console.log("Subscribe to Stream");
    const readCharacteristic = await service.getCharacteristic(dataStreamChar);
    const readNotification = await readCharacteristic.startNotifications()
    readNotification.addEventListener('characteristicvaluechanged', handleNotifications)
  } catch (error) {
    console.error(error);
  }

}

const stopStream = async (service: BluetoothRemoteGATTService) => {
  const writeChar = "dbf90003-1d19-11eb-adc1-0242ac120002"

  try {
    console.log('Sending stop command...');
    const writeCharacteristic = await service.getCharacteristic(writeChar);
    const writeResponse = await writeCharacteristic.writeValue(stopStData)
    console.log(writeResponse)

  } catch (error) {
    console.log(error);

  }

}

let txBuffer = new ArrayBuffer(20);
export const startStData = new Uint8Array(txBuffer);
startStData[0] = 0x01
startStData[1] = 0x40
startStData[2] = 0x01
startStData[3] = 0x00
startStData[4] = 0x00

let txBuffer_3 = new ArrayBuffer(20);
export const stopStData = new Uint8Array(txBuffer_3);
stopStData[0] = 0x03
stopStData[1] = 0x40
stopStData[2] = 0x01
stopStData[3] = 0x00
stopStData[4] = 0x00

function handleNotifications(event: any, sub$: Subject<number>) {
  let value: DataView = event.target.value;

  [0, 2, 4, 6, 8, 10, 12, 14, 16, 18].forEach((d) => {
    // a.push(value.getInt16(d, true))
    sub$.next(value.getInt16(d, true))
  })

}
