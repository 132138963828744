import { gql } from '@apollo/client';

export const DOCTER_FOLLOW_USER = gql`
mutation DoctorFollowUser($follower_id: String, $followee_id: String, $accepted_on: timestamptz = null) {
  insert_followers_one(object: {followee_id: $followee_id, follower_id: $follower_id, deleted_on: null, accepted_on: $accepted_on}, on_conflict: {
    constraint: followers_followee_id_follower_id_key,
update_columns: [deleted_on]
  }) {
    id
  }
}`

export const UNFOLLOW_PATIENT = gql`
mutation UnfollowPatient($id: uuid!) {
  update_followers_by_pk(pk_columns: {id: $id}, _set: {deleted_on: "now()", accepted_on: null}) {
    id
  }
}
`

export const REMOVE_FOLLOWER = gql`
mutation UnfollowPatient($id: uuid!) {
  update_followers_by_pk(pk_columns: {id: $id}, _set: {deleted_on: "now()", accepted_on: null}) {
    id
  }
}
`

export const ACCEPT_FOLLOW = gql`
mutation AcceptFollow($id: uuid!) {
  update_followers_by_pk(pk_columns: {id: $id}, _set: {accepted_on: "now()"}) {
    id
  }
}`

export const UPDATE_USER_PROFILE = gql`
mutation UpdateUserProfile($userId: String!, $userDetails: UserDetails!) {
  PatchUser(userId: $userId, userDetails: $userDetails) {
    user {
      birthDate
      email
      firstName
      lastName
      data {
        gender
      }
    }
  }
}
`
export const UPDATE_RECORDING_BY_PK = gql`
mutation UpdateRecordingByPk($id: uuid!, $title: String!, $trend_recording: Boolean!) {
  update_heart_data_by_pk(pk_columns: {id: $id}, _set: {title: $title, trend_recording: $trend_recording}) {
    id
    title
    trend_recording
  }
}
`

export const DELETE_RECORDING_BY_PK = gql`
mutation DeleteRecording($id: uuid!) {
  update_heart_data_by_pk(pk_columns: {id: $id}, _set: {deleted_on: "now()"}) {
    id
    title
    deleted_on
  }
}`


export const DELETE_NOTE_BY_PK = gql`
mutation Delete_Note_By_PK($id: bigint!) {
  delete_note_by_pk(id: $id) {
    id
  }
}`

export const INSERT_NOTE_FOR_RECORDING = gql`
mutation InsertOneNoteForRecording($heart_data_id: uuid!, $author_id: String!, $note: json!, $text: String!) {
  insert_note_one(object: {heart_data_id: $heart_data_id, author_id: $author_id, note: $note, text: $text}) {
    id
    heart_data_id
    author_id
    note
    created_at
    updated_at
  }
}`

export const UPDATE_NOTE_BY_PK = gql`
mutation UpdateNoteByPk($note: json!, $text: String!, $id: bigint!) {
  update_note_by_pk(pk_columns: {id: $id}, _set: {note: $note, text: $text}) {
    id
    note
  }
}`

export const INSERT_ANSWER_ONE = gql`
mutation InsertAnswerOne($answer: Boolean!, $question_id: Int!, $heart_data_id: uuid!) {
  insert_answer_one(object: {question_id: $question_id, heart_data_id: $heart_data_id, answer: $answer}) {
    id
    answer
  }
}
`

export const UPDATE_ANSWER_BY_PK = gql`
mutation UpdateAnswerByPk($id: bigint!, $answer: Boolean!) {
  update_answer_by_pk(pk_columns: {id: $id}, _set: {answer: $answer}) {
    id
    answer
  }
}
`