import { useQuery } from '@apollo/client';
import { Button, Collapse, Modal } from 'antd';
import React, { useState } from 'react'
import { GET_BRAIN_GAUGE_DETAIL } from '../../graphql/queries';
import { blue5 } from '../../utils/colors';
import { LoadingOutlined } from '@ant-design/icons';


const { Panel } = Collapse;

export const BrainGaugeModal = () => {
  const [modal, modalContextHolder] = Modal.useModal();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: bgData, loading: bgLoading, error: bgError } = useQuery(GET_BRAIN_GAUGE_DETAIL)

  const showModal = () => {
    // barcodeData.onClose()
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // barcodeData.onClose()
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    // barcodeData.onClose()
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        // type="primary"
        style={{
          backgroundColor: blue5,
          border: 'none',
          color: 'white'
        }}
        onClick={showModal}
      >
        Parameters
      </Button>
      <Modal
        width={800}
        title="Brain Gauge Parameters"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {/* <pre>{createBarcodeString(barcodeData)}</pre> */}
          <p>Explanations of Brain Gauge Parameters</p>
          {bgLoading && !bgData &&
            <div style={{ width: "100%", display: "flex", flexDirection: "column", height: 30, alignContent: 'center', alignItems: "center", justifyContent: 'center' }}>
              <div style={{ width: 30 }}>
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              </div>
            </div>
          }
          {bgData && !bgLoading &&
            <Collapse defaultActiveKey={[0]} style={{ width: '100%' }} >
              {bgData.bg_parameters.map((d: any, i: number) => {
                return <Panel header={d.name} key={i}>
                  <div dangerouslySetInnerHTML={{ __html: d.explanation }}></div>
                </Panel>
              })}
            </Collapse>
          }
        </div>
      </Modal>
    </>
  )
}
