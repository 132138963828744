import html2canvas from "html2canvas";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

export const useResizeObserver = (ref: any): DOMRectReadOnly | null => {
  const [dimensions, setDimensions] = useState(null)
  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries: any) => {
      entries.forEach((entry: any) => {
        setDimensions(entry.contentRect)
      });
    })
    resizeObserver.observe(observeTarget);
    return () => {
      resizeObserver.unobserve(observeTarget)
    }
  }, [ref])

  return dimensions;
}

export const getFormatedDate = (date: string) => {
  return new Date(date).toLocaleDateString(
    'en-gb',
    {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }
  ); // 18 February 2019
}

export const getFormatedTime = (date: string) => {
  return new Date(date).toLocaleTimeString(
    'en-gb',
    { hour: '2-digit', minute: '2-digit' }
  ); // 18 February 2019
}

export const binInterval = (intervalSize: number) => {
  var bins = []
  for (var i = 600; i < 1200; i += intervalSize) {
    bins.push(i)
  }
  return bins
}

export const returnRandomDataArray = (size: number) => {
  let newArr: any[] = []
  for (let i = 0; i < size; i++) {
    newArr.push(Math.random() * 11)
  }
  return newArr
}

export interface BarcodeObject {
  name: string
  ownerId: string
  age: number
  gender: string
  sampleRate: number
  onClose: () => void
}

export const createBarcodeString = (barcodeObject: BarcodeObject): string => {
  const jsonObject = JSON.stringify(barcodeObject)
  return window.btoa(jsonObject)
}

export const generateHTMLImageUrl = async (id: string) => {
  const element = document.getElementById(id)
  if (!element) return ""
  const canvas = await html2canvas(element);
  return canvas.toDataURL("image/png", 1.0);
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export const useUrlQuery = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}