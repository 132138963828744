import { Button, Col, Modal, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { TSpan, VictoryPie } from 'victory'
import { HorisontalBarComponent } from './HorisontalBarComponent';
import logo from '../hq-logo.svg';
import { DisplayDescription } from './DisplayDescription';
import { formulateModal, generateModalObjects } from '../utils/Descriptions';
import { energyDepHorisontal, hormonesMineralsHorisontal, polyvagalHorisontal, psActivityHorisontal, stressIndexHorisontal, totalPowerHorisontal } from '../utils/gradients';
import { HorisontalBarHeadingSingle } from './HorisontalBarHeadingSingle';
import { HorisontalBarHeadingDouble } from './HorisontalBarHeadingDouble';
import { ValueCard } from './ValueCard';
import { green5, red5, yellow5 } from '../utils/colors';
import moment from 'moment';
import { useAuth } from '../App';
import { FrequencyDomainPie } from './FrequencyDomainPie';
import { toPng } from 'html-to-image';
import { renderToStaticMarkup } from "react-dom/server"
import { RMSSDValueCard } from './RMSSDValueCard';
import { LFHFValueCard } from './LFHFValueCard';




export const HRVDetailsSection = ({ recording, user }: any) => {

  let auth = useAuth();

  const [modal, modalContextHolder] = Modal.useModal();



  return (
    <>
      {/* <pre>{JSON.stringify(recording, null, 2)}</pre> */}
      <Row gutter={20}>
        <Col sm={10}>
          <FrequencyDomainPie recording={recording} />
          <Row gutter={10}>
            <Col sm={6}>
              <ValueCard title="Heart Rate"
                subTitle="(60-84)"
                range={[59, 84]}
                value={recording?.data?.bpm}
                onClick={() => formulateModal(modal, "Heart Rate", "bpm")}
                decimal={0} />
            </Col>
            <Col sm={6}>
              <ValueCard title="SDNN"
                subTitle="(50-70)"
                range={[50, 70]}
                value={recording?.data?.timeDomain?.sdnn}
                onClick={() => formulateModal(modal, "SDNN", "sdnn")} />
            </Col>
            <Col sm={6}>
              <RMSSDValueCard
                title="RMSSD"
                subTitle={`(15-45+)`}
                value={recording?.data?.timeDomain?.rmssd}
                decimal={0}
                onClick={() => formulateModal(modal, "RMSSD", "rmssd")}
              />
            </Col>
            <Col sm={6}>
              <LFHFValueCard
                title="LF-HF Ratio"
                subTitle={`(1 - 3)`}
                value={recording?.data?.frequencyDomain?.lf_hf_ratio}
                decimal={1}
                onClick={() => formulateModal(modal, "LF - HF Ratio", "lf_hf_ratio")}
              />
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: 10 }}>

            {/* <Col sm={6}>
              <ValueCard
                title="Wellness"
                subTitle={`(50-100)`}
                range={[50, 101]}
                value={recording?.data?.healthIndex}
                onClick={() => formulateModal(modal, "Wellness Score", "healthIndex")}
                decimal={1} />
            </Col>
            <Col sm={6}>
              <ValueCard title="Actual Age"
                subTitle="(12-100)"
                range={[12, 100]}
                value={moment().diff(user?.birthDate, 'years')}
                decimal={0} />
            </Col>
            <Col sm={6}>
              <ValueCard
                title="Biological Age"
                subTitle={`(${(recording?.data?.bioAge - 2).toFixed()}-${(recording?.data?.bioAge + 2).toFixed()})`}
                range={[recording?.data?.bioAge - 2, recording?.data?.bioAge + 2]}
                value={recording?.data?.bioAge}
                decimal={0} />
            </Col> */}
          </Row>
        </Col>
        <Col
          sm={14}>
          <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Total Power", "total_power")}>
            <HorisontalBarHeadingSingle
              title="Total Power"
              subHeading="(1500-3500)"
              value={recording?.data?.frequencyDomain?.total_power.toFixed(2)}
            />
            <HorisontalBarComponent
              value={recording?.data?.frequencyDomain?.total_power}
              domain={[100, 6000]}
              goodRange={[1500, 3500]}
              markers={[{ name: "1500", value: 1500 }, { name: "3500", value: 3500 },]}
              decimal={0}
              backgroundColor={totalPowerHorisontal}
              leftText="Low"
              rightText="High"
              valueMarker={true}
            />
          </div>
          <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Stress Index", "stressIndex")}>
            <HorisontalBarHeadingSingle
              title="Stress Index"
              subHeading="(10-100)"
              onClick={() => formulateModal(modal, "Stress Index", "stressIndex")}
              value={recording?.data?.stressIndex.toFixed(2)}
            />
            <HorisontalBarComponent
              value={recording?.data?.stressIndex}
              domain={[10, 500]}
              goodRange={[0, 100]}
              markers={[{ name: "100", value: 100 },]}
              backgroundColor={stressIndexHorisontal}
              leftText="Low"
              rightText="High"
              valueMarker={true}
            />
          </div>
          {/* <div style={{ cursor: "pointer", paddingTop: 10 }} onClick={() => formulateModal(modal, "Dorsal Vagus", "dorsalVagus")}>
            <HorisontalBarHeadingDouble
              titleLeft="Ventral Vagus"
              // subHeadingLeft="(10-500)" 
              titleRight="Dorsal Vagus"
            // subHeadingRight=""
            />
            <HorisontalBarComponent
              value={recording?.data?.dorsalVagus}
              domain={[0, 3]}
              valueMarker={true}
              backgroundColor={hormonesMineralsHorisontal}
            />
          </div> */}


          <div style={{ cursor: "pointer", }} onClick={() => formulateModal(modal, "Nervous System Balance Index", "cns_ans")}>
            <HorisontalBarHeadingSingle
              title="Nervous System Balance Index"
              subHeading="(1-3)"
              value={recording?.data?.cns_ans.toFixed()}
            />
            <HorisontalBarComponent
              value={recording?.data?.cns_ans}
              domain={[0.1, 5]}
              goodRange={[1, 3]}
              valueMarker={true}
              backgroundColor={hormonesMineralsHorisontal}
              leftText="CNS"
              rightText="ANS"
            />
          </div>
          <div style={{
            backgroundColor: "#F3F3F340",
            padding: 20,
            borderRadius: 10,
            marginTop: 10,
            border: "3px solid #99999940",
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            cursor: "pointer"
          }}
            onClick={() => formulateModal(modal, "Poly Vagal", "dorsalVagus")}
          >
            <div style={{
              fontSize: 24,
              textAlign: 'center',
              marginBottom: 20
            }}>Poly Vagal</div>
            <div style={{ cursor: "pointer", }}
            >
              <HorisontalBarHeadingSingle
                title="Parasympathetic Activity"
                subHeading="(0 - 70)"
                value={`${(recording?.data?.frequencyDomain?.hf / recording?.data?.frequencyDomain?.total_power * 100).toFixed()}%`}
              />
              <HorisontalBarComponent
                value={(recording?.data?.frequencyDomain?.hf / recording?.data?.frequencyDomain?.total_power * 10)}
                domain={[0, 7.0]}
                backgroundColor={psActivityHorisontal}
                valueMarker={true}
                markers={[{ name: "15%", value: 1.5 }, { name: "30%", value: 3.0 }, { name: "70%", value: 6.7 }]}
              />
            </div>
            <div style={{ cursor: "pointer", }}
            >
              <HorisontalBarHeadingSingle
                title="Energy Index"
                subHeading="(100-5000)"
                value={`${(recording?.data?.frequencyDomain?.total_power / 1000).toFixed(1)}K`}
              />
              <HorisontalBarComponent
                value={(recording?.data?.frequencyDomain?.total_power)}
                domain={[5000, 100]}
                backgroundColor={energyDepHorisontal}
                valueMarker={true}
                markers={[{ name: "5K", value: 5000 }, { name: "3.5K", value: 3500 }, { name: "1.5K", value: 1500 }, { name: "1K", value: 1000 }, { name: "0.5", value: 500 }, { name: ".1", value: 100 }]}
              />
            </div>
            <div style={{ cursor: "pointer", }}
            >
              <HorisontalBarHeadingSingle
                title="Poly-Vagal"
                subHeading="(0.25-3.0)"
                value={calculatePolyVagal(recording).toFixed(2)}
              />
              <HorisontalBarComponent
                value={calculatePolyVagal(recording)}
                domain={[0.25, 3.0]}
                backgroundColor={polyvagalHorisontal}
                valueMarker={true}
                markers={[{ name: "Ventral nucleous", value: 0.3 }, { name: "Balanced", value: 1.2 }, { name: "Dorsal Nucleous", value: 2.65 }]}
                additionalMarkers={[{ name: "0.25", value: 0.27 }, { name: "0.5", value: 0.5 }, { name: "0.8", value: 0.8 }, { name: "1.0", value: 1 }, { name: "1.2", value: 1.15 }, { name: "3.0", value: 2.9 }]}

              />
            </div>
          </div>

        </Col>
      </Row>
      {modalContextHolder}
    </>
  )
}

export const calculatePolyVagal = (recording: any) => {
  const fireMinister = (recording?.data?.meridians?.tw + recording?.data?.meridians?.pc)
  const earth = (recording?.data?.meridians?.sp + recording?.data?.meridians?.st)
  return fireMinister / earth
}

export const calculatePolyVagalPDF = (recording: any) => {
  const fireMinister = (recording?.meridians?.tw + recording?.meridians?.pc)
  const earth = (recording?.meridians?.sp + recording?.meridians?.st)
  return fireMinister / earth
}