import { Spin } from 'antd';
import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

export const GraphLoadingBox = ({ height }: { height: number }) => {
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", height: height, alignContent: 'center', alignItems: "center", justifyContent: 'center' }}>
      <div style={{ width: 30 }}>
        <LoadingOutlined style={{ fontSize: 24 }} spin />
      </div>
    </div>
  )
};
