import { createContext, useContext } from "react";
import logo from './hq-logo.svg';
import 'antd/dist/antd.css';
import './App.css';
import MainLayout from './containers/MainLayoutContainer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { LoginContainer } from "./containers/auth/LoginContainer";
import { SignupContainer } from "./containers/auth/SignupContainer";
import axios from "axios";
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { ApolloProvider } from '@apollo/client/react';
import { setContext } from '@apollo/client/link/context';
import { json } from "d3";
import { InfoModalContext } from "./utils/ModalContext";
import moment from "moment";
import { ForgotPasswordContainer } from "./containers/auth/ForgotPasswordContainer";
import { DOCTER_FOLLOW_USER } from "./graphql/mutations";
import { GET_PRAC_PATIENTS } from "./graphql/queries";
import { ProfilePage } from "./containers/ProfilePage";
import _ from "lodash";
import { PDFOverviewPage } from "./pdfComponents/PDFOverviewPage";
import { ExternalTest } from "./containers/ExternalTest";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-hasura-admin-secret": process.env.REACT_APP_GRAPHQL_HEADER
    }
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

function App() {

  return (
    <ProvideAuth>
      <ApolloProvider client={client} >
        <InfoModalContext.Provider value="" >
          <Router>
            <Switch>
              <PrivateRoute path="/dashboard">
                <MainLayout />
              </PrivateRoute>
              {/* <PrivateRoute path="/pdf">
                <PDFOverviewPage />
              </PrivateRoute> */}
              <Route path="/login">
                <LoginContainer />
              </Route>
              <Route path="/signup">
                <SignupContainer />
              </Route>
              <Route path="/forgot-password">
                <ForgotPasswordContainer />
              </Route>
              <Route path="/record/:userId">
                <ExternalTest />
              </Route>
              <Route exact path="/">
                <Redirect
                  to={{
                    pathname: "/login",
                  }}
                />
              </Route>
            </Switch>
          </Router>
        </InfoModalContext.Provider>
      </ApolloProvider>
    </ProvideAuth>
  );
}

export default App;


// const fakeAuth = {
// 	isAuthenticated: false,
// 	signin(cb: () => void) {
// 		fakeAuth.isAuthenticated = true;
// 		setTimeout(cb, 100); // fake async
// 	},
// 	signout(cb: () => void) {
// 		fakeAuth.isAuthenticated = false;
// 		setTimeout(cb, 100);
// 	}
// };

const authContext = createContext<any>({});

function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  let history = useHistory();
  // const [user, setUser] = useState<any | null>(null);

  const patientsignup = (data: any, cb: () => void) => {
    // console.log(getUser()?.id)
    // console.log(moment(new Date(data.birthYear, data.birthMonth, data.birthDay)).format('YYYY-MM-DD'));


    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_URL}/api/user/registration`,
      headers: {
        "Authorization": process.env.REACT_APP_AUTHORIZATION,
      },
      data: {
        // applicationId: process.env.REACT_APP_AUTH_APP_ID,
        sendSetPasswordEmail: true,
        registration: {
          applicationId: process.env.REACT_APP_AUTH_APP_ID,
        },
        user: {
          email: data.email,
          firstName: data.firstname,
          lastName: data.lastname,
          fullName: `${data.firstname} ${data.lastname}`,
          birthDate: moment(new Date(data.birthYear, data.birthMonth, data.birthDay)).format('YYYY-MM-DD'),
          data: {
            gender: data.gender
          }
        }
      }
    })
      .then((response) => {
        // console.log(response.status)
        // console.log(response.data)
        // console.log("Running Follower Mutation")
        // console.log("Docter", getUser()?.id)
        // console.log("Followee ID", response.data.user.id);

        return client.mutate({
          mutation: DOCTER_FOLLOW_USER,
          variables: {
            follower_id: getUser()?.id,
            followee_id: response.data.user.id,
            accepted_on: "now()"
          },
          refetchQueries: [{
            query: GET_PRAC_PATIENTS,
            variables: {
              _id: getUser()?.id
            }
          }
          ]
        })
      })
      .then(data => {
        // console.log("User Link Query Result", data);
        return data
      })
      .catch((error) => {
        console.log("error")
        console.log("Error", error.response)
        return error
      })


  };

  const professionalSignUp = (data: any, cb: () => void) => {
    // console.log(data)

    return axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_URL}/api/user/registration`,
      headers: {
        "Authorization": process.env.REACT_APP_AUTHORIZATION,
      },
      data: {
        registration: {

          applicationId: process.env.REACT_APP_AUTH_APP_ID,
          roles: ['user', 'professional']
        },
        user: {
          email: data.email,
          firstName: data.firstname,
          lastName: data.lastname,
          fullName: `${data.firstname} ${data.lastname}`,
          birthDate: moment(new Date(data.birthYear, data.birthMonth, data.birthDay)).format('YYYY-MM-DD'),
          password: data.password,
          data: {
            gender: data.gender
          }
        }
      }
    })
      .then((response) => {
        // console.log(response.data)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('user', JSON.stringify(response.data.user))
        const userRoles = response.data.registration.roles
        localStorage.setItem('userRoles', JSON.stringify(userRoles.roles))
        return client.mutate({
          mutation: DOCTER_FOLLOW_USER,
          variables: {
            follower_id: getUser()?.id,
            followee_id: getUser()?.id
          }
        })
      })
      .then(data => {
        console.log("User Link Query Result", data);
        return data
      })
      .catch((error) => {
        console.log(error)
        return error
      })


  };

  const signin = (data: any, cb: () => void) => {
    return axios({
      method: 'POST',
      url: `${process.env.REACT_APP_AUTH_URL}/api/login`,
      headers: {
        "Authorization": process.env.REACT_APP_AUTHORIZATION,
      },
      data: {
        applicationId: process.env.REACT_APP_AUTH_APP_ID,
        loginId: data.email,
        password: data.password,
      }
    })
      .then((response) => {
        // console.log(response.status)
        // setUser(response.data)
        // console.log(response.data)
        localStorage.setItem('token', response.data.token)
        localStorage.setItem('user', JSON.stringify(response.data.user))
        const userRoles = response.data.user.registrations.find((val: any) => (val.applicationId === process.env.REACT_APP_AUTH_APP_ID))
        localStorage.setItem('userRoles', JSON.stringify(userRoles.roles))
        return response
        // cb()
      })
      .catch((error) => {
        console.log(error)
        return error.response
      })


  };



  const signout = (cb: () => void) => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    cb();
  };

  const getUser = () => {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) : null
  }

  const refetchUser = (id: string) => {
    return axios({
      method: 'GET',
      url: `${process.env.REACT_APP_AUTH_URL}/api/user/${id}`,
      headers: {
        "Authorization": process.env.REACT_APP_AUTHORIZATION,
      }
    })
      .then((response) => {
        // console.log(response.status)
        // setUser(response.data)
        // console.log(response.data)
        // localStorage.setItem('token', response.data.token)
        // console.log(getUser());

        const newUser = _.merge(getUser(), response.data.user)
        // console.log(newUser);

        localStorage.setItem('user', JSON.stringify(newUser))
        // const userRoles = response.data.user.registrations.find((val: any) => (val.applicationId === process.env.REACT_APP_AUTH_APP_ID))
        // localStorage.setItem('userRoles', JSON.stringify(userRoles.roles))
        return response
        // cb()
      })
      .catch((error) => {
        console.log(error)
        return error.response
      })
  }

  return {
    // user,

    getUser,
    patientsignup,
    professionalSignUp,
    signin,
    signout,
    refetchUser,

  };
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function PrivateRoute({ children, ...rest }: any) {
  // let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem('user') && localStorage.getItem('token') ? (
          children
        ) : (
          // children
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

