import { Modal } from 'antd';
import React, { useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { green5, red5, yellow5 } from '../utils/colors';
import { formulateModal } from '../utils/Descriptions';

export const TissuesBarDisplay = ({ recording }: any) => {
  const [clearBars, setClearBars] = useState<any>()
  const [modal, modalContextHolder] = Modal.useModal();
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={{ x: 20 }}
      // width={250}
      height={300}
    >
      <VictoryAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", strokeWidth: 0.5 },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 10, padding: 5, fill: "black" }
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 10, padding: 5, fill: "black" }
        }}
      />
      <VictoryAxis
        dependentAxis
        offsetX={30}
        theme={VictoryTheme.material}
        tickValues={[1, 3, 5, 7, 10]}
        tickFormat={(tick) => ``}
        style={{
          axis: { display: "none" },
          ticks: { display: "none" },
          // tickLabels: { fontSize: 4, fill: "black" },
          grid: { stroke: ({ tick }) => getColor(tick) }
        }}
      />
      <VictoryBar
        domain={{ y: [0, 20] }}
        barRatio={0.8}
        style={{
          data: {
            cursor: 'pointer',
            fill: (d) => {
              return getColor(d.datum.y)
            }
          }
        }}
        categories={{ x: ["rasa", "rakta", "mamsa", "medu", "asthi", "majja", "shukra"] }}
        data={[
          { x: 1, y: recording?.data?.dhatu?.rasa, name: 'rasa', shortName: "T1" },
          { x: 2, y: recording?.data?.dhatu?.rakta, name: 'rakta', shortName: "T2" },
          { x: 3, y: recording?.data?.dhatu?.mamsa, name: 'mamsa', shortName: "T3" },
          { x: 4, y: recording?.data?.dhatu?.medu, name: 'medu', shortName: "T4" },
          { x: 5, y: recording?.data?.dhatu?.asthi, name: 'asthi', shortName: "T5" },
          { x: 6, y: recording?.data?.dhatu?.majja, name: 'majja', shortName: "T6" },
          { x: 7, y: recording?.data?.dhatu?.shukra, name: 'shukra', shortName: "T7" },
        ]}
        events={[
          {
            target: "data",
            eventHandlers: {
              onClick: () => {
                setClearBars([
                  {
                    target: ["data"],
                    eventKey: "all",
                    mutation: () => ({ style: undefined }),
                  }
                ])
                return [{
                  target: "data",
                  mutation: (props) => {
                    const y = props.datum.y;
                    formulateModal(modal, "Tissues", props.datum.name, {
                      name: props.datum.name,
                      shortName: props.datum.shortName,
                      value: y.toFixed(2),
                      status: (y < 3) ? "Chronic" : (y > 8) ? "acute" : "Normal",
                      parentDosha: 'Dhatu',
                    }, 'eastern')
                  }
                }];
              },

            }
          }
        ]}
      />

      {modalContextHolder}
    </VictoryChart>
  )
};


const getColor = (value: number) => {
  if (value < 3) {
    return red5
  }
  else if (value >= 3 && value < 5) {
    return 'orange'
  }
  else if (value >= 5 && value < 7.5) {
    return yellow5
  }
  else if (value >= 7.5 && value < 10) {
    return green5
  }
  else {
    return green5
  }
}