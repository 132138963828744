import { Modal } from 'antd';
import React, { useState } from 'react';
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme } from 'victory';
import { formulateModal } from '../utils/Descriptions';

export const KaphaBarDisplay = ({ recording }: any) => {
  const [clearBars, setClearBars] = useState<any>()
  const [modal, modalContextHolder] = Modal.useModal();
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={{ x: 20 }}
      width={450}
    >
      <VictoryAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", strokeWidth: 0.5 },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 15, padding: 5, fill: "black" }
        }}
      />
      <VictoryAxis
        dependentAxis
        style={{
          axis: { stroke: "black", strokeWidth: 0.5 },
          grid: { stroke: "none", },
          ticks: { stroke: "black", size: 5 },
          tickLabels: { fontSize: 15, padding: 5, fill: "black" }
        }}
      />
      <VictoryBar
        externalEventMutations={clearBars}
        domain={{ y: [0, 10] }}
        barRatio={0.8}
        style={{
          data: {
            cursor: 'pointer',
            fill: (d) => {
              return (d.datum.y < 1) ? "#FFB300" : (d.datum.y > 3.5) ? "#E53935" : "#43A047"
            },

          }
        }}
        categories={{ x: ["K1", "K2", "K3", "K4", "K5"] }}
        data={[
          { x: 1, y: recording?.data?.doshas?.kaphaSub?.kledaka, name: "Kledaka", shortName: "K1", relationship: "V4, P2", affectedAreas: "Govern digestive processes; stomach and small intestines", details: "Governs upper part of stomach; initial stages of digestion.  Sluggish digestion/nausea/mucous & regulation of mucous in stomach, esophagus & proximal sm intestine; burning in middle of abdomen that can travel to chest/neck/bellybutton or back.  Heartburn possible or indigestion." },
          { x: 2, y: recording?.data?.doshas?.kaphaSub?.sleshaka, name: "Sleshaka", shortName: "K2", relationship: "V5, P4", affectedAreas: "Limbs, skin, joints; surface of the body; circulation", details: "Governs lubrication of joints & protects bones.  Swelling/restricted motion possible tenosynovitis, arthritis, bursitis, osteopenia. Can be impairment of Vit. D production & imbalance in K2. Also issues w/ ENT." },
          { x: 3, y: recording?.data?.doshas?.kaphaSub?.bodhaka, name: "Bodhaka", shortName: "K3", relationship: "V3, P3", affectedAreas: "Head, face, neck, mouth, sensory perceptions; help the function of their respective subtypes", details: "Governs saliva and taste.  Areas are mouth & tongue.  Excess alivation or mucous in mouth/throat.  Issues in nasopharynx, larynx, and upper respiratory tract. Involves throat & bronchopulmonary issues as well as the gums, teeth and sinuses.  May see gastritis." },
          { x: 4, y: recording?.data?.doshas?.kaphaSub?.avalambaka, name: "Avalambaka", shortName: "K4", relationship: "V2, P5", affectedAreas: "Support other doshas; kidney/heart/liver; govern waste products of the doshas", details: "Moist respiratory conditions; lymphostasis that can manifest as bronchitis/asthma/pneumonia.  Could be caused by toxin accumulation (toxins can accumulate in any tissue and create multiple symptoms where area is blocked.)" },
          { x: 5, y: recording?.data?.doshas?.kaphaSub?.tarpaka, name: "Tarpaka", shortName: "K5", relationship: "V1, P1", affectedAreas: "Brain, heart, spine, nervous system functions; have a controlling action of the subdoshas of their respective dosha", details: "Governs stability & metabolic support of the nerve cells including brain & spinal cord. Symptoms of neurodegeneration: apathy, rigid muscles, balance issues, intention tremors, speech changes, insomnia, memory issues;  dullness, depression, sluggishness." }
        ]}
        events={[
          {
            target: "data",
            eventHandlers: {
              onClick: () => {
                setClearBars([
                  {
                    target: ["data"],
                    eventKey: "all",
                    mutation: () => ({ style: undefined }),
                  }
                ])
                return [{
                  target: "data",
                  mutation: (props) => {
                    console.log("Hello");

                    const x = props.datum.x;
                    const y = props.datum.y;
                    formulateModal(modal, "Doshas", props.datum.name.toLowerCase(), {
                      name: props.datum.name,
                      shortName: props.datum.shortName,
                      value: y.toFixed(2),
                      status: (y < 1) ? "Chronic" : (y > 3.5) ? "acute" : "Normal",
                      parentDosha: 'Kapha',
                    }, 'eastern')

                  }
                }];
              },

            }
          }
        ]}
      />
      {modalContextHolder}
    </VictoryChart>
  );
};
