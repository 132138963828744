import { Form, Input, Button, Checkbox, Spin } from 'antd';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useAuth } from '../../App';
import { Link, useHistory } from 'react-router-dom';
import './LoginContainer.css'
import logo from '../../hq-logo.svg';
import axios from 'axios';

export const ForgotPasswordContainer = () => {
  let history = useHistory();
  let auth = useAuth();

  const [error, setError] = useState<string>("")
  const [emailSent, setEmailSent] = useState(false)
  const [emailSentLoading, setEmailSentLoading] = useState(false)


  const onFinish = (values: any) => {
    sendResetEmail(values.email)
  };

  const sendResetEmail = (email: any) => {
    setEmailSentLoading(true)
    axios({
      url: `${process.env.REACT_APP_AUTH_URL}/api/user/forgot-password`,
      method: 'post',
      headers: {
        Authorization: process.env.REACT_APP_AUTHORIZATION,
      },
      data: {
        loginId: email,
      },
    })
      .then((response) => {
        console.log(response);
        setEmailSent(true)
        setEmailSentLoading(false)
        // history.push("/login")
      })
      .catch((err) => {
        console.log(err);
        setEmailSentLoading(false)
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", alignItems: "center", justifyContent: "center" }}>
      <div>
        <img src={logo} alt="Heart Quest Logo" />
      </div>
      <h1 style={{ marginBottom: 70, fontWeight: "bold", letterSpacing: 7, lineHeight: 1.5, textAlign: "center", marginTop: 50 }}>HEART QUEST</h1>
      <div>
        {error ? <p style={{ color: "red" }}>{error}</p> : null}
      </div>
      {emailSentLoading ? <div style={{ marginBottom: 30 }}>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </div> : null}
      {!emailSent && <Form
        name="normal_login"
        className="login-form"
        initialValues={{ email: "", password: "", remember: true }}
        onFinish={onFinish}
        onCompositionUpdate={() => {
          setError("")
        }}
      >

        <Form.Item>
          <p style={{ textAlign: 'center' }} >We will send you an email to reset password!</p>
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input your E-mail!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className={"login-form-button"}>
            Send Password Reset!
          </Button>
          Or <Link to="/login">Sign In Now!</Link>
        </Form.Item>
      </Form>}
      {emailSent &&
        <div>
          <p style={{ textAlign: 'center' }} >Check your email, we have sent you a link to reset your password.</p>
          <Button type="primary" className={"login-form-button"} onClick={() => history.push("/login")} >
            {/* <Link to="/login"> */}
            Back to Login
            {/* </Link> */}
          </Button>
        </div>}

    </div>
  );
};