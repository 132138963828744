import { Col, Modal, Row } from 'antd';
import React from 'react'
import { formulateModal } from '../utils/Descriptions';
import { hormonesMineralsHorisontal, totalPowerHorisontal } from '../utils/gradients';
import { ChakraDiagram } from './ChakraDiagram';
import { FiveElementsDiagram } from './FiveElementsDiagram';
import { HorisontalBarComponent } from './HorisontalBarComponent';
import { HorisontalBarHeadingSingle } from './HorisontalBarHeadingSingle';
import { KaphaBarDisplay } from './KaphaBarDisplay';
import { MeridiansDiagram } from './MeridiansDiagram';
import { PittaBarDisplay } from './PittaBarDisplay';
import { TissuesBarDisplay } from './TissuesBarDisplay';
import { VataBarDisplay } from './VataBarDisplay';

export const EasternSection = ({ recording }: any) => {
  const [modal, modalContextHolder] = Modal.useModal();
  const doshaTotalPower = recording?.data?.doshas?.vata + recording?.data?.doshas?.pitta + recording?.data?.doshas?.kapha

  return (
    <>
      <div id="easternSection">
        <Row>
          <Col sm={24} md={12} lg={8}>
            <h1 style={{ textAlign: "center", cursor: 'pointer' }}
              onClick={() => formulateModal(modal, "Vata", "vataSub")}
            >Vata = {(recording?.data?.doshas?.vata / doshaTotalPower * 100).toFixed(1)}%</h1>
            <VataBarDisplay recording={recording} />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <h1 style={{ textAlign: "center", cursor: 'pointer' }}
              onClick={() => formulateModal(modal, "Pitta", "pittaSub")}
            >Pitta = {(recording?.data?.doshas?.pitta / doshaTotalPower * 100).toFixed(1)}%</h1>
            <PittaBarDisplay recording={recording} />
          </Col>
          <Col sm={24} md={12} lg={8}>
            <h1 style={{ textAlign: "center", cursor: 'pointer' }}
              onClick={() => formulateModal(modal, "Kapha", "kaphaSub")}
            >Kapha  = {(recording?.data?.doshas?.kapha / doshaTotalPower * 100).toFixed(1)}%</h1>
            <KaphaBarDisplay recording={recording} />
          </Col>
        </Row>
        <Row style={{ marginTop: 50 }}>
          <Col sm={24} md={24} lg={12}>
            {/* <h1 style={{ textAlign: "center" }}>Tissues (Dhatu)</h1>
          <div style={{ maxWidth: 550, margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <TissuesBarDisplay recording={recording} />
          </div> */}
            <h1 style={{ textAlign: "center", marginBottom: 50 }}>Meridians</h1>

            <div style={{}}>
              {recording?.data ?
                <MeridiansDiagram meridians={recording?.data?.meridians} />
                : null}

            </div>
            <div style={{ cursor: "pointer", maxWidth: 400, margin: "0 auto" }} onClick={() => formulateModal(modal, "Total Power", "total_power")}>
              <HorisontalBarHeadingSingle
                title="Total Power"
                subHeading="(1500-3500)"
                value={recording?.data?.frequencyDomain?.total_power.toFixed(2)}
              />
              <HorisontalBarComponent
                value={recording?.data?.frequencyDomain?.total_power}
                domain={[1500, 3500]}
                decimal={0}
                backgroundColor={totalPowerHorisontal}
                valueMarker={true}
                leftText="Low"
                rightText="High"
              />
            </div>
          </Col>
          <Col sm={24} md={24} lg={12}>
            <h1 style={{ textAlign: "center" }}>Five Elements</h1>
            {recording?.data ?
              <FiveElementsDiagram meridians={recording?.data?.meridians} elementClick={(d: any) => {
                formulateModal(modal, "Five Elements", d.shortName.toLowerCase(), {
                  name: d.name,
                  shortName: d.shortName,
                  value: d.value,
                  // status: (y < 3) ? "Chronic" : (y > 8) ? "acute" : "Normal",
                  parentDosha: 'Five Elements',
                }, 'eastern')
              }} />
              : null}
            <div style={{ width: "100%" }}>

            </div>
            <div style={{ maxWidth: 400, margin: "0 auto" }}>
              <HorisontalBarHeadingSingle
                title="Deficiency"
                subHeading="(-1 - 1)"
              // value={recording?.data?.frequencyDomain?.total_power.toFixed(2)}
              />
              <HorisontalBarComponent
                value={recording?.data?.yin_yang}
                domain={[-3, 3]}
                onClick={() => formulateModal(modal, "Yin Yang", "yin_yang")}
                backgroundColor={hormonesMineralsHorisontal}
                valueMarker={true}
                leftText="Yin"
                rightText="Yang"
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 50 }}>
          {/* <Col sm={24} md={24} lg={12}>
          <h1 style={{ textAlign: "center" }}>Meridians</h1>

          <div style={{}}>
            {recording?.data ?
              <MeridiansDiagram meridians={recording?.data?.meridians} />
              : null}

          </div>
          <div style={{ cursor: "pointer", maxWidth: 400, margin: "0 auto" }} onClick={() => formulateModal(modal, "Total Power", "total_power")}>
            <HorisontalBarHeadingSingle
              title="Total Power"
              subHeading="(1500-3500)"
              value={recording?.data?.frequencyDomain?.total_power.toFixed(2)}
            />
            <HorisontalBarComponent
              value={recording?.data?.frequencyDomain?.total_power}
              domain={[1500, 3500]}
              decimal={0}
              backgroundColor={totalPowerHorisontal}
              valueMarker={true}
              leftText="Low"
              rightText="High"
            />
          </div>

        </Col> */}
          <Col sm={24} md={24} lg={12}>
            <h1 style={{ textAlign: "center" }}>Energy Plexus</h1>
            <div style={{ flex: 1, width: 520, margin: "0 auto" }}>
              {recording?.data ?
                <ChakraDiagram data={recording?.data} />
                : null}
            </div>
          </Col>
        </Row>
      </div>

      {modalContextHolder}
    </>
  )
}
