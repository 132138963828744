import { Breadcrumb, Button, Space, Spin, Table } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  HomeOutlined,
  UserOutlined
} from '@ant-design/icons';
import { useAuth } from '../App';
import { GET_PRAC_FOLLOWERS } from '../graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import { RemoveFollowerModal } from '../components/RemoveFollowerModal';
import { ACCEPT_FOLLOW } from '../graphql/mutations';
import { LoadingOutlined } from '@ant-design/icons';
import { EditUserModal } from '../components/EditUserModal';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const ProfilePage = () => {
  let auth = useAuth();
  let user = auth.getUser()

  const { loading, error, data } = useQuery(GET_PRAC_FOLLOWERS, {
    variables: {
      followee_id: user.id
    },
  });

  const [acceptFollow, { data: acceptFollowData, error: acceptFollowError, loading: acceptFollowLoading }] = useMutation(ACCEPT_FOLLOW, {
    refetchQueries: [{
      query: GET_PRAC_FOLLOWERS,
      variables: {
        followee_id: user.id
      }
    }]
  })

  const refetchUser = () => {
    console.log("Calling Refetch");

    auth.refetchUser(user.id)
    setTimeout(() => {
      user = auth.getUser()
    }, 1000)
  }

  if (loading) {
    return <div>LOADING...</div>
  }

  if (error) {
    console.log(error);
    return <div>
      {JSON.stringify(error)}
    </div>
  }

  if (data) {
    console.log(data);
  }

  if (acceptFollowError) {
    console.log(acceptFollowError);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: ["follower", "firstName"],
    },
    {
      title: 'Surname',
      dataIndex: ["follower", "lastName"],
    },
    {
      title: 'Requested On',
      render: (followInfo: any) => {
        let req_on = new Date(followInfo.requested_on)
        return (
          <div>
            <div style={{ fontSize: 12 }}>{req_on.toDateString()}</div>
            <div style={{ fontSize: 10 }}>{req_on.toLocaleTimeString()}</div>
          </div>
        )
      }
    },
    {
      title: 'Action',
      render: (followInfo: any) => {
        if (!followInfo.accepted_on) {
          return (
            <Space>
              {
                acceptFollowLoading ? <Spin indicator={antIcon} /> : null
              }
              <Button type="primary" onClick={() => {
                acceptFollow({
                  variables: {
                    id: followInfo.id
                  }
                })
              }} >
                Accept
              </Button>
            </Space>
          )
        }
        else {
          return (
            <RemoveFollowerModal relationship_id={followInfo.id} followerName={followInfo.follower.firstName} />
          )
        }
      }
    },
  ];


  return (
    <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Link to="/dashboard/patients">
          <Breadcrumb.Item>
            <HomeOutlined />
            <span>Home</span>
          </Breadcrumb.Item>
        </Link>
        {/* <Link to="/dashboard/patients"> */}
        <Breadcrumb.Item >
          <UserOutlined />
          <span>
            Profile
          </span>
        </Breadcrumb.Item>
        {/* </Link> */}
      </Breadcrumb>
      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >
        {/* {
          JSON.stringify(user)
        } */}
        <EditUserModal refetchUser={refetchUser} />
        <h1>Firstname: {user?.firstName}</h1>
        <h2>Lastname: {user?.lastName}</h2>
        <h2>Email: {user?.email}</h2>
        <h2>Gender: {user?.data?.gender}</h2>
        <h2>Birth Date: {user?.birthDate}</h2>
        <div style={{ height: 50 }}></div>
        <h1>Follow Details</h1>
        {
          !loading && data &&
          <Table rowKey={(record) => record.id} dataSource={data?.followers} columns={columns} />
        }
      </Content>
    </>
  )
};


