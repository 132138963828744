import { Modal } from 'antd';
import { green5, red5 } from '../utils/colors';
import { formulateModal } from '../utils/Descriptions'

export const RMSSDValueCard = ({ title, subTitle, value = 0, decimal = 2, onClick }: any) => {
  const [modal, modalContextHolder] = Modal.useModal();

  return (
    <div style={{
      flex: 1,
      minHeight: 50,
      textAlign: "center",
      paddingTop: 15,
      paddingBottom: 15,
      borderRadius: 5,
      // border: "3px solid lightblue", 
      cursor: "pointer",
      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)'
    }}
      // onClick={() => formulateModal(modal, "HRV Index", "hrvIndex")}
      onClick={onClick}
    >
      <div style={{
        fontSize: 34,
        lineHeight: 1,
        color: value < 15 ? red5 : green5,
        marginBottom: 7,
      }}>{value.toFixed(decimal)}</div>
      <div style={{ textAlign: "center", color: 'black', fontSize: 16, lineHeight: 1 }}>{title}</div>
      {subTitle ?
        <div style={{ textAlign: "center", fontSize: 12, color: 'grey', lineHeight: 1 }}>{subTitle}</div>
        : null}

      {modalContextHolder}

    </div>
  )
}
