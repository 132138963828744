import { useQuery } from '@apollo/client';
import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Table, Tag } from 'antd';
import { Content } from 'antd/lib/layout/layout'
import Column from 'antd/lib/table/Column';
import ColumnGroup from 'antd/lib/table/ColumnGroup';
import moment from 'moment';
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { client, useAuth } from '../App';
import { GET_HEART_DATA_BY_OWNER, GET_PRAC_PATIENTS } from '../graphql/queries';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { DOCTER_FOLLOW_USER } from '../graphql/mutations';

const { Option } = Select

export const RegisterPatients = () => {
  let auth = useAuth();
  let history = useHistory();

  const [formError, setFormError] = useState<string>("")

  const onFinish = (fieldsValue: any) => {
    // const values = {
    //   ...fieldsValue,
    //   'birthDate': fieldsValue['birthDate'].format('YYYY-MM-DD')
    // }
    // console.log(values);


    auth.patientsignup(fieldsValue)
      .then((data: any) => {
        console.log("Link User Done");
        console.log(data)
        console.log(data?.response?.status)

        console.log(data)
        if (data?.response?.status == 400) {
          setFormError("The user already exist, please search for user on Connect Patient view")
        }
        else {
          history.push("/dashboard")
        }
      })
  };




  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };


  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '24px 16px',
        padding: 24,
        minHeight: 200,
      }} >
      <h1>Invite a User</h1>
      <Row
        justify="center"
        style={{
          marginTop: 70,
        }}>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={16}
          xl={10}
          style={{
            padding: 50,
            boxShadow: "5px 8px 24px 5px rgba(208, 216, 243, 0.6)",

          }}>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", flex: 1, paddingBottom: 30 }} >
            <h1 style={{ textAlign: "center" }}>Enter user details below</h1>
            <p style={{ maxWidth: 300, textAlign: "center" }}>The user will be sent an email to enter a password. Any user added through this form will automatically be added to your followees list.</p>
          </div>

          <div style={{
            color: "red",
            paddingBottom: 20,
            textAlign: "center"
          }}>{formError}</div>

          <Form
            {...layout}
            name="register_user_under_doctor"
            // className="login-form"
            initialValues={{ email: "", firstName: "", lastName: "", remember: true }}
            onFinish={onFinish}
            onCompositionUpdate={() => {
              setFormError("")
            }}
          >

            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid email!',
                },
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
            >
              <Input
                placeholder="Please input email"
              />
            </Form.Item>

            <Form.Item
              name="firstname"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your First Name!',
                },
              ]}
            >
              <Input
                placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="lastname"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Last Name!',
                },
              ]}
            >
              <Input
                placeholder="Last Name" />
            </Form.Item>

            <Form.Item
              name="gender"
              label="Gender"
              rules={[{ required: true, message: "Please ensure to pick a gender" }]}
            >
              <Select placeholder="Gender" >
                <Option value="male" >Male</Option>
                <Option value="female" >Female</Option>
              </Select>
            </Form.Item>

            {/* <div style={{ color: "#999" }}>Birth Date</div> */}
            <Form.Item label="BirthDate" style={{ marginBottom: 0 }} required>
              <Input.Group compact style={{ width: "100%" }}  >
                <Form.Item name="birthDay">
                  <InputNumber min={1} max={31} placeholder="Day" />
                </Form.Item>
                <Form.Item name="birthMonth">
                  <Select placeholder="Select Month">
                    {
                      [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December"
                      ].map((m, i) => {
                        return <Select.Option key={i} value={i}>{m}</Select.Option>
                      })
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="birthYear"
                >
                  <InputNumber min={new Date().getFullYear() - 110} max={new Date().getFullYear() - 18} placeholder="Year" />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            {/* <Form.Item
              name="birthDate"
              label="Birth Date"
              rules={[
                {
                  type: 'object' as const,
                  required: true,
                  message: 'Please select a birthdate!'
                }
              ]} >
              <DatePicker />
            </Form.Item> */}

            <Form.Item wrapperCol={{ md: { span: 24, offset: 0 } }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  marginTop: 20,
                  height: 50,
                  width: '100%',
                  backgroundColor: "#000",
                  borderColor: "#000",
                }}>
                Invite User
              </Button>

            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Content>
  )
}
