import React, { useCallback, useRef } from 'react';
import { VictoryAxis, VictoryChart, VictoryLine, VictoryTheme, VictoryZoomContainer } from 'victory';
import { toPng } from 'html-to-image';


export const RhythmogramDisplay = ({ rythmData }: any) => {

  return (
    <>
      <VictoryChart
        domain={{ y: [500, 1300] }}
        domainPadding={{ x: 0, y: 0 }}
        style={{
          background: {
            fill: "rgb(240, 240, 240)"
          },

        }}
        padding={0}
        height={70}
        containerComponent={

          <VictoryZoomContainer
            zoomDomain={{
              x: [rythmData[0].x, rythmData[rythmData.length - 1].x],
              y: [500, 1300]
            }}
          />}
      >
        <VictoryAxis
          style={{
            axis: { display: "none" },
            ticks: { display: "none" },
            tickLabels: { display: "none" },
            // grid: { stroke: 'black', strokeWidth: 0.5 },
          }}
        />

        <VictoryLine
          style={{ data: { stroke: "#c43a31", strokeWidth: .5 } }}
          data={rythmData}
        />
        <VictoryAxis
          dependentAxis
          offsetX={30}
          theme={VictoryTheme.material}
          tickValues={[400, 600, 800, 900, 1000, 1200]}
          tickFormat={(tick) => `${tick}ms`}
          style={{
            axis: { display: "none" },
            ticks: { display: "none", },
            tickLabels: { fontSize: 4, fill: "black" },
            grid: { stroke: '#333333', strokeWidth: 0.2 },
          }}
        />

      </VictoryChart>
    </>
  );
};
