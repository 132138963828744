import { useMutation, useQuery } from '@apollo/client'
import { message, Spin, Switch } from 'antd'
import React, { useEffect } from 'react'
import { GET_SURVEY_BY_RECORDING } from '../../graphql/queries'
import { LoadingOutlined } from '@ant-design/icons';
import { INSERT_ANSWER_ONE, UPDATE_ANSWER_BY_PK } from '../../graphql/mutations';

const LoadingProgress = <LoadingOutlined style={{ fontSize: 50 }} spin />;


interface VagalQuestionnaire {
  recordingId: string
}
export const VagalSurvey = ({ recordingId }: VagalQuestionnaire) => {

  const { data, loading, error } = useQuery(GET_SURVEY_BY_RECORDING, {
    fetchPolicy: 'network-only',
    variables: {
      heart_data_ref: recordingId,
      survey_ref: 1
    }
  })

  const [insertOneAnswer] = useMutation(INSERT_ANSWER_ONE, {
    onCompleted: () => {
      success()
    },
    onError: () => {
      errorUpdate()
    },
    refetchQueries: [{
      query: GET_SURVEY_BY_RECORDING,
      variables: {
        heart_data_ref: recordingId,
        survey_ref: 1
      }
    }]
  })

  const [updateAnswer] = useMutation(UPDATE_ANSWER_BY_PK, {
    onCompleted: () => {
      successUpdate()
    },
    onError: () => {
      errorUpdate()
    },
    refetchQueries: [{
      query: GET_SURVEY_BY_RECORDING,
      variables: {
        heart_data_ref: recordingId,
        survey_ref: 1
      }
    }]
  })

  const success = () => {
    message.success('Successfully saved answer', 5);
  };
  const successUpdate = () => {
    message.success('Successfully updated answer', 5);
  };
  const errorUpdate = () => {
    message.error('Could not updated answer', 5);
  };
  // const errorDelete = () => {
  //   message.error('Could not delete answer', 5);
  // };
  // const successDelete = () => {
  //   message.success('Successfully deleted answer', 5);
  // };

  useEffect(() => {

  }, [recordingId])

  if (loading || !data) {

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 150 }}>
      <div>
        <Spin indicator={LoadingProgress} />
      </div>
    </div>
  }




  const onCheckChanged = (checked: boolean, e: any, obj: any) => {
    // console.log("Checked: ", checked);
    // console.log("Event: ", e);
    // console.log("Obj: ", obj);

    if (obj.answer_id === null) {
      insertOneAnswer({
        variables: {
          question_id: obj.question_id,
          heart_data_id: recordingId,
          answer: checked
        }
      })
    }
    if (obj.answer_id !== null) {
      updateAnswer({
        variables: {
          id: obj.answer_id,
          answer: checked
        }
      })
    }

  }

  return (
    <div>
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "flex-start",
        flexWrap: "wrap",
        maxHeight: 900,
      }}>
        {data && data.get_heart_data_answers.map((q: any, i: number) => {
          return (<div key={i} style={{
            display: "flex",
            width: 336,
            marginTop: 10
          }}>
            <div style={{ width: 50 }}>
              <Switch defaultChecked={q.answer} onChange={(c, e) => onCheckChanged(c, e, q)} />
            </div>
            <div style={{ flex: 1 }}>
              {q.question}
            </div>
          </div>)
        })}

      </div>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
    </div>
  )
}

