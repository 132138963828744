import { Col, Modal, Row } from 'antd'
import axios from 'axios'
import { dsv, csv, min } from 'd3'
import React, { useCallback, useEffect, useState } from 'react'
import { VictoryArea, VictoryAxis, VictoryChart, VictoryCursorContainer, VictoryHistogram, VictoryLine, VictoryScatter, VictoryTheme, VictoryZoomContainer } from 'victory'
import { formulateModal } from '../utils/Descriptions'
import { binInterval } from '../utils/helperFunctions'
import { ValueCard } from './ValueCard'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { blue5, green5, red5, yellow5 } from '../utils/colors'
import { RhythmogramDisplay } from './RhythmogramDisplay'
import { HistogramDisplay } from './HistogramDisplay'
import { ScattergramDisplay } from './ScattergramDisplay'
import { GraphLoadingBox } from './GraphLoadingBox'
import { ScattergramRRDisplay } from './ScattergramRRDisplay'
import { HistogramRRDisplay } from './HistogramRRDisplay'
import { RhythmogramRRDisplay } from './RhythmogramRRDisplay'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface ScatterData {
  x: number
  y: number
}

export const CardioVascularSection = ({ recording }: any) => {
  // console.log("RECORDING", recording?.rr_file?.id)

  const [modal, modalContextHolder] = Modal.useModal();

  const [rythmData, setRythmData] = useState<any[]>()
  const [rythmLoading, setRythmLoading] = useState(true)
  const [scatterData, setScatterData] = useState<ScatterData[]>()
  const source = axios.CancelToken.source()

  useEffect(() => {
    if (!recording) return
    if (recording.rr_metadata === null) {
      getRythmogram()
    }
    if (recording?.rr_metadata?.rrs) {
      // console.log("Build Scattergram");

      const rdata = recording.rr_metadata.rrs.map((values: any, index: any) => {
        // console.log()
        return { x: Number(values.x1), y: Number(values.value), color: values.rejected ? "black" : red5, rejected: values.rejected }
      })
      const x1 = rdata.map((d: any) => d.y)
      x1.pop()
      const colorArr = rdata.map((d: any) => d.color)
      colorArr.shift()
      const x2 = rdata.map((d: any) => d.y)
      x2.shift()

      const scatData = x1.map((d: any, i: any) => {
        return { x: d, y: x2[i], color: colorArr[i] }
      })
      setScatterData(scatData)
      setRythmData(rdata)
    }


    return () => {
      source.cancel('Cancelled rr file request')
      // setRythmData([])
      // setScatterData([])
    }
  }, [recording])

  async function getRythmogram() {
    setRythmLoading(true)

    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/v1/files/rr/${recording?.rr_file?.id}`,
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
      // data: location,
      cancelToken: source.token
    })
      .then(urldata => {
        // console.log("URL DATA", urldata.data)
        // const rythmFetched = csv(urldata.data)

        // if (true) {
        //   return csv(urldata.data.replace("localstack", "localhost"), initReq)
        // }
        return csv(urldata.data)

      })

      .then(res => {
        // console.log(res)
        const rdata = res.map((values: any, index: any) => {
          return { x: Number(values.x), y: Number(values.y) }
        })
        // console.log(rdata)
        const x1 = rdata.map((d: any) => d.y)
        x1.pop()
        const x2 = rdata.map((d: any) => d.y)
        x2.shift()

        const scatData = x1.map((d: any, i: any) => {
          return { x: d, y: x2[i] }
        })
        // console.log(scatData)
        setScatterData(scatData)

        setRythmData(rdata)
        setRythmLoading(false)
      })
      .catch(error => {
        console.log(error);

        console.log(error.message)
        setRythmLoading(false)
      })
  }



  return (
    <div>
      <div style={{ display: "flex", borderRadius: 10, width: "100%", gap: 20 }}>
        <ValueCard title="HRV Index" subTitle="(6-25)" range={[6, 25]} value={recording?.data?.hrvIndex} onClick={() => formulateModal(modal, "HRV Index", "hrvIndex")} />
        <ValueCard title="Heart Rate" subTitle="(60-84)" range={[59, 84]} value={recording?.data?.bpm} onClick={() => formulateModal(modal, "Heart Rate", "bpm")} decimal={0} />
        <ValueCard title="Stress Index" subTitle="(10-100)" range={[10, 100]} value={recording?.data?.stressIndex} onClick={() => formulateModal(modal, "Stress Index", "stressIndex")} decimal={0} />
        <ValueCard title="aMo%" subTitle="(30-50)" range={[30, 50]} value={recording?.data?.amo * 100} onClick={() => formulateModal(modal, "Amo %", "amo")} decimal={1} />
      </div>
      <div style={{ height: 20 }}></div>
      <div
        style={{ cursor: "pointer", fontSize: 24, color: "#444", textAlign: 'center', marginBottom: 20, marginTop: 20 }}
        onClick={() => formulateModal(modal, "Rhythmogram", "rhythmogram")}
      >
        Rhythmogram
      </div>
      <div>
        {!recording?.rr_metadata?.rrs && (rythmData && !rythmLoading ? <div id="rgram"><RhythmogramDisplay rythmData={rythmData} /></div> : <GraphLoadingBox height={150} />)}
        {recording?.rr_metadata?.rrs && rythmData && <div id="rgram"><RhythmogramRRDisplay rythmData={rythmData} /></div>}
      </div>

      <Row >
        <Col sm={24} md={12} lg={12} style={{ cursor: "pointer" }} onClick={() => formulateModal(modal, "Histogram", "histogram")}>
          <div style={{ cursor: "pointer", fontSize: 24, color: "#444", textAlign: 'center', marginTop: 20 }}>
            Histogram = {(recording?.data?.amo * 100).toFixed(2)}%
          </div>
          {!recording?.rr_metadata?.rrs && (rythmData && !rythmLoading ? <div id="hgram"><HistogramDisplay rythmData={rythmData} /></div> : <GraphLoadingBox height={300} />)}
          {recording?.rr_metadata?.rrs && rythmData && <div id="hgram"><HistogramRRDisplay rythmData={rythmData} /></div>}
        </Col>
        <Col sm={24} md={12} lg={12} style={{ cursor: "pointer" }} onClick={() => formulateModal(modal, "Scattergram", "scattergram")}>
          <div
            style={{ cursor: "pointer", fontSize: 24, color: "#444", textAlign: 'center', marginTop: 20 }}
          >
            Scattergram
          </div>
          {!recording?.rr_metadata?.rrs && (rythmData && !rythmLoading ? <div id="sgram"><ScattergramDisplay scatterData={scatterData} /></div> : <GraphLoadingBox height={300} />)}
          {/* {rythmData && !rythmLoading ? <div id="sgram"><ScattergramDisplay scatterData={scatterData} /></div> : <GraphLoadingBox height={300} />} */}
          {recording?.rr_metadata?.rrs && <div id="sgram"><ScattergramRRDisplay scatterData={scatterData} /></div>}
        </Col>

      </Row>

      {modalContextHolder}
    </div>
  )
}
