import { useQuery } from '@apollo/client'
import { GET_DESCRIPTION_BY_KEY } from '../graphql/queries';


export const DisplayDescription = ({ value, data, layout }: { value: string, data: any, layout: string }) => {

  const { loading, error, data: descriptionObject } = useQuery(GET_DESCRIPTION_BY_KEY, {
    variables: {
      key: value
    }
  });

  if (loading) {
    return (
      <div>
        LOADING...
      </div>
    )
  }

  if (error) {
    return (
      <div>
        Error...<br />
        {JSON.stringify(error)}
      </div>
    )
  }

  if (descriptionObject?.descriptions.length === 0) {
    return (
      <div>
        Sorry we do not have any description for this item...
      </div>
    )
  }

  if (layout === "eastern") {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
          <div style={{ width: '100%' }}>
            <div style={{ fontSize: 30 }}>{data?.name.charAt(0).toUpperCase() + data?.name.slice(1)}</div>
            <div style={{ fontSize: 20, fontWeight: 600 }}>{data?.shortName}</div>
            <div style={{ fontSize: 18 }}>{data?.parentDosha}</div>
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontSize: 40 }}>{data?.value}</div>
            {data?.status ?
              <div style={{ color: "white", borderRadius: 5, backgroundColor: (data?.value < 1) ? "#FFB300" : (data?.value > 3.5) ? "#E53935" : "#43A047" }}>{data?.status}</div>
              : null}
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: descriptionObject?.descriptions[0]?.hq_professional }}></div>
      </div>
    )
  }

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: descriptionObject?.descriptions[0]?.hq_professional }}></div>
    </div>
  )
}
