import { Col, Modal, Row } from 'antd';
import React from 'react'
import { formulateModal } from '../utils/Descriptions';
import { hormonesMineralsHorisontal, inflamIndexHorisontal, mineralsHorisontal, neuroBalance, neuroBalanceIndex, TfiHorisontal } from '../utils/gradients';
import { NeurotransmitterCombination } from './CombinedDisplays/NeurotransmitterCombination';
import { HorisontalBarComponent } from './HorisontalBarComponent'
import { HorisontalBarHeadingSingle } from './HorisontalBarHeadingSingle';
import { ICPCardDisplay } from './ICPCardDisplay';
import { NeuroHCardDisplay } from './NeuroHCardDisplay';
import { ValueCard } from './ValueCard';

export const NeuroHormonalSection = ({ recording }: any) => {
  const [modal, modalContextHolder] = Modal.useModal();

  return (
    <Row>
      <Col
        sm={24}
        md={12}
        lg={8}
        xl={8}
      >
        <div id="minerals">
          <h1 style={{ textAlign: "center" }}>Minerals</h1>
          <HorisontalBarHeadingSingle title="K (Potassium)" subHeading="(4-6)" value={recording?.data?.minerals.k.toFixed(2)} />
          <HorisontalBarComponent value={recording?.data?.minerals.k} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Potassium", "k")} backgroundColor={mineralsHorisontal} leftText="Low"
            rightText="High" valueMarker={true} />
          <HorisontalBarHeadingSingle title="Na (Sodium)" subHeading="(4-6)" value={recording?.data?.minerals.na.toFixed(2)} />
          <HorisontalBarComponent value={recording?.data?.minerals.na} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Sodium", "na")} backgroundColor={mineralsHorisontal} leftText="Low"
            rightText="High" valueMarker={true} />
          <HorisontalBarHeadingSingle title="Mg (Magnesium)" subHeading="(4-6)" value={recording?.data?.minerals.mg.toFixed(2)} />
          <HorisontalBarComponent value={recording?.data?.minerals.mg} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Magnesium", "mg")} backgroundColor={mineralsHorisontal} leftText="Low"
            rightText="High" valueMarker={true} />
          <HorisontalBarHeadingSingle title="Ca (Calcium)" subHeading="(4-6)" value={recording?.data?.minerals.ca.toFixed(2)} />
          <HorisontalBarComponent value={recording?.data?.minerals.ca} domain={[0, 10]} goodRange={[4, 6]} onClick={() => formulateModal(modal, "Calcium", "ca")} backgroundColor={mineralsHorisontal} leftText="Low"
            rightText="High" valueMarker={true} />
        </div>
        <Row justify='center' gutter={10} style={{ marginTop: 50 }} align='middle'>
          <Col sm={8}>
            <ICPCardDisplay title="ICP"
              subTitle="(WNL-HIGH)"
              value={recording?.data?.icp}
              onClick={() => formulateModal(modal, "Intracranial Pressuer", "icp")} />
          </Col>
          <Col sm={12}>
            <NeuroHCardDisplay title="NeuroH Reg"
              subTitle="(10-100)"
              range={[10, 100]}
              value={recording?.data?.neuro_hormonal_reg}
              onClick={() => formulateModal(modal, "Neuro Hormonal Regulation", "neuro_hormonal_reg")}
              decimal={5} />
          </Col>
          {/* <Col sm={6}>
            <ValueCard title="Heart Rate"
              subTitle="(60-84)"
              range={[59, 84]}
              value={recording?.data?.bpm}
              onClick={() => formulateModal(modal, "Heart Rate", "bpm")}
              decimal={0} />
          </Col> */}
        </Row>

      </Col>
      <Col
        sm={24}
        md={12}
        lg={8}
        xl={8}
      >
        <div id="neurotransmitters">
          <NeurotransmitterCombination recording={recording} />
        </div>
      </Col>
      <Col
        sm={24}
        md={12}
        lg={8}
        xl={8}
      >
        <div id="hormones">
          <h1 style={{ textAlign: "center" }}>Hormones</h1>
          <HorisontalBarHeadingSingle title="Inflam Index" subHeading="(75-300)" value={recording?.data?.inflamIndex.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.inflamIndex}
            domain={[50, 1500]}
            goodRange={[0, 150]}
            onClick={() => formulateModal(modal, "Inflam Index", "inflamIndex")}
            backgroundColor={inflamIndexHorisontal}
            leftText="Low"
            rightText="High"
            valueMarker={true}
            additionalMarkers={[{ name: "75", value: 75 }]}

            decimal={1} />
          <HorisontalBarHeadingSingle title="Cortisol" subHeading="(4-6)" value={recording?.data?.hormones?.cortisol.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.hormones?.cortisol}
            domain={[0, 10]}
            goodRange={[4, 6]}
            onClick={() => formulateModal(modal, "Cortisol", "cortisol")}
            backgroundColor={hormonesMineralsHorisontal}
            leftText="Low"
            rightText="High" valueMarker={true} />
          <HorisontalBarHeadingSingle title="DHEA" subHeading="(4-6)" value={recording?.data?.hormones?.dhea.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.hormones?.dhea}
            domain={[0, 10]}
            goodRange={[4, 6]}
            onClick={() => formulateModal(modal, "DHEA", "dhea")}
            backgroundColor={hormonesMineralsHorisontal}
            leftText="Low"
            rightText="High"
            valueMarker={true} />
          <HorisontalBarHeadingSingle title="Estradiol" subHeading="(4-6)" value={recording?.data?.hormones?.estradiol.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.hormones?.estradiol}
            domain={[0, 10]}
            goodRange={[4, 6]}
            onClick={() => formulateModal(modal, "Estradiol", "estradiol")}
            backgroundColor={hormonesMineralsHorisontal}
            leftText="Low"
            rightText="High"
            valueMarker={true} />
          <HorisontalBarHeadingSingle title="Pregnenolone" subHeading="(4-6)" value={recording?.data?.hormones?.pregnenolone.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.hormones?.pregnenolone}
            domain={[0, 10]}
            goodRange={[4, 6]}
            onClick={() => formulateModal(modal, "Pregnenolone", "pregnenolone")}
            backgroundColor={hormonesMineralsHorisontal}
            leftText="Low"
            rightText="High"
            valueMarker={true} />
          <HorisontalBarHeadingSingle title="Insulin" subHeading="(4-6)" value={recording?.data?.hormones?.insulin.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.hormones?.insulin}
            domain={[0, 10]}
            goodRange={[4, 6]}
            onClick={() => formulateModal(modal, "Insulin", "insulin")}
            backgroundColor={hormonesMineralsHorisontal}
            leftText="Low"
            rightText="High"
            valueMarker={true} />
          <HorisontalBarHeadingSingle title="T4 - T3" subHeading="(4-6)" value={recording?.data?.hormones?.t3_t4.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.hormones?.t3_t4}
            domain={[0, 10]}
            goodRange={[4, 6]}
            onClick={() => formulateModal(modal, "T4 T3", "t4_t3")}
            backgroundColor={hormonesMineralsHorisontal}
            leftText="Low"
            rightText="High"
            valueMarker={true} />
          <HorisontalBarHeadingSingle title="TFi" subHeading="(-0.07 - 0)" value={recording?.data?.tfi.toFixed(2)} />
          <HorisontalBarComponent
            value={recording?.data?.tfi}
            domain={[-0.2, 0.15]}
            goodRange={[-0.07, 0]}
            onClick={() => formulateModal(modal, "Thyroid Function Index", "tfi")}
            backgroundColor={TfiHorisontal}
            leftText="HYPO"
            rightText="HYPER"
            valueMarker={true} />
        </div>
      </Col>
      {modalContextHolder}

    </Row>
  )
}
