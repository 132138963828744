import { stack, select, scaleLinear, axisBottom, axisLeft, area, zoom, zoomTransform, zoomIdentity, EnterElement } from 'd3';
import _ from 'lodash';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { green5, red5, yellow5 } from '../utils/colors';
import { useResizeObserver } from '../utils/helperFunctions'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';


interface TrendPanBarProps {
  data: any[],
  dataIndex: number,
  setDataIndex: any,
  dataPos: number,
  locationChange: any
}

export type BaseType = Element | EnterElement | Document | Window | null;

// TODO:
// 2. Set custom start position
// 3. scroll to current index

export const TrendPanBar = ({ data, setDataIndex, dataIndex, locationChange }: TrendPanBarProps) => {


  const svgRef = useRef(null)
  const ref = useRef<any>()
  const groupRef = useRef()
  const dimensions = useResizeObserver(ref)

  const margin = { top: 20, right: 20, bottom: 30, left: 50 };
  const width = (dimensions?.width || 2000) - margin.left - margin.right;
  const height = 200 - margin.top - margin.bottom;

  const stacked = stack().keys(["vlf", "lf", "hf"])(data)

  // Convert data length to 100unit size intervals
  var x = scaleLinear()
    .domain([0, data.length])
    .range([0, data.length * 100]);

  var dataIndexConv = scaleLinear()
    .domain([data.length - 1, 0])
    .range([0, data.length - 1])
    .clamp(true)

  const reverseDataIndex = Math.round(dataIndexConv(dataIndex)) === 0 ? 0 : Math.round(dataIndexConv(dataIndex))
  // console.log(reverseDataIndex)
  // Add Y axis
  var y = scaleLinear()
    .domain([0, 20000])
    .range([height, 0]);

  const stackedArea = area()
    .x((d: any, i: number) => { return x(i) })
    .y0((d: any) => { return y(d[0]) })
    .y1((d: any) => { return y(d[1]) })

  // const [currentX, setcurrentX] = useState(0);

  const mouseDragEvent = (d: any, dataDisplay: any) => {
    if (d.sourceEvent.type === "wheel") {
      return
    }
    // Only mouse move may update position
    // console.log(d);

    if (d.sourceEvent.type === 'mousemove') {
      const index = Math.round(d.transform.x / 100)

      dataDisplay.attr("transform", "translate(" + (d.transform.x) + ",0)")
      // setCurrentIndex(index)
      if (index !== dataIndex) {
        setDataIndex(data.length - index - 1)
      }
      else if (index === 0) {
        setDataIndex(data.length - 1)
      }
      // setcurrentX(d.transform.x)
    }
  }

  const mouseEndSnap = (d: any, dataDisplay: any) => {
    setTimeout(() => {
      dataDisplay.attr("transform", "translate(" + (Math.round(d.transform.x / 100) * 100) + ",0)")
    }, 250)
  }

  useLayoutEffect(() => {

    if (!dimensions) {
      return
    }

    const svg = select(svgRef.current)
      .attr("width", dimensions?.width || width)
      .attr("height", height + margin.top + margin.bottom)

    const mainGroup = svg.append("g")
      .attr("transform", "translate(0," + margin.top + ")")

    let dataDisplay = mainGroup.selectAll("layers")
      .data(stacked)
      .enter()
      .append("g")
      .attr("class", "layer")

    let dataDisplay2 = mainGroup.selectAll("layers")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "layer")

    dataDisplay
      .append("path")
      .attr("fill", (d: any) => {
        if (d.key === "vlf") {
          return red5
        }
        if (d.key === "lf") {
          return yellow5
        }
        if (d.key === "hf") {
          return green5
        }
        return "grey"
      })
      .attr("stroke", "none")
      .attr("d", (d: any) => {
        return stackedArea(d)
      })
      // Translate data left by half data length to align last recording with screen
      .attr("transform", "translate(" + (-(x(data.length - 1) - dimensions?.width / 2)) + ",0)")

    dataDisplay2
      .append("line")
      .attr("transform", "translate(" + (-(x(data.length - 1) - dimensions?.width / 2)) + ",0)")
      .attr("x1", (d, i) => x(i))
      .attr("y1", 0)
      .attr("x2", (d, i) => x(i))
      .attr("y2", height + margin.top + margin.bottom)
      .style("stroke", "white")
      .style("stoke-width", 3)

    const zoomBehaviour = zoom()
      .scaleExtent([1, 1000])
      .translateExtent([[(-x(data.length - 1)), 1], [(dimensions?.width || width), 150]])
      .on("zoom", (d) => {
        mouseDragEvent(d, dataDisplay)
      })
      .on("end", (d) => {
        mouseEndSnap(d, dataDisplay)
      })

    // Move graph of index selected externally
    dataDisplay2.attr("transform", "translate(" + (x(reverseDataIndex)) + ",0)")
    dataDisplay.attr("transform", "translate(" + (x(reverseDataIndex)) + ",0)")

    svg.call((d: any) => zoomBehaviour(d))

    if (dimensions?.width) {
      // console.log("A Width was found", dimensions.width)
      svg.append("line")
        .attr("x1", (dimensions?.width || width) / 2)
        .attr("y1", 0)
        .attr("x2", (dimensions?.width || width) / 2)
        .attr("y2", height + margin.top + margin.bottom)
        .style("stroke", "red")
    }

    return () => {
      svg.selectAll("g").remove()
      svg.selectAll("line").remove()
    }

  }, [dimensions, dataIndex, locationChange]);



  return (
    <div ref={ref} style={{ position: 'relative' }}>
      {/* <div>Current X: {currentX}</div> */}
      {/* <div>DataIndex: {dataIndexConv(dataIndex)}</div> */}
      {/* <div>DataIndexReversed: {reverseDataIndex}</div> */}

      <div style={{ position: 'absolute', left: 0, height: 200, cursor: 'pointer', background: 'rgba(232, 232, 232, 0.50)', paddingTop: 90, paddingLeft: 5, paddingRight: 5 }} onClick={() => {
        const index = dataIndex <= 0 ? 0 : dataIndex - 1
        setDataIndex(index)
      }}><LeftOutlined /></div>
      <div style={{ position: 'absolute', right: 0, height: 200, cursor: 'pointer', background: 'rgba(232, 232, 232, 0.50)', paddingTop: 90, paddingLeft: 5, paddingRight: 5 }} onClick={() => {
        const index = dataIndex >= (data.length - 1) ? (data.length - 1) : dataIndex + 1
        setDataIndex(index)
      }}> <RightOutlined /> </div>
      <svg ref={svgRef} style={{ cursor: "w-resize" }} >
      </svg>
    </div>
  )
};
