import React, { useEffect, useState } from 'react'
import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal } from 'antd';
import { red5, yellow5 } from '../utils/colors';
import axios from 'axios';

interface RecalculateRecordingModalProps {
  recording: any
}

export const RecalculateRecordingModal = ({ recording }: RecalculateRecordingModalProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRecalculateLoading, setIsRecalculateLoading] = useState(false)

  const handleOk = () => {
    // setIsModalVisible(false);
    recalculateRecording(recording.id)
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const recalculateRecording = (id: string) => {
    setIsRecalculateLoading(true)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/v1/files/ecg/reprocess/${id}`,
      headers: {
        "Access-Control-Allow-Origin": "*"
      },
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setIsRecalculateLoading(false)
        setIsModalVisible(false)
      })
  }


  return (
    <>
      <Button
        icon={<EditOutlined />}
        style={{
          backgroundColor: "darkorange",
          border: 'none',
          color: 'white'
        }}
        onClick={() => setIsModalVisible(true)}
      >
        Recalculate
      </Button>

      <Modal
        title="Warning"
        visible={isModalVisible}
        okText="Recalculate"
        okButtonProps={{
          danger: true,
          loading: isRecalculateLoading
        }}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>This will rerun all calculations for this specific recording.</p>
        <p>It is usefull to run recalculate on old recordings or to try and reprocess failed recordings.</p>
      </Modal>
    </>
  )
}
