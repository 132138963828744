import { Modal } from 'antd'
import React from 'react'
import { formulateModal } from '../../utils/Descriptions'
import { neuroBalance, neuroBalanceIndex } from '../../utils/gradients'
import { HorisontalBarComponent } from '../HorisontalBarComponent'
import { HorisontalBarHeadingSingle } from '../HorisontalBarHeadingSingle'

interface NeurotransmitterCombinationProps {
  recording: any
}

export const NeurotransmitterCombination = ({ recording }: NeurotransmitterCombinationProps) => {
  const [modal, modalContextHolder] = Modal.useModal();

  return (
    <div id="neurotransmitters">
      <h1 style={{ textAlign: "center" }}>Neurotransmitters</h1>
      <HorisontalBarHeadingSingle
        title="Dopa"
        subHeading="(2-4)"
        value={recording?.data?.bnt?.dopa.toFixed(2)}
      />
      <HorisontalBarComponent
        value={recording?.data?.bnt?.dopa}
        domain={[0, 7.5]}
        goodRange={[2, 4]}
        onClick={() => formulateModal(modal, "Dopa", "dopa")}
        backgroundColor={neuroBalance}
        leftText="Low"
        rightText="High"
        valueMarker={true} />
      <HorisontalBarHeadingSingle title="Ct E" subHeading="(2-4)" value={recording?.data?.bnt?.ct_e.toFixed(2)} />
      <HorisontalBarComponent
        value={recording?.data?.bnt?.ct_e}
        domain={[0, 7.5]}
        goodRange={[2, 4]}
        onClick={() => formulateModal(modal, "Epinephrine", "ct_e")}
        backgroundColor={neuroBalance}
        leftText="Low"
        rightText="High"
        valueMarker={true} />
      <HorisontalBarHeadingSingle title="Ct Ne" subHeading="(2-4)" value={recording?.data?.bnt?.ct_ne.toFixed(2)} />
      <HorisontalBarComponent
        value={recording?.data?.bnt?.ct_ne}
        domain={[0, 7.5]}
        goodRange={[2, 4]}
        onClick={() => formulateModal(modal, "Norepinephrine", "ct_ne")}
        backgroundColor={neuroBalance}
        leftText="Low"
        rightText="High"
        valueMarker={true} />
      <HorisontalBarHeadingSingle title="Neuro Balance" subHeading="(-1.0 - 1.0)"
        value={((recording?.data?.bnt?.dopa + recording?.data?.bnt?.ct_e + recording?.data?.bnt?.ct_ne) - (recording?.data?.bnt?.ach + recording?.data?.bnt?.gaba + recording?.data?.bnt?.sert)).toFixed(2)} />
      <HorisontalBarComponent
        value={((recording?.data?.bnt?.dopa + recording?.data?.bnt?.ct_e + recording?.data?.bnt?.ct_ne) - (recording?.data?.bnt?.ach + recording?.data?.bnt?.gaba + recording?.data?.bnt?.sert))}
        domain={[-3, 3]}
        goodRange={[-1, 1]}
        // onClick={() => formulateModal(modal, "Potassium", "k")}
        backgroundColor={neuroBalanceIndex}
        leftText="Inhibitory Predominance"
        rightText="Excitatory Predominance"
        valueMarker={true}
      />
      <HorisontalBarHeadingSingle
        title="Ach"
        subHeading="(2-4)"
        value={recording?.data?.bnt?.ach.toFixed(2)} />
      <HorisontalBarComponent
        value={recording?.data?.bnt?.ach}
        domain={[0, 7.5]}
        goodRange={[2, 4]}
        onClick={() => formulateModal(modal, "Acetylcholine", "ach")}
        backgroundColor={neuroBalance}
        leftText="Low"
        rightText="High"
        valueMarker={true} />
      <HorisontalBarHeadingSingle title="Gaba" subHeading="(2-4)" value={recording?.data?.bnt?.gaba.toFixed(2)} />
      <HorisontalBarComponent
        value={recording?.data?.bnt?.gaba}
        domain={[0, 7.5]}
        goodRange={[2, 4]}
        onClick={() => formulateModal(modal, "Gaba", "gaba")}
        backgroundColor={neuroBalance}
        leftText="Low"
        rightText="High"
        valueMarker={true} />
      <HorisontalBarHeadingSingle title="Sert" subHeading="(2-4)" value={recording?.data?.bnt?.sert.toFixed(2)} />
      <HorisontalBarComponent
        value={recording?.data?.bnt?.sert}
        domain={[0, 7.5]}
        goodRange={[2, 4]}
        onClick={() => formulateModal(modal, "Serotonin", "sert")}
        backgroundColor={neuroBalance}
        leftText="Low"
        rightText="High"
        valueMarker={true} />
      {modalContextHolder}
    </div>
  )
}
