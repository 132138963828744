import React from 'react'

import vagus_nerve from "../../vagal_image/vagus_nerve.png"
import {
  LoadingOutlined, HomeOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useAuth } from '../../App';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Breadcrumb, Button, Col, Descriptions, Divider, Modal, PageHeader, Row, Space, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { NotesModal } from '../Notes/NotesModal';
import { GET_DESCRIPTION_BY_KEY, GET_HEART_DATA_BY_PK } from '../../graphql/queries';
import moment from 'moment';
import { ValueCard } from '../ValueCard';
import { RMSSDValueCard } from '../RMSSDValueCard';
import { LFHFValueCard } from '../LFHFValueCard';
import { formulateModal } from '../../utils/Descriptions';
import { VagalSurvey } from './VagalSurvey';


const Loader = <LoadingOutlined style={{ fontSize: 50 }} spin />;


export const VagalScreen = () => {
  let auth = useAuth();
  let user = auth.getUser()
  const history = useHistory()
  const { userId, recordingId }: any = useParams()
  const [modal, modalContextHolder] = Modal.useModal();

  const { loading, error, data } = useQuery(GET_HEART_DATA_BY_PK, {
    variables: {
      id: recordingId,
      userIdString: userId,
      userIdUuid: userId
    }
  })

  const { loading: descriptionLoading, error: descriptionError, data: descriptionObject } = useQuery(GET_DESCRIPTION_BY_KEY, {
    variables: {
      key: "rules_vns"
    }
  });


  if (loading || !data) {

    return <div style={{ display: 'flex', flexDirection: 'column', alignContent: 'center', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
      <div>
        <Spin indicator={Loader} />
      </div>
    </div>
  }


  return (
    <>
      <Breadcrumb style={{ marginTop: 16, marginLeft: 16 }}>
        <Breadcrumb.Item>
          <Link to="/dashboard/patients">
            <HomeOutlined />
            <span>Home</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item >
          <Link to="/dashboard/patients">
            <span>Patients</span>
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item><UserOutlined />
          <span>
            {data?.user?.firstName}
          </span>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <span>
            Brain Screen
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>

      <Content
        className="site-layout-background"
        style={{
          margin: '16px 16px',
          padding: 24,
          minHeight: 200,
        }} >

        <PageHeader
          className="site-page-header"
          onBack={history.goBack}
          title={`${data?.user?.firstName}`}
          subTitle={data?.users_by_pk?.last_name}
          extra={[
            <NotesModal recordingId={recordingId} authorId={user.id} />,
          ]}
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 30 }}>
            <Descriptions.Item label="Age">{moment().diff(data?.user?.birthDate, 'years')}</Descriptions.Item>
            <Descriptions.Item label="Gender">{data?.users_by_pk?.data ? JSON.parse(data?.users_by_pk?.data)?.data?.gender.toUpperCase() : null}</Descriptions.Item>
            {/* <Descriptions.Item label="Total recordings">{data?.heart_data?.length}</Descriptions.Item> */}
          </Descriptions>
        </PageHeader>
        <Divider />

        <div>
          <Row gutter={50}>
            <Col sm={6}>
              <img src={vagus_nerve} style={{
                width: "100%"
              }} alt="" />
            </Col>
            <Col sm={18}>
              <Row gutter={10}>
                <Col sm={6}>
                  <RMSSDValueCard
                    title="RMSSD"
                    subTitle={`(15-45+)`}
                    value={data?.heart_data_by_pk?.data?.timeDomain?.rmssd}
                    decimal={0}
                    onClick={() => formulateModal(modal, "RMSSD", "rmssd")}
                  />
                </Col>
                <Col sm={6}>
                  <LFHFValueCard
                    title="LF-HF Ratio"
                    subTitle={`(1-3)`}
                    value={data?.heart_data_by_pk?.data?.frequencyDomain?.lf_hf_ratio}
                    decimal={1}
                    onClick={() => formulateModal(modal, "LF - HF Ratio", "lf_hf_ratio")}
                  />
                </Col>
                <Col sm={6}>
                  <ValueCard title="HF %"
                    subTitle="_"
                    range={[15, 99]}
                    value={data?.heart_data_by_pk?.data?.frequencyDomain.hf / data?.heart_data_by_pk?.data?.frequencyDomain.total_power * 100}
                    // onClick={() => formulateModal(modal, "Heart Rate", "bpm")}
                    decimal={0} />
                </Col>
              </Row>
              {/* <Row gutter={10} style={{
                marginTop: 30
              }}>
                <Col sm={24}>
                  <Space>
                    <Button onClick={() => formulateModal(modal, "Vagal Nerve Stimulation", "vns_explanation")}>Vagal Nerve Stimulation Explained</Button>
                    <Button>Rules</Button>
                  </Space>
                </Col>
              </Row> */}
              <Row >
                <Col sm={24} style={{ marginTop: 20 }}>
                  <div dangerouslySetInnerHTML={{ __html: descriptionObject?.descriptions[0]?.hq_professional }}></div>

                  <Button type='primary' style={{ marginTop: 20, marginBottom: 30 }} onClick={() => formulateModal(modal, "Vagal Nerve Stimulation", "vns_explanation")}>Vagal Nerve Stimulation Explained</Button>
                  <h2>Decreased Vagus Nerve Tone Questions</h2>
                  <VagalSurvey recordingId={recordingId} />
                </Col>
              </Row>
            </Col>
          </Row>


        </div>

      </Content>
      {modalContextHolder}
    </>
  )
}
