import React from 'react';
import { VictoryAxis, VictoryChart, VictoryScatter } from 'victory';
import { green5, red5, yellow5 } from '../utils/colors';

export const ScattergramDisplay = ({ scatterData }: any) => {
  return (
    <VictoryChart
      width={350} height={350}
      domain={{ x: [600, 1200], y: [600, 1200] }}
    >
      <g transform='rotate(-45) translate(-50,240)'>
        <ellipse key={0} cx={0} cy={0} ry={40} rx={100} style={{ fill: yellow5, strokeWidth: .5, opacity: .2 }} />
        <ellipse key={1} cx={0} cy={0} ry={25} rx={50} style={{ fill: green5, strokeWidth: .5, opacity: .2 }} />
      </g>
      <VictoryAxis
        dependentAxis
        tickFormat={(t) => `${t / 1000}`}
        style={{
          tickLabels: { fontSize: 8, fill: "black" },
        }}
      // style={{ tickLabels: { fill: 'white' }, axis: { stroke: 'white' } }}
      />
      <VictoryAxis
        tickFormat={(t) => `${t / 1000}`}
        // style={{ tickLabels: { fill: 'white' }, axis: { stroke: 'white' } }}
        style={{
          tickLabels: { fontSize: 8, fill: "black" },
        }}
      />

      <VictoryScatter
        style={{ data: { fill: red5 } }}
        size={1.5}
        data={scatterData}
      />

    </VictoryChart>
  )
};
