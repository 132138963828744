import { Button } from 'antd'
import d3, { axisBottom, axisLeft, brushX, line, max, min, scaleLinear, select, zoom } from 'd3'
import React, { useEffect, useRef, useState } from 'react'
import { useResizeObserver } from '../utils/helperFunctions'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';


import './EcgViewer.css'

export const EcgViewer = ({ data, rrData = [], filteredRR = [] }: any) => {
  // console.log(filteredRR);
  // console.log(rrData);

  const ref = useRef<any>()
  const svgRef = useRef<any>()
  const dimensions = useResizeObserver(ref)

  const svgHeight = 500
  const margins = { top: 30, right: 50, left: 50, bottom: 200 }
  const margins2 = { top: 350, right: 50, left: 50, bottom: 50 }
  const width = dimensions?.width ? dimensions?.width - margins.left - margins.right : 500
  const height = svgHeight - margins.top - margins.bottom
  const height2 = svgHeight - margins2.top - margins2.bottom

  const [showRR, setshowRR] = useState(false)

  const scaleX = scaleLinear()
    .domain([0, data.length])
    .range([0, width])

  const scaleY = scaleLinear()
    .domain([Number(min(data)), Number(max(data))])
    .range([height, 0])

  const scaleX2 = scaleLinear()
    .domain([0, data.length])
    .range([0, width])

  const scaleY2 = scaleLinear()
    .domain([Number(min(data)), Number(max(data))])
    .range([height2, 0])




  useEffect(() => {

    const svg = select(svgRef.current)
    svg.attr("width", dimensions?.width ?? 1000)
    svg.attr("height", svgHeight)


    const xAxis = axisBottom(scaleX)
    const xAxis2 = axisBottom(scaleX2)
    const yAxis = axisLeft(scaleY)

    var brush = brushX()
      .extent([[0, 0], [width, height2]])
      .on("end", (event) => {
        brushed(event)
      });

    // var zoomer = zoom()
    //   .scaleExtent([1, Infinity])
    //   .translateExtent([[0, 0], [width, height]])
    //   .extent([[0, 0], [width, height]])
    //   .on("zoom", (event) => console.log(event));

    var focus = svg.append("g")
      .attr("class", "focus")
      .attr("transform", "translate(" + margins.left + "," + margins.top + ")")

    var context = svg.append("g")
      .attr("class", "context")
      .attr("transform", "translate(" + margins2.left + "," + margins2.top + ")");

    const heartLine = line()
      .x((value: any, index: any) => scaleX(index))
      .y((value: any, index: any) => scaleY(value))

    const heartLine2 = line()
      .x((value: any, index: any) => scaleX(index))
      .y((value: any, index: any) => scaleY2(value))


    svg.append("defs").append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", width)
      .attr("height", height);

    focus.append("path")
      .datum(data)
      .attr("class", "area")
      .attr("d", (value: any) => heartLine(value))
      .attr("stroke", "tomato")
      .attr("fill", "none")

    focus.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxis);

    focus.append("g")
      .attr("class", "axis axis--y")
      .call(yAxis);

    context.append("path")
      .datum(data)
      .attr("class", "area")
      .attr("d", (value: any) => heartLine2(value))
      .attr("stroke", "tomato")
      .attr("fill", "none")

    context.append("g")
      .attr("class", "axis axis--x")
      .attr("transform", "translate(0," + height2 + ")")
      .call(xAxis2)

    context.append("g")
      .attr("class", "brush")
      .call(brush)
      .call(brush.move, [scaleX((data.length / 2) - 500), scaleX((data.length / 2) + 500)]);

    svg.append("rect")
      .attr("class", "zoom")
      .attr("width", width)
      .attr("height", height)
      .attr("transform", "translate(" + margins.left + "," + margins.top + ")")


    function brushed(event: any) {
      if (event.sourceEvent && event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
      var s = event.selection || scaleX2.range();
      scaleX.domain(s.map(scaleX2.invert, scaleX2));

      focus.select(".area")
        .attr("d", (value: any) => heartLine(value));

      if (rrData) {
        focus
          .selectAll("line")
          .data(rrData)
          .join("line")
          .attr("class", "area")
          .attr("x1", (d: any) => scaleX(d.x))
          .attr("y1", 0)
          .attr("x2", (d: any) => scaleX(d.x))
          .attr("y2", height)
          .style("stroke-width", 1)
          .style("stroke", "grey")
          .style("fill", "none");
      }

      if (filteredRR) {
        focus
          .selectAll(".rr")
          .data(filteredRR)
          .join("line")
          .attr("class", "rr")
          .attr("x1", (d: any) => scaleX(d.x1))
          .attr("y1", 0)
          .attr("x2", (d: any) => scaleX(d.x1))
          .attr("y2", height)
          .style("stroke-width", 1)
          .style("stroke", (d: any) => d.rejected ? "red" : "green")
          .style("fill", "none");

        focus
          .selectAll(".rrrange")
          .data(filteredRR)
          .join("line")
          .attr("class", "rrrange")
          .attr("x1", (d: any) => scaleX(d.x0 + 100))
          .attr("y1", () => scaleY(2600))
          .attr("x2", (d: any) => scaleX(d.x1 - 100))
          .attr("y2", () => scaleY(2600))
          .style("stroke-width", 1)
          .style("stroke", (d: any) => d.rejected ? "red" : "green")
          .style("fill", "none");

        focus
          .selectAll(".intervaltext")
          .data(filteredRR)
          .join("text")
          .attr("class", "intervaltext")
          .attr("x", (d: any) => scaleX(d.xc))
          .attr("y", () => scaleY(2700))
          .text((d: any) => d.value.toFixed())
          .style("text-anchor", "middle")
          .style("fill", (d: any) => d.rejected ? "red" : "green");
      }
      // focus
      //   .selectAll("circle")
      //   .data(rrData)
      //   .join("circle")
      //   .attr("fill", "none")
      //   .attr("stroke", "steelblue")
      //   .attr("stroke-width", 1.5)
      //   .attr("class", "area")
      //   .attr("cx", (d: any) => scaleX(d.x))
      //   .attr("cy", (d: any) => scaleY(d.y * 2.4))
      //   .attr("r", 3);


      // focus.select(".axis--x")
      // .call(xAxis);
      // svg.select(".zoom").call(zoom.transform, d3.zoomIdentity
      //   .scale(width / (s[1] - s[0]))
      //   .translate(-s[0], 0));
    }

    return () => {
      console.log("ECGView Run Cleanup");
      svg.selectAll(".rr").remove()
      svg.selectAll(".rrrange").remove()
      svg.selectAll(".intervaltext").remove()
      svg.selectAll("*").remove()

    }
  }, [data, rrData, dimensions])

  return (
    <div ref={ref}>
      {/* <Button
        type="primary"
        style={{ float: 'right', marginBottom: 20 }}
        onClick={() => setshowRR(!showRR)}
        icon={showRR ? <EyeOutlined /> : <EyeInvisibleOutlined />}>View RR</Button> */}
      <svg ref={svgRef}
        style={{ backgroundColor: "#f0f0f0" }}
      ></svg>
    </div>
  )
}

