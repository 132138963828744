import { blue4, blue5, green5, green6, red5, yellow5 } from "./colors"

const darkRed = "rgb(229, 57, 53)"
const red = "#ff6347"
const yellow = yellow5 //"#ffd700"
const darkGreed = green6 //"rgb(67, 160, 71)"
const green = green5//"#8fbc8f"
const blue = blue4 //"lightblue"
const darkBlue = blue5 //"rgba(34,193,195,1)"
const deepBlue = blue5 //"#045CAD"

// export const totalPowerVertical = `linear-gradient(0deg, rgba(253,187,45,1)  0%, ${red} 100%)`
export const totalPowerVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 5%, ${yellow} 10%, ${green} 35%, ${darkGreed} 80%)`
export const totalPowerHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 5%, ${yellow} 10%, ${green} 35%, ${darkGreed} 80%)`
export const totalPowerHorisontalPDF = [{ offset: "0%", color: darkRed }, { offset: "5%", color: red }, { offset: "10%", color: yellow }, { offset: "35%", color: green }, { offset: "10%", color: darkGreed }]

export const lfVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 20%, ${yellow} 55%, ${green} 70%, ${darkGreed} 100%)`
export const hfVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 20%, ${yellow} 55%, ${green} 70%, ${darkGreed} 100%)`
export const vlfVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 20%, ${yellow} 55%, ${green} 70%, ${darkGreed} 100%)`

export const lfHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 20%, ${yellow} 55%, ${green} 70%, ${darkGreed} 100%)`
export const hfHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 20%, ${yellow} 55%, ${green} 70%, ${darkGreed} 100%)`
export const vlfHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 20%, ${yellow} 55%, ${green} 70%, ${darkGreed} 100%)`

export const lfPercVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 10%, ${yellow} 25%, ${green} 40%, ${green} 70%, ${yellow} 80%, ${red} 100%)`
export const hfPercVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 10%, ${yellow} 25%, ${green} 40%, ${green} 70%, ${yellow} 80%, ${red} 100%)`
export const vlfPercVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 10%, ${yellow} 25%, ${green} 40%, ${green} 70%, ${yellow} 80%, ${red} 100%)`

export const lfPercHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 10%, ${yellow} 25%, ${green} 40%, ${green} 70%, ${yellow} 80%, ${red} 100%)`
export const hfPercHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 10%, ${yellow} 25%, ${green} 40%, ${green} 70%, ${yellow} 80%, ${red} 100%)`
export const vlfPercHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 10%, ${yellow} 25%, ${green} 40%, ${green} 70%, ${yellow} 80%, ${red} 100%)`

export const lfHfVertical = `linear-gradient(0deg, ${darkBlue} 0%, ${blue} 20%,${green} 45%, ${green} 55%, ${yellow} 75%, ${red} 100%)`
export const lfHfHorisontal = `linear-gradient(90deg, ${darkBlue} 0%, ${blue} 20%,${green} 45%, ${green} 55%, ${yellow} 75%, ${red} 100%)`

export const stressIndexVertical = `linear-gradient(0deg, ${darkBlue} 0%, ${blue} 7%,${green} 10%, ${green} 17%, ${yellow} 20%, ${red} 50%, ${darkRed} 100%)`
export const stressIndexHorisontal = `linear-gradient(90deg, ${darkBlue} 0%, ${blue} 7%,${green} 10%, ${green} 17%, ${yellow} 20%, ${red} 50%, ${darkRed} 100%)`
export const stressIndexHorisontalPDF = [{ offset: "0%", color: darkBlue }, { offset: "7%", color: blue }, { offset: "10%", color: green }, { offset: "17%", color: green }, { offset: "20%", color: yellow }, { offset: "50%", color: red }, { offset: "100%", color: darkRed }]



export const sdnnVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 10%, ${yellow} 20%, ${green} 35%, ${green} 70%, ${darkGreed} 100%)`
export const rmssdVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 10%, ${yellow} 20%, ${green} 35%, ${green} 70%, ${darkGreed} 100%)`
export const hrvIndexVertical = `linear-gradient(0deg, ${darkRed} 0%, ${red} 10%, ${yellow} 20%, ${green} 35%, ${green} 70%, ${darkGreed} 100%)`

export const sdnnHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 10%, ${yellow} 20%, ${green} 35%, ${green} 70%, ${darkGreed} 100%)`
export const rmssdHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 10%, ${yellow} 20%, ${green} 35%, ${green} 70%, ${darkGreed} 100%)`
export const hrvIndexHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 10%, ${yellow} 20%, ${green} 35%, ${green} 70%, ${darkGreed} 100%)`

export const hormonesMineralsHorisontal = `linear-gradient(90deg, ${darkRed} 0%, ${red} 7%, ${yellow} 35%, ${green} 45%, ${green} 55%, ${yellow} 65%, ${red} 93%, ${darkRed} 100%)`
export const AndCnsHorisontalPDF = [{ offset: "0%", color: darkRed }, { offset: "7%", color: red }, { offset: "35%", color: yellow }, { offset: "45%", color: green }, { offset: "55%", color: green }, { offset: "65%", color: yellow }, { offset: "93%", color: red }, { offset: "100%", color: darkRed }]


export const mineralsHorisontal = `linear-gradient(90deg, ${darkBlue} 0%, ${blue} 15%,  ${green} 35%, ${green} 60%, ${yellow} 75%, ${red} 85%, ${darkRed} 100%)`

export const neuroBalance = `linear-gradient(90deg, ${darkBlue} 0%, ${blue} 15%,  ${green} 25%, ${green} 45%, ${yellow} 65%, ${red} 85%, ${darkRed} 100%)`
export const neuroBalanceIndex = `linear-gradient(90deg, ${darkBlue} 0%, ${blue} 15%,  ${green} 25%, ${green} 75%, ${blue} 95%, ${darkBlue} 100%)`

export const inflamIndexHorisontal = `linear-gradient(90deg, ${darkGreed} 0%, ${green} 10%, ${yellow} 15%, ${yellow} 30%, ${red} 70%, ${darkRed} 100%)`

export const TfiHorisontal = `linear-gradient(90deg, ${darkBlue} 0%, ${blue} 25%,  ${green} 35%, ${green} 55%, ${yellow} 70%, ${red} 85%, ${darkRed} 100%)`

export const psActivityHorisontal = `linear-gradient(90deg, ${yellow5} 20%, ${green} 25%, ${green} 40%, ${red} 50%, ${darkRed} 100%)`
export const psActivityHorisontalPDF = [{ offset: "20%", color: yellow5 }, { offset: "25%", color: green }, { offset: "40%", color: green }, { offset: "50%", color: red }, { offset: "100%", color: darkRed }]
// export const energyDepHorisontal = `linear-gradient(90deg, ${red5} 0%, ${red5} 25%, ${yellow5} 35%, ${green5} 45%,${green5} 65%, ${blue} 80%, ${blue5} 100%)`
export const energyDepHorisontal = `linear-gradient(90deg, ${blue5} 10%, ${blue} 30%,  ${green5} 35%, ${green5} 70%, ${yellow} 75%,${yellow5} 80%, ${red5} 85%, ${red5} 100%)`
export const energyDepHorisontalPDF = [{ offset: "10%", color: blue5 }, { offset: "30%", color: blue }, { offset: "35%", color: green5 }, { offset: "70%", color: green5 }, { offset: "75%", color: yellow }, { offset: "80%", color: red5 }, { offset: "100%", color: red5 }]

export const polyvagalHorisontal = `linear-gradient(90deg, ${green6} 20%, ${green5} 25%, ${green5} 25%, ${green5} 32%, ${yellow} 38%, ${yellow} 43%, ${red5} 53%, ${red5} 100%)`
export const polyvagalHorisontalPDF = [{ offset: "0%", color: green6 }, { offset: "25%", color: green5 }, { offset: "32%", color: green5 }, { offset: "38%", color: yellow }, { offset: "43%", color: yellow }, { offset: "53%", color: red5 }, { offset: "100%", color: red5 }]


