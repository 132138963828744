import { Button, Drawer } from 'antd'
import { useState } from 'react'
import { FileTextOutlined } from '@ant-design/icons'
import { NotesDisplay } from './NotesDisplay'

interface NotesModalProps {
  recordingId: string
  authorId: string
}

export const NotesModal = ({ recordingId, authorId }: NotesModalProps) => {

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer} icon={<FileTextOutlined />}>
        Notes
      </Button>
      <Drawer
        title="All Notes"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80 }}
      >
        {visible && <NotesDisplay heart_data_id={recordingId} />}
      </Drawer>
    </>
  )
}
